export const getBadgeColor = (color: string) => {
  if (color === "#505258") {
    return {
      typography: "#505258",
      background: "#EEF1F7",
    };
  }
  if (color === "#6A2D2B") {
    return {
      typography: "#6A2D2B",
      background: "#F7C1BF",
    };
  }
  if (color === "#685903") {
    return {
      typography: "#685903",
      background: "#FFF3BA",
    };
  }
  if (color === "#2C532C") {
    return {
      typography: "#2C532C",
      background: "#D3FFD2",
    };
  }
  if (color === "#1D315E") {
    return {
      typography: "#1D315E",
      background: "#CEDDFF",
    };
  }
  if (color === "#764207") {
    return {
      typography: "#764207",
      background: "#FFEEDB",
    };
  }
  if (color === "#573197") {
    return {
      typography: "#573197",
      background: "#DECAFF",
    };
  }
  return {
    typography: "#505258",
    background: "#EEF1F7",
  };
};
