import { Box, useTheme } from "@mui/material";
import { RectangularLoader } from "../../molecules/skeleton-loader/RectangularLoader";
import { AttentionCardLoader } from "./AttentionCardLoader";
import { QuickLinksLoader } from "./QuickLinksLoader";
import { APITableLoader } from "./APITableLoader";
import { AssignedToMeColumnsVisibility } from "../organization/overview/Tables/AssignedToMeTableColumns";
import { useNavigate } from "react-router-dom";

export const OrganizationOverviewLoader = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const setSelectedTask = () => {};

  return (
    <Box>
      <Box
        sx={{
          maxHeight: "100%",
          gap: "31px",
          display: "flex",
        }}
      >
        <Box
          width="100%"
          display="flex"
          flexDirection="column"
          gap="15px"
          overflow="auto"
          flexGrow={1}
        >
          <Box display="flex" flexDirection="column" gap="15px">
            {[...Array(4)].map((_, index) => (
              <AttentionCardLoader />
            ))}
          </Box>
          <Box bgcolor={theme.palette.custom.primaryBackground}>
            <RectangularLoader height="35px" width="100%" />
          </Box>
          {[...Array(2)].map((_, index) => (
            <AttentionCardLoader />
          ))}
        </Box>
        <Box
          width="100%"
          display="flex"
          flexDirection="column"
          gap="15px"
          overflow="auto"
          flexGrow={1}
          height="100%"
        >
          <Box display="flex" flexGrow={1} width="100%">
            <QuickLinksLoader />
          </Box>
          <Box
            display="flex"
            flexDirection="column"
            flexGrow={1}
            width="100%"
            border={`1px solid ${theme.palette.custom.primaryBorder}`}
            borderRadius="6px"
            bgcolor={theme.palette.custom.primaryBackground}
          >
            <Box width="100%" padding="10px">
              <RectangularLoader height="17px" width="108px" />
            </Box>
            <APITableLoader
              columnsVisibility={AssignedToMeColumnsVisibility({
                navigate,
                setSelectedTask,
                theme,
              })}
              hideColumnVisibility
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
