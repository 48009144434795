import { Box, Menu, MenuItem, Typography, useTheme } from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { IconButton } from "../../atoms/IconButton";
import { signOut } from "../../services/AuthService";

export const OrganizationThreeDotsMenu = () => {
  const theme = useTheme();
  const [isMenuVisible, setMenuVisible] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const toggleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setMenuVisible(!isMenuVisible);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setMenuVisible(!isMenuVisible);
  };

  const navigate = useNavigate();

  return (
    <>
      <IconButton
        variant="user"
        width="25px"
        height="25px"
        color="none"
        onClick={(e) => toggleMenu(e)}
      />
      <Menu
        elevation={0}
        id="three-dots-menu"
        MenuListProps={{
          "aria-labelledby": "three-dots-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        sx={{
          gap: "5px",
          "& .MuiPaper-root": {
            "& .MuiMenu-list": {
              padding: "4px",
            },
            "& .MuiMenuItem-root": {
              padding: "4px",
              // width: "110px",
              "&:hover": {
                backgroundColor: theme.palette.custom.secondaryBackground,
              },
            },
          },
        }}
      >
        <CustomMenuItem
          onClick={() => {
            navigate("/profile");
            setAnchorEl(null);
          }}
        >
          <Typography variant="body2">Profile Settings</Typography>
        </CustomMenuItem>
        <CustomMenuItem
          onClick={() => {
            navigate("/profile/access-keys");
            setAnchorEl(null);
          }}
        >
          <Typography variant="body2">Access Keys</Typography>
        </CustomMenuItem>
        <CustomMenuItem
          onClick={() => {
            signOut();
            setAnchorEl(null);
          }}
        >
          <Typography variant="body2">Logout</Typography>
        </CustomMenuItem>
      </Menu>
    </>
  );
};

const CustomMenuItem = ({
  children,
  onClick,
}: {
  children: React.ReactNode;
  onClick: () => void;
}) => {
  return (
    <MenuItem>
      <Box
        display="flex"
        justifyContent="space-between"
        width="100%"
        alignItems="center"
        gap="5px"
        paddingRight="3px"
        onClick={(e) => {
          onClick();
        }}
      >
        {children}
      </Box>
    </MenuItem>
  );
};
