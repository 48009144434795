export const palette = {
  blue: {
    50: "#EAF0FF",
    100: "#CEDDFF",
    200: "#98B7FC",
    300: "#6595FF",
    400: "#2C67F1",
    500: "#2352C1",
    600: "#2D4785",
    700: "#1D315E",
    800: "#142344",
  },
  yellow: {
    50: "#FFF9DB",
    100: "#FFF3BA",
    200: "#FFEE99",
    300: "#FFE770",
    400: "#E3C10B",
    500: "#C5A708",
    600: "#978007",
    700: "#685903",
    800: "#4A3E04",
  },
  red: {
    50: "#FBE0DF",
    100: "#F7C1BF",
    200: "#F3A19F",
    300: "#EF827F",
    400: "#EB635F",
    500: "#BC4F4C",
    600: "#8D3B39",
    700: "#6A2D2B",
    800: "#49201E",
  },
  orange: {
    50: "#FFEEDB",
    100: "#FFDBB5",
    200: "#FFC98F",
    300: "#FFB766",
    400: "#F19630",
    500: "#D47D1B",
    600: "#AD6514",
    700: "#764207",
    800: "#51310E",
  },
  green: {
    50: "#EDFFEC",
    100: "#D3FFD2",
    200: "#B4FAB3",
    300: "#93E992",
    400: "#75C174",
    500: "#5C9E5C",
    600: "#356635",
    700: "#2C532C",
    800: "#1E381E",
  },
  purple: {
    50: "#EBDFFF",
    100: "#DECAFF",
    200: "#C9A9FF",
    300: "#B88DFF",
    400: "#A272F3",
    500: "#8857DA",
    600: "#7149B5",
    700: "#573197",
    800: "#2D1950",
  },
  grey: {
    50: "#F5F5F5",
    100: "#ECECEC",
    200: "#E1E1E1",
    300: "#CBCBCB",
    400: "#ABABAB",
    500: "#888888",
    600: "#616161",
    700: "#393939",
    800: "#292929",
  },
};
