import { Box, Button, Typography, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { IconButton } from "../../atoms/IconButton";
import { DefaultBadge } from "../../atoms/StatusBadge";
import { useQuestionnaireAnswers } from "../../hooks/useAnswers";
import { fetchAsset, fetchAssets, useAssets } from "../../hooks/useAssets";
import { usePluginInfo } from "../../hooks/usePlugins";
import { Asset } from "../../models/types";
import { IColumnVisibility } from "../../molecules/ColumnVisibility";
import { EmptyTable } from "../../molecules/EmptyTable";
import { RectangularLoader } from "../../molecules/skeleton-loader/RectangularLoader";
import { TableTextWithEllipsis } from "../../molecules/TableTextWithEllipsis";
import { editAsset } from "../../services/DataService";
import { isJSON } from "../../utilities/UIHelper";
import { APITable } from "../APITable";
import { CreateAssetModelModal } from "../modals/assets/CreateAssetModelModal";
import { RemoveRelatedObjectConfirmation } from "../modals/compliances/RemoveRelatedObjectConfirmation";
import { YesOrNoSelectField } from "./BooleanSelect";

type ModelsProps = {
  value: string;
  onChange: (newVal: any) => void;
  objectId: string;
};

export const Models = (props: ModelsProps) => {
  const { value, onChange, objectId } = props;
  const theme = useTheme();

  const { data: modelsResponse, isLoading: isLoadingModelsResponse } = useAssets({
    "asset_type_name[]": ["Model"],
    "metadata_related_use_case[]": [objectId],
  });
  const { data: modelsTypeResponse, isLoading } = useQuestionnaireAnswers({
    question: "use_case_models",
    "related_object_id[]": [objectId],
  });
  const [models, setModels] = useState<string[]>([]);
  const [connectModels, setConnectModels] = useState<string>(
    (models?.length ?? 0) > 0 ? "Yes" : value === "No" ? value : ""
  );
  const [openAddModal, setOpenAddModal] = useState<boolean>(false);
  const { data: mlflow } = usePluginInfo("mlflow");
  const isDatabricksIntegration = mlflow?.tracking_server?.toLowerCase() === "databricks";
  const [selectedModel, setSelectedModel] = useState<Asset | null>(null);

  const columnsVisibility: IColumnVisibility<Asset>[] = [
    {
      field: "name",
      headerName: "Model",
      visible: true,
      columnMinWidth: 400,
      renderer: (asset) => <TableTextWithEllipsis value={asset.name} />,
    },
    {
      field: "source",
      headerName: "Source",
      visible: true,
      columnMinWidth: 160,
      columnMaxWidth: 200,
      renderer: (asset) => (asset?.source ? <DefaultBadge value={asset.source} /> : null),
    },
    {
      field: "updated_by",
      headerName: "",
      visible: true,
      columnMaxWidth: 60,
      columnMinWidth: 60,
      renderer: (asset) => (
        <IconButton
          variant="unlink"
          onClick={(e) => {
            e.stopPropagation();
            setSelectedModel(asset);
          }}
          width="30px"
          height="30px"
          color={theme.palette.custom.redTypography}
        />
      ),
    },
  ];

  const onCreate = async (modelId: string) => {
    try {
      const model = await fetchAsset(modelId);
      await editAsset(model.id, {
        metadata: {
          ...model?.metadata,
          related_use_case: Array.isArray(model?.metadata?.related_use_case)
            ? [...model.metadata.related_use_case, objectId]
            : [objectId],
        },
      });
      setModels([...JSON.parse(value), modelId]);
      onChange(JSON.stringify([...JSON.parse(value), modelId]));
      setOpenAddModal(false);
    } catch {}
  };

  const onCloseAddModelModal = async () => {
    try {
      const models = await fetchAssets({
        "asset_type_name[]": ["Model"],
        "object_relates_to_id[]": [objectId],
      });
      if (models.results.length === 0) {
        setConnectModels("");
      }
      setOpenAddModal(false);
    } catch {}
  };

  const onRemove = async () => {
    try {
      await editAsset(selectedModel?.id ?? "", {
        metadata: {
          ...selectedModel?.metadata,
          related_use_case: Array.isArray(selectedModel?.metadata?.related_use_case)
            ? selectedModel?.metadata.related_use_case.filter(
                (useCase: string) => useCase !== objectId
              )
            : [],
        },
      });
      setModels(JSON.parse(value).filter((item: string) => item !== selectedModel?.id));
      onChange(
        JSON.stringify(JSON.parse(value).filter((item: string) => item !== selectedModel?.id))
      );
    } catch {}
  };

  useEffect(() => {
    if (connectModels === "Yes" && (models?.length ?? 0) === 0) {
      setOpenAddModal(true);
    }
  }, [connectModels]);

  useEffect(() => {
    if (modelsTypeResponse && modelsTypeResponse.results && modelsTypeResponse.results.length > 0) {
      if (isJSON(modelsTypeResponse.results[0].answer)) {
        const options = JSON.parse(modelsTypeResponse.results[0].answer);
        setModels(options);
      }
    }
  }, [modelsTypeResponse]);

  useEffect(() => {
    if (modelsResponse && modelsResponse.results && modelsResponse.results.length > 0) {
      let options: string[] = [];
      const valueOptions = isJSON(value) ? JSON.parse(value) : [];
      modelsResponse.results.map((model) => options.push(model.id));
      if (Array.isArray(valueOptions)) {
        if (valueOptions.length === 0) {
          setModels(options);
          onChange(JSON.stringify(options));
        }
      }
    }
  }, [modelsResponse]);

  return (
    <>
      <CreateAssetModelModal
        open={openAddModal}
        onClose={onCloseAddModelModal}
        onCreate={onCreate}
        isDatabricks={isDatabricksIntegration}
        relatedObjectId={objectId}
        showConfirmationModal={true}
      />
      {selectedModel && (
        <RemoveRelatedObjectConfirmation
          open={!!selectedModel}
          onClose={() => setSelectedModel(null)}
          mappedObjectId={objectId}
          onRemoved={() => onRemove()}
          objectId={selectedModel.id}
          showErrorSnackbar={false}
        />
      )}
      {isLoading || isLoadingModelsResponse ? (
        <RectangularLoader width="100%" height="100px" />
      ) : (models?.length ?? 0) > 0 ? (
        <APITable
          useGetData={useAssets}
          queryParams={{
            "asset_type_name[]": ["Model"],
            "metadata_related_use_case[]": [objectId],
          }}
          columnsVisibility={columnsVisibility}
          secondComponent={
            <Box
              display="flex"
              flexGrow={1}
              justifyContent="flex-end"
              alignItems="center"
              gap="10px"
            >
              <Button onClick={() => setOpenAddModal(true)}>Connect</Button>
            </Box>
          }
          emptyTableComponent={
            <EmptyTable
              variant="asset"
              label="Models"
              description="Create your Model"
              actionLabel="Create"
              action={() => setOpenAddModal(true)}
            />
          }
          showPagination={false}
          hideColumnVisibility
        />
      ) : (
        <Box display="flex" flexDirection="column" gap="10px">
          <Typography variant="body2">Do you have any models to link?</Typography>
          <YesOrNoSelectField
            value={connectModels}
            onChange={setConnectModels}
            required
            yesLabel="Yes"
            noLabel="No"
          />
        </Box>
      )}
    </>
  );
};
