import { Box, Typography, useTheme } from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { DefaultBadge, StatusBadge } from "../../../atoms/StatusBadge";
import { navigateToFramework } from "../../../components/FrameworkNavigator";
import { useOrganizationContext } from "../../../context/OrganizationContext";
import { ComplianceElement, CustomFieldValue } from "../../../models/types";
import { EditableUserTag } from "../../../organisms/EditableUserTag";
import { ReadOnlyCustomFieldValuesModal } from "../../../organisms/modals/custom-fields/ReadOnlyCustomFieldsValue";
import { stripHTMLTags } from "../../../utilities/common";
import { getFrameworkIcon } from "../../../utilities/FrameworkIconHelper";
import { ShowMore } from "../../ShowMore";
import { TableTextWithEllipsis } from "../../TableTextWithEllipsis";

type ElementDetailsFormProps = {
  element: ComplianceElement;
  selectedUser: string | null;
  setSelectedUser: (userId: string) => void;
  customFields?: CustomFieldValue[];
  disabled?: boolean;
};

export const ElementDetailsForm = ({
  element,
  selectedUser,
  setSelectedUser,
  customFields,
  disabled = false,
}: ElementDetailsFormProps) => {
  const theme = useTheme();
  const inheritColor = theme.palette.custom.secondaryTypography;
  const { setSelectedElement } = useOrganizationContext();
  const navigate = useNavigate();
  const hasCustomFields = customFields && customFields.length > 0;

  const [openCustomFields, setOpenCustomFields] = useState(false);

  return (
    <Box display="flex" flexDirection="column" gap="15px">
      <ReadOnlyCustomFieldValuesModal
        open={openCustomFields}
        onClose={() => setOpenCustomFields(false)}
        objectId={element.id}
      />
      <Typography variant="h3" color={inheritColor}>
        Name
      </Typography>
      <Typography variant="body2">{element?.name}</Typography>
      {!!element?.description && element?.description !== "" && (
        <>
          <Typography variant="h3" color={inheritColor}>
            Description
          </Typography>
          <ShowMore
            description={stripHTMLTags(element.description)}
            maxChar={250}
            richText={false}
          />
        </>
      )}
      <Box display="flex" flexDirection="row" alignItems="center" gap="10px">
        <Typography width="76px" variant="h3" color={inheritColor}>
          Owner
        </Typography>
        <EditableUserTag onChange={setSelectedUser} value={selectedUser} disabled={disabled} />
      </Box>
      {element?.framework_name && (
        <Box display="flex" flexDirection="row" alignItems="center" width="100%" gap="10px">
          <Typography width="76px" variant="h3" color={inheritColor}>
            Framework
          </Typography>
          <DefaultBadge
            renderIcon={() => getFrameworkIcon(element?.framework_name?.name, "small")}
            value={element?.framework_name?.name}
            onClick={
              disabled
                ? () => {}
                : () => {
                    navigateToFramework({ navigate, frameworkId: element?.framework });
                    setSelectedElement(null);
                  }
            }
          />
        </Box>
      )}
      <Box display="flex" flexDirection="row" alignItems="center" gap="10px">
        <Typography width="76px" minWidth="76px" variant="h3" color={inheritColor}>
          Status
        </Typography>
        <StatusBadge status={element?.status as any} />
      </Box>
      {hasCustomFields && (
        <TableTextWithEllipsis
          hideTooltip
          color={theme.palette.custom.blueTypography}
          variant="h4"
          value="View Additional Details"
          onClick={() => setOpenCustomFields(true)}
        />
      )}
    </Box>
  );
};
