import { Box, useTheme } from "@mui/material";
import { ScreenSmallerThen } from "../../utilities/UIHelper";
import { RectangularLoader } from "../../molecules/skeleton-loader/RectangularLoader";
import { OrganizationSettingsGeneralLoader } from "./OrganizationSettingsGeneralLoader";

export const OrganizationSettingsLoader = () => {
  const isSmallScreen = ScreenSmallerThen();
  const pageSize = isSmallScreen ? "unset" : "calc(80vh - 56px)";
  const theme = useTheme();

  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        flexDirection: isSmallScreen ? "column" : "row",
        minHeight: pageSize,
        borderWidth: "1px",
        borderRadius: "6px",
        borderColor: theme.palette.custom.primaryBorder,
        borderStyle: "solid",
        backgroundColor: theme.palette.custom.primaryBackground,
      }}
    >
      <Box
        sx={{
          display: "flex",
          padding: isSmallScreen ? "15px" : "15px 15px 0 15px",
          borderRight: isSmallScreen ? 0 : 1,
          borderBottom: isSmallScreen ? 1 : 0,
          borderColor: "divider",
        }}
      >
        <Box
          sx={{
            minHeight: "35px",
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            paddingRight: "15px",
          }}
        >
          <RectangularLoader height="35px" width="108px" />
          <RectangularLoader height="35px" width="108px" />
          <RectangularLoader height="35px" width="108px" />
          <RectangularLoader height="35px" width="108px" />
        </Box>
      </Box>
      <OrganizationSettingsGeneralLoader />
    </Box>
  );
};
