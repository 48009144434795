import { Box, Typography, useTheme } from "@mui/material";
import { HuggingFaceDataset, HuggingFaceModel } from "../../models/types";
import { SearchableDropdown } from "../../components/SearchableDropdown";
import { useState } from "react";
import { searchHuggingFaceDatasets, searchHuggingFaceModels } from "../../hooks/useHuggingFace";

type AddHuggingFaceDataProps = {
  setSelectedResource: (newField: any) => void;
  selectedResource: HuggingFaceModel | HuggingFaceDataset | null;
};

export const AddHuggingFaceData = (props: AddHuggingFaceDataProps) => {
  const { setSelectedResource, selectedResource } = props;
  const theme = useTheme();
  const innerTextColor = theme.palette.custom.secondaryTypography;

  const typeOptions: string[] = ["Model", "Dataset"];
  const [type, setType] = useState<string | null>(null);

  return (
    <Box display="flex" flexDirection="column" gap="10px">
      <Typography variant="h3" color={innerTextColor}>
        Type
      </Typography>
      <SearchableDropdown
        label="Type"
        value={type}
        onChange={setType}
        getOptions={(search: string) =>
          typeOptions.filter((option) => option.toLowerCase().includes(search.toLowerCase()))
        }
        getOptionLabel={(option) => option}
        isOptionEqualToValue={(a, b) => a === b}
      />
      {type && (
        <>
          <Typography variant="h3" color={innerTextColor}>
            {type}
          </Typography>
          <SearchableDropdown
            label="Select or Search"
            required
            error={false}
            value={selectedResource || null}
            onChange={(newField) => newField != null && setSelectedResource(newField)}
            getOptions={type === "Model" ? searchHuggingFaceModels : searchHuggingFaceDatasets}
            isOptionEqualToValue={(a, b) => a === b}
            getOptionLabel={(option) => option?.id}
          />
        </>
      )}
    </Box>
  );
};
