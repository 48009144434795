import { Box, Typography, useTheme } from "@mui/material";
import { NavbarIcon } from "../../atoms/navbar/Icon";
import { CircularLoader } from "../../molecules/skeleton-loader/CircularLoader";
import { RectangularLoader } from "../../molecules/skeleton-loader/RectangularLoader";
import { ListViewLoader } from "../../layouts/skeleton-loaders/ListViewLoader";
import { TasksColumnsVisibility } from "../tables/tasks/TasksColumnsVisibility";

export const ImpactAssessmentReviewLoader = () => {
  const theme = useTheme();
  return (
    <Box display="flex" flexDirection="column" width="100%">
      <Box
        padding="10px"
        minHeight="800px"
        border={`1px solid ${theme.palette.custom.primaryBorder}`}
        display="flex"
        flexDirection="column"
        bgcolor={theme.palette.custom.primaryBackground}
        gap="10px"
        borderRadius="6px"
      >
        <RectangularLoader width="100%" height="50px" />
        <RectangularLoader width="100%" height="50px" />
        <RectangularLoader width="100%" height="50px" />
        <RectangularLoader width="100%" height="50px" />
        <RectangularLoader width="100%" height="50px" />
        <RectangularLoader width="100%" height="50px" />
        <RectangularLoader width="100%" height="50px" />
        <RectangularLoader width="100%" height="50px" />
        <RectangularLoader width="100%" height="50px" />
        <RectangularLoader width="100%" height="50px" />
      </Box>
    </Box>
  );
};

export const ImpactAssessmentDetailLoader = () => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        justifyContent: "space-between",
        alignItems: "flex-start",
        padding: "0 0 15px 0",
      }}
    >
      <Box
        paddingBottom={"0px"}
        borderRadius="6px"
        border="1px solid"
        width="100%"
        minHeight="100%"
        maxHeight="100%"
        // overflow="auto"
        display="flex"
        flexDirection="column"
        borderColor={theme.palette.custom.primaryBorder}
        bgcolor={theme.palette.custom.primaryBackground}
      >
        <Box sx={{ width: "100%", display: "flex" }}>
          <ImpactAssessmentSidebarLoading />
          <Box width="100%" display="flex" flexDirection="column" gap="20px" padding="10px">
            <ImpactAssessmentDetailsFormLoader />
            <FairoAISuggestionsLoader />
            <Typography variant="h3">Tasks Assigned to Me</Typography>
            <ListViewLoader
              paddingBottom={false}
              columnsVisibility={TasksColumnsVisibility({
                onEdit: (taskId: string) => {},
                showCompleted: false,
              })}
              tableSize="small"
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export const ImpactAssessmentTasksLoader = () => {
  return (
    <Box display="flex" flexDirection="column" gap="10px" width="100%">
      <RectangularLoader width="100%" height="70px" />
      <RectangularLoader width="100%" height="70px" />
    </Box>
  );
};

export const PotentialRisksLoader = () => {
  return (
    <Box display="flex" flexDirection="row" padding="10px" width="100%" gap="10px">
      <Box display="flex" flexDirection="column" gap="10px">
        <BorderLoader width="100%" height="70px" />
        <BorderLoader width="100%" height="70px" />
        <BorderLoader width="100%" height="70px" />
        <BorderLoader width="100%" height="70px" />
        <BorderLoader width="100%" height="70px" />
        <BorderLoader width="100%" height="70px" />
      </Box>
      <BorderLoader width="100%" height="95%" />
    </Box>
  );
};

export const SuggestedActionsLoader = () => {
  return (
    <Box
      display="flex"
      justifyContent="center"
      overflow="auto"
      flexDirection="row"
      flexWrap="wrap"
      flexGrow={1}
      padding="10px"
      gap="10px"
    >
      <SuggestedActionCard />
      <SuggestedActionCard />
      <SuggestedActionCard />
      <SuggestedActionCard />
    </Box>
  );
};

export const SuggestedActionCard = () => {
  const theme = useTheme();
  return (
    <Box
      border={`1px solid ${theme.palette.custom.secondaryBorder}`}
      bgcolor={theme.palette.custom.primaryBackground}
      padding="10px"
      flexDirection="column"
      gap="10px"
      width="100%"
      height="75px"
      borderRadius="3px"
    >
      <RectangularLoader width="100%" height="100%" />
    </Box>
  );
};

export const ImpactAssessmentSidebarLoading = () => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        maxWidth: "286px",
        justifyContent: "flex-start",
        overflowY: "auto",
        overflowX: "hidden",
        gap: "15px",
        padding: "15px",
        borderRight: "1px solid",
        borderRightColor: theme.palette.custom.secondaryBorder,
      }}
    >
      <RectangularLoader width="100%" height="22px" />
      <RectangularLoader width="100%" height="33px" />
      <Typography variant="h3">Progress</Typography>
      <RectangularLoader width="100%" height="20px" />
      <Typography variant="h3">Details</Typography>
      <RectangularLoader width="100%" height="22px" />
      <Typography variant="h3">Questionnaire Sections</Typography>
      <ImpactAssessmentSectionsLoader />
    </Box>
  );
};

export const ImpactAssessmentSectionsLoader = () => {
  return (
    <Box display="flex" flexDirection="column" gap="10px">
      <RectangularLoader width="100%" height="22px" />
      <RectangularLoader width="100%" height="22px" />
      <RectangularLoader width="100%" height="22px" />
      <RectangularLoader width="100%" height="22px" />
      <RectangularLoader width="100%" height="22px" />
    </Box>
  );
};

export const AssignedUsersComponentLoader = ({
  title,
  count,
}: {
  title: string;
  count: number;
}) => {
  const theme = useTheme();
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="flex-start"
      alignItems="flex-start"
      gap="10px"
      flexGrow={1}
    >
      <Typography variant="h3" color={theme.palette.custom.secondaryTypography}>
        {title}
      </Typography>
      <Box display="flex" flexDirection="row" gap="10px">
        {Array.from({ length: count }).map((_, index) => (
          <Box
            display="flex"
            flexDirection="column"
            gap="5px"
            alignItems="center"
            justifyContent="center"
          >
            <CircularLoader width="25px" height="25px" />
            <RectangularLoader width="50px" height="22px" />
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export const ImpactAssessmentDetailsFormLoader = () => {
  const theme = useTheme();
  return (
    <Box display="flex" flexDirection="column" gap="10px">
      <Typography variant="h3" color={theme.palette.custom.secondaryTypography}>
        Description
      </Typography>
      <RectangularLoader width="100%" height="44px" />
      <Box display="flex" flexDirection="row" flexWrap="wrap">
        <AssignedUsersComponentLoader count={3} title="Reviewers" />
        <AssignedUsersComponentLoader count={1} title="Assessment Owner" />
        <AssignedUsersComponentLoader count={1} title="Use Case Owner" />
      </Box>
    </Box>
  );
};

export const FairoAISuggestionsLoader = () => {
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === "dark";
  return (
    <Box display="flex" flexDirection="column" width="100%" gap="15px">
      <Box display="flex" flexDirection="row" alignItems="center" gap="5px">
        <NavbarIcon
          sx={{
            width: "24px",
            height: "24px",
            minHeight: "24px",
            minWidth: "24px",
          }}
          color={theme.palette.custom.mainButton}
          variant="fairo-ai"
        />
        <Typography
          color={
            isDarkMode ? theme.palette.custom.whiteTypography : theme.palette.custom.mainButton
          }
          variant="h3"
        >
          Fairo AI Suggestions
        </Typography>
      </Box>
      <BorderLoader width="100%" height="100%" />
    </Box>
  );
};

export const BorderLoader = ({ width, height }: { width: string; height: string }) => {
  const theme = useTheme();
  return (
    <Box
      display="flex"
      height="354px"
      overflow="hidden"
      flexDirection="row"
      border={`1px solid ${theme.palette.custom.secondaryBorder}`}
      borderRadius="6px"
    >
      <RectangularLoader width={width} height={height} />
    </Box>
  );
};

export const ImpactAssessmentSectionLoader = () => {
  return (
    <Box display="flex" flexDirection="column" padding="10px" gap="10px">
      <RectangularLoader width="100%" height="50px" />
      <RectangularLoader width="100%" height="50px" />
      <RectangularLoader width="100%" height="50px" />
      <RectangularLoader width="100%" height="50px" />
      <RectangularLoader width="100%" height="50px" />
      <RectangularLoader width="100%" height="50px" />
      <RectangularLoader width="100%" height="50px" />
    </Box>
  );
};

export const ImpactAssessmentSingleSectionLoader = () => {
  return (
    <Box display="flex" flexDirection="column" gap="10px" padding="10px">
      <RectangularLoader width="100%" height="50px" />
    </Box>
  );
};
