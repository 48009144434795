import { Box, Typography, useTheme } from "@mui/material";
import { useState } from "react";
import { SearchableDropdown } from "../../components/SearchableDropdown";
import { searchGithubRepositories } from "../../hooks/usePlugins";
import { GithubRepository } from "../../models/types";

type AddGithubRepositoryProps = {
  setSelectedResource: (newField: any) => void;
  selectedResource: GithubRepository | null;
};

export const AddGithubRepository = (props: AddGithubRepositoryProps) => {
  const { setSelectedResource, selectedResource } = props;
  const theme = useTheme();
  const innerTextColor = theme.palette.custom.secondaryTypography;

  return (
    <Box display="flex" flexDirection="column" gap="10px">
      <Typography variant="h3" color={innerTextColor}>
        Repository
      </Typography>
      <SearchableDropdown
        label="Select or Search"
        required
        error={false}
        value={selectedResource || null}
        onChange={(newField) => newField != null && setSelectedResource(newField)}
        getOptions={searchGithubRepositories}
        isOptionEqualToValue={(a, b) => a === b}
        getOptionLabel={(option) => option?.name}
      />
    </Box>
  );
};
