import { Box, useTheme } from "@mui/material";
import { useState } from "react";
import { PluginType } from "../models/types";
import { DatabricksPluginGuideModal } from "../organisms/modals/integrations/DatabricksGuide";
import { TableTextWithEllipsis } from "./TableTextWithEllipsis";

type PluginGuideHandlerProps = {
  pluginType: PluginType;
};

export const PluginGuideHandler = ({ pluginType }: PluginGuideHandlerProps) => {
  const [openDatabricksGuide, setOpenDatabricksGuide] = useState(false);
  const theme = useTheme();
  const handleOpen = () => {
    if (pluginType === "databricks") {
      setOpenDatabricksGuide(true);
    }
  };

  const supportedTypes = ["databricks"];

  // When plugin type don't match supported types, return null
  if (!supportedTypes.some((type) => type === pluginType)) {
    return null;
  }

  return (
    <Box display="flex" flexGrow={1} justifyContent="center">
      <TableTextWithEllipsis
        fullWidth={false}
        hideTooltip
        value="Open Guide"
        onClick={() => handleOpen()}
        color={theme.palette.custom.hyperlink}
      />
      <DatabricksPluginGuideModal
        open={openDatabricksGuide}
        onClose={() => setOpenDatabricksGuide(false)}
      />
    </Box>
  );
};
