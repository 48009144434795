import { Box, useTheme } from "@mui/material";
import SecondaryBox from "../../atoms/box/SecondaryBox";
import { PageDetailLayout } from "../../components/layout/PageDetailLayout";
import { RectangularLoader } from "../../molecules/skeleton-loader/RectangularLoader";
import { evidenceTestInScopeResourcesListColumnsVisibility } from "../../organisms/impact-assessment/EvidenceTestInScopeResourcesListColumnsVisibility";
import { APITableLoader } from "../../organisms/skeleton-loaders/APITableLoader";
import { DetailsLoader } from "../../organisms/skeleton-loaders/DetailsLoader";
import { columnsVisibility } from "../../utilities/columns-visibility/RelatedControlsListColumnVisibility";

export const EvidenceLogicLoader = () => {
  return (
    <SecondaryBox gap="10px">
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        gap="10px"
      >
        <RectangularLoader width="200px" height="34px" />
        <RectangularLoader width="64px" height="34px" />
      </Box>
      <RectangularLoader width="100%" height="34px" />
      <RectangularLoader width="100%" height="34px" />
      <RectangularLoader width="100%" height="34px" />
    </SecondaryBox>
  );
};

export const EvidenceDetailsLoader = () => {
  const theme = useTheme();

  return (
    <PageDetailLayout
      leftComponent={
        <Box sx={{ width: "100%", display: "flex", flexDirection: "column", gap: "20px" }}>
          <SecondaryBox>
            <DetailsLoader />
          </SecondaryBox>
          <EvidenceLogicLoader />
          <Box
            sx={{
              maxWidth: "100%",
              width: "100%",
              height: "100%",
              overflow: "visible",
            }}
          >
            <Box height={"100%"}>
              <Box
                paddingBottom={"0px"}
                borderRadius="6px"
                border="1px solid"
                minHeight="100%"
                maxHeight="100%"
                overflow="auto"
                borderColor={theme.palette.custom.primaryBorder}
                bgcolor={theme.palette.custom.primaryBackground}
              >
                <APITableLoader
                  columnsVisibility={evidenceTestInScopeResourcesListColumnsVisibility({
                    onSelectResource: () => {},
                    onExclude: () => {},
                  })}
                  tableSize="small"
                  secondComponent={true}
                  tableSearch={true}
                />
              </Box>
            </Box>
          </Box>
          <EvidenceLogicLoader />
          <Box
            sx={{
              maxWidth: "100%",
              width: "100%",
              height: "100%",
              overflow: "visible",
            }}
          >
            <Box height={"100%"}>
              <Box
                paddingBottom={"0px"}
                borderRadius="6px"
                border="1px solid"
                minHeight="100%"
                maxHeight="100%"
                overflow="auto"
                borderColor={theme.palette.custom.primaryBorder}
                bgcolor={theme.palette.custom.primaryBackground}
              >
                <APITableLoader
                  columnsVisibility={columnsVisibility}
                  tableSize="small"
                  secondComponent={true}
                  tableSearch={true}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      }
    />
  );
};
