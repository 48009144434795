import { Box, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { IconButton } from "../../../../atoms/IconButton";
import { navigateToElement } from "../../../../components/ElementNavigator";
import { SmallModal } from "../../../../components/SmallModal";
import { useOrganizationContext } from "../../../../context/OrganizationContext";
import { useAuth } from "../../../../hooks/useAuth";
import { useComplianceElement } from "../../../../hooks/useComplianceElements";
import { useCustomFieldValues } from "../../../../hooks/useCustomFields";
import { ElementDetailsForm } from "../../../../molecules/compliance/Library/ElementDetailsForm";
import { ElementDetailsProgressForm } from "../../../../molecules/compliance/Library/ElementDetailsProgress";
import { TimestampWithAction } from "../../../../molecules/TimestampWithAction";
import { editComplianceElement } from "../../../../services/ComplianceElements";
import { CustomAxiosError } from "../../../../utilities/ErrorResponseHelper";
import { isCreatedByFairo } from "../../../../utilities/UIHelper";
import { ElementAdditionalActionsTable } from "../../../tables/compliance/library/ElementAdditionalActions";
import { ElementDetailsDocumentsTable } from "../../../tables/compliance/library/ElementDetailsDocuments";
import { ElementDetailsEvidenceTestsTable } from "../../../tables/compliance/library/ElementDetailsEvidenceTests";
import { ElementDetailsPoliciesTable } from "../../../tables/compliance/library/ElementDetailsPolicies";

type ElementDetailModalProps = {
  elementId: string;
  open: boolean;
  onClose: () => void;
};

export const ElementDetailModal = (props: ElementDetailModalProps) => {
  const { elementId, open, onClose } = props;
  const { data: element, isLoading } = useComplianceElement(elementId, { fairo_data: true });
  const theme = useTheme();
  const navigate = useNavigate();
  const { user } = useAuth();
  const { selectedOrganization, currentAdminView } = useOrganizationContext();
  const adminView = currentAdminView === "admin";
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedUser, setSelectedUser] = useState<string | null>(null);
  const { data: customFields } = useCustomFieldValues(elementId);
  const onUpdateElement = async () => {
    try {
      setLoading(true);
      await editComplianceElement(elementId, {
        assignee: selectedUser,
      });
    } catch (error) {
      if (error instanceof CustomAxiosError) {
        error.showAuditLogSnackbar(`Failed to update element`);
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setSelectedUser(element?.assignee);
  }, [element]);

  return (
    <SmallModal
      open={open}
      position="right"
      transparentBackdrop
      onClose={onClose}
      isLoading={isLoading}
      size="medium"
      title={`${element?.element_type}: ${element?.external_id}`}
      rightComponent={
        user?.is_fairo_admin &&
        !selectedOrganization &&
        adminView && (
          <IconButton
            color={theme.palette.custom.hyperlink}
            height="15px"
            width="15px"
            onClick={() => {
              onClose();
              navigateToElement({
                navigate,
                elementId: element.id,
                external_id: element.external_id,
                fairoData: isCreatedByFairo(element.updated_by),
              });
            }}
            variant="edit-icon"
          />
        )
      }
    >
      <Box display="flex" flexDirection="column" gap="15px">
        <ElementDetailsForm
          selectedUser={selectedUser}
          setSelectedUser={setSelectedUser}
          element={element}
          customFields={customFields}
        />
        <ElementDetailsProgressForm element={element} />
        <ElementDetailsDocumentsTable element={element} elementId={elementId} />
        <ElementDetailsPoliciesTable element={element} elementId={elementId} />
        <ElementDetailsEvidenceTestsTable element={element} elementId={elementId} />
        <ElementAdditionalActionsTable element={element} elementId={elementId} />
        {/* <ElementOtherMappedElementsTable
          onClose={onClose}
          elementId={elementId}
          element={element}
        /> */}
        <TimestampWithAction
          direction="row"
          buttonLabel="Save"
          isLoading={loading}
          onClick={onUpdateElement}
          createdBy={element?.created_by}
          createdOn={element?.created_on}
          updatedBy={element?.common_data_updated?.updated_by}
          updatedOn={element?.common_data_updated?.updated_on}
          isUpdated={element?.assignee !== selectedUser}
        />
      </Box>
    </SmallModal>
  );
};
