import { Box, Button, Typography } from "@mui/material";
import { RegistryFieldStyleRule } from "../../models/types";
import { isJSON } from "../../utilities/UIHelper";
import { useState } from "react";
import { AddRegistryFieldRuleModal } from "../../organisms/modals/compliances/AddRegistryFieldRuleModal";
import { StyleRules } from "../../components/StyleRules";

type ColorSchemeCustomFieldProps = {
  value: string;
  onChange: (newVal: any) => void;
};

export const ColorSchemeCustomField = (props: ColorSchemeCustomFieldProps) => {
  const { value, onChange } = props;
  const values: RegistryFieldStyleRule[] =
    typeof value === "string" && isJSON(value) ? JSON.parse(value) : value;

  const [openAddRule, setOpenAddRule] = useState<boolean>(false);
  const [selectedRule, setSelectedRule] = useState<RegistryFieldStyleRule | null>(null);
  const [rules, setRules] = useState<RegistryFieldStyleRule[]>(values);

  const onAddRule = (rule: RegistryFieldStyleRule) => {
    let newRules = [];
    if (selectedRule) {
      newRules = rules.map((r) => (r === selectedRule ? rule : r));
    } else {
      newRules = [...rules, rule];
    }
    setRules(newRules);
    onChange(JSON.stringify(newRules));
    setOpenAddRule(false);
  };

  return (
    <>
      <AddRegistryFieldRuleModal
        open={openAddRule}
        onClose={() => setOpenAddRule(false)}
        onSave={onAddRule}
        rule={selectedRule ?? undefined}
      />
      <Box display="flex" flexDirection="column" gap="10px">
        <Box display="flex" alignItems="center" justifyContent="space-between" gap="10px">
          <Typography variant="h3">Style Rules</Typography>
          <Button variant="text" onClick={() => setOpenAddRule(true)}>
            Add
          </Button>
        </Box>
        <StyleRules
          rules={rules}
          onEdit={(rule: RegistryFieldStyleRule) => {
            setSelectedRule(rule);
            setOpenAddRule(true);
          }}
          onRemove={(idx: number) => {
            setRules(rules.filter((_, i) => i !== idx));
          }}
        />
      </Box>
    </>
  );
};
