import { Box, Typography, useTheme } from "@mui/material";
import { OverviewCard } from "../molecules/OverviewCard";

type CreatePolicyOptionProps = {
  /** Item name */
  name: string;
  /** Item description */
  description: string;
  /** Icon component to display on left side of the card */
  icon: React.ReactNode;
  /** On name click */
  onClick?: () => void;
  fullHeight?: boolean;
};

export const CreatePolicyOption = (props: CreatePolicyOptionProps) => {
  const { name, description, icon, onClick, fullHeight = true } = props;
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === "dark";

  return (
    <Box
      flexGrow="1"
      flexBasis={fullHeight ? "307px" : "unset"}
      onClick={onClick ? () => onClick() : undefined}
      sx={{
        ":hover": {
          cursor: "pointer",
          backgroundColor: theme.palette.custom.primaryBackground,
        },
      }}
    >
      <OverviewCard
        size="small"
        icon={
          <Box
            width="50px"
            height="50px"
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            {icon}
          </Box>
        }
        height={fullHeight ? "100%" : "unset"}
        name={name}
        titleVariant="h3"
        secondComponent={<Typography variant="body2">{description}</Typography>}
        nameRightComponent={<></>}
      />
    </Box>
  );
};
