import { Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { PluginData } from "../../models/types";
import { PluginOAuthForm } from "./PluginOAuthForm";
import { PluginTokenAuthForm } from "./PluginTokenAuthForm";

type PluginAuthFormProps = {
  plugin: PluginData;
  pluginData?: {};
  updatePlugin: (params: any) => Promise<void>;
  onCancel?: () => void;
  setOauthRedirect?: () => void;
};

export const PluginAuthForm = (props: PluginAuthFormProps) => {
  const { plugin, pluginData, updatePlugin, onCancel, setOauthRedirect } = props;
  const [pluginParams, setPluginParams] = useState<any>({});

  useEffect(() => {
    if (pluginData) {
      setPluginParams(pluginData);
    }
  }, [plugin, pluginData]);

  return (
    <>
      {plugin.auth.map((auth: any, index: number) => (
        <>
          {index !== 0 && <Typography align={"center"}>OR</Typography>}
          <React.Fragment key={auth.auth_type}>
            {(() => {
              switch (auth.auth_type) {
                case "boolean":
                  // return <PluginBooleanForm auth={auth} pluginParams={pluginParams} setPluginParams={setPluginParams} />;
                  return <></>;
                case "oauth":
                  return <PluginOAuthForm auth={auth} setOauthRedirect={setOauthRedirect} />;
                case "token_auth":
                  return (
                    <PluginTokenAuthForm
                      onCancel={() => {
                        if (onCancel) {
                          onCancel();
                        }
                      }}
                      updatePlugin={updatePlugin}
                      plugin={plugin}
                      pluginData={pluginData}
                      pluginParams={pluginParams}
                      setPluginParams={setPluginParams}
                      auth={auth}
                    />
                  );
                default:
                  return <></>;
              }
            })()}
          </React.Fragment>
        </>
      ))}
    </>
  );
};
