import { Box, Typography, useTheme } from "@mui/material";

type PluginCategoryProps = {
  name?: string;
  count?: number;
};

export const PluginCategory = (props: PluginCategoryProps) => {
  const { name, count } = props;

  const theme = useTheme();
  const isDarkMode = theme.palette.mode === "dark";

  const isCount = !!count;

  return (
    <Box
      padding="4px 10px"
      maxWidth="100%"
      borderRadius="3px"
      height="24px"
      sx={{
        backgroundColor: theme.palette.custom.secondaryBackground,
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Typography
        variant="h4"
        fontSize="12px"
        sx={{
          height: "15px",
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          maxWidth: "100px",
        }}
      >
        {isCount ? ` +${count}` : name}
      </Typography>
    </Box>
  );
};
