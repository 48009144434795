import { Box, Typography } from "@mui/material";
import { useEffect } from "react";
import { DefaultBadge } from "../../atoms/StatusBadge";
import { SearchableDropdown } from "../../components/SearchableDropdown";
import { navigateToUseCaseInNewTab } from "../../components/UseCaseNavigator";
import { useUseCase, useUseCases } from "../../hooks/useUseCases";
import { FieldHandlerProps, UseCase } from "../../models/types";
import { Awaitable } from "../../utilities/common";
import { RectangularLoader } from "../skeleton-loader/RectangularLoader";

export const UseCaseCustomField = (
  props: FieldHandlerProps & {
    search: (searchText: string) => Awaitable<UseCase[]>;
    setObjectAttributes?: (obj: any) => void;
  }
) => {
  const { value, search, onChange, error, setObjectAttributes, required } = props;
  const { data: UseCase } = useUseCase(value || "");
  useEffect(() => {
    if (UseCase && setObjectAttributes) {
      setObjectAttributes(UseCase ?? {});
    }
  }, [UseCase]);
  return (
    <SearchableDropdown<UseCase>
      label="UseCase"
      required={required}
      error={error}
      value={UseCase || null}
      onChange={(newValue) => (newValue !== null ? onChange(newValue.id) : onChange(null))}
      getOptions={search}
      isOptionEqualToValue={(a, b) => a.id === b.id}
      getOptionLabel={(obj) => obj.name ?? "-"}
    />
  );
};

export const UseCaseListCustomField = (
  props: FieldHandlerProps & {
    search: (searchText: string) => Awaitable<UseCase[]>;
    setObjectAttributes?: (obj: any) => void;
  }
) => {
  const { value, search, onChange, error, setObjectAttributes, required } = props;
  const { data: items } = useUseCases({ id: value });
  useEffect(() => {
    if (items && items.results && items.results.length && setObjectAttributes) {
      setObjectAttributes(items.results[0] ?? {});
    }
  }, [items]);
  return (
    <SearchableDropdown<UseCase>
      label="UseCase"
      required={required}
      error={error}
      multiple={true}
      value={value.length === 0 ? [] : items?.results ?? []}
      onChange={(newValue) => newValue !== null && onChange(newValue.map((item) => item.id))}
      getOptionLabel={(obj) => obj.name ?? "-"}
      getOptions={search}
      isOptionEqualToValue={(a, b) => a.id === b.id}
    />
  );
};

type UseCaseReadOnlyCustomFieldProps = {
  value: string[];
};

export const UseCaseListReadOnlyCustomField = ({ value }: UseCaseReadOnlyCustomFieldProps) => {
  const { data: metrics, isLoading } = useUseCases({ id: value });

  return isLoading ? (
    <RectangularLoader height="20px" width="100%" />
  ) : metrics?.count && value.length > 0 ? (
    <Box display="flex" gap="5px" flexWrap="wrap">
      {metrics.results.map((obj, index) => {
        return (
          <DefaultBadge
            key={index}
            value={obj?.name}
            onClick={() => navigateToUseCaseInNewTab(obj)}
          />
        );
      })}
    </Box>
  ) : (
    <Typography variant="body2">No use cases</Typography>
  );
};
