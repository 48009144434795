import { Box, Grid, Stack, Typography, useTheme } from "@mui/material";
import { RectangularLoader } from "../../molecules/skeleton-loader/RectangularLoader";
import { ScreenSmallerThen } from "../../utilities/UIHelper";

export const UserSettingsLoader = () => {
  const theme = useTheme();
  const isSmallScreen = ScreenSmallerThen();
  const pageSize = isSmallScreen ? "unset" : `calc(80vh - 56px)`;
  return (
    <Box sx={{ maxWidth: "100%", height: pageSize, overflow: "visible" }}>
      <Box height="100%">
        <Box
          paddingBottom={"0px"}
          borderRadius="6px"
          border="1px solid"
          minHeight="100%"
          maxHeight="100%"
          overflow="auto"
          borderColor={theme.palette.custom.primaryBorder}
          bgcolor={theme.palette.custom.primaryBackground}
        >
          <Grid container columns={12} padding={2} display="flex" justifyContent="center">
            <Grid item xs={12} mt={2} display="flex" justifyContent="center">
              <Grid item xs={12} md={8} lg={6}>
                <Typography
                  sx={{
                    fontSize: "18px",
                    fontWeight: 600,
                    paddingBottom: 2,
                  }}
                  color="text.primary"
                >
                  Account Settings
                </Typography>
                <Stack gap="15px">
                  <RectangularLoader width="100%" height="35px" />
                  <RectangularLoader width="100%" height="35px" />
                  <RectangularLoader width="100%" height="35px" />
                  <Box display="flex" justifyContent="space-between" width="100%">
                    <RectangularLoader width="35%" height="35px" />
                    <RectangularLoader width="35%" height="35px" />
                  </Box>
                  <RectangularLoader width="100%" height="35px" />
                  <RectangularLoader width="100%" height="35px" />
                  <RectangularLoader width="50%" height="35px" />
                  <Box display="flex" justifyContent="space-between" width="100%">
                    <RectangularLoader width="35%" height="35px" />
                    <RectangularLoader width="35%" height="35px" />
                  </Box>
                  <Box display="flex" justifyContent="space-between" width="100%">
                    <RectangularLoader width="45%" height="145px" />
                    <RectangularLoader width="45%" height="145px" />
                  </Box>
                </Stack>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
};
