import { Box, Button, Typography } from "@mui/material";
import { SmallModal } from "../../../components/SmallModal";
import { openSnackbar } from "../../../context/SnackbarContext";
import { deleteElementMapping, getElementMappings } from "../../../services/ElementMappingService";

type RemoveRelatedObjectConfirmationProps = {
  open: boolean;
  onClose: () => void;
  objectId: string;
  mappedObjectId: string;
  onRemoved: () => void;
  showErrorSnackbar?: boolean;
};

export const RemoveRelatedObjectConfirmation = (props: RemoveRelatedObjectConfirmationProps) => {
  const { open, objectId, mappedObjectId, onRemoved, onClose, showErrorSnackbar = true } = props;

  const onRemove = async () => {
    try {
      const elementMappings = await getElementMappings({
        from_object: [objectId],
        to_object: [mappedObjectId],
        fairo_data: false,
      });
      if (elementMappings.data && elementMappings?.data?.results?.length > 0) {
        const elementMappingObject = elementMappings.data.results[0];
        await deleteElementMapping(elementMappingObject.id, {});
        return openSnackbar("Successfully removed association", "success");
      }
      showErrorSnackbar && openSnackbar("No associations were found, please try again", "error");
    } catch (error) {
      openSnackbar("Failed to remove association", "error");
    } finally {
      onRemoved();
      onClose();
    }
  };

  return (
    <SmallModal open={open} size="small" onClose={onClose} title={"Remove Association"}>
      <Box gap="10px" display="flex" flexDirection="column">
        <Typography variant="body2">
          This action will not delete the object, it will remove the association between the
          objects. Are you sure you want to proceed?
        </Typography>
        <Button onClick={() => onRemove()}>Yes, remove association</Button>
        <Button variant="text" color="error" onClick={() => onClose()}>
          Cancel
        </Button>
      </Box>
    </SmallModal>
  );
};
