import { useEffect } from "react";
import { SearchableDropdown } from "../components/SearchableDropdown";
import { APIFetchOptions, APIGetListHook, APIGetObjectHook } from "../models/types";
import { Awaitable } from "../utilities/common";

type GenericFieldProps<T extends { id: string }, K extends string | string[] | null> = {
  useGetData: APIGetObjectHook<T>;
  search: (searchText: string) => Awaitable<T[]>;
  setObjectAttributes?: (obj: any) => void;
  label: string;
  nameKey: keyof T;
  descriptionKey?: keyof T;
  onChangeKey?: keyof T;
  getName?: (object: T) => string;
  getDescription?: (object: T) => string;
  params?: any;
  required?: boolean;
  value: K;
  onChange: (value: K | null, optionalParams?: any) => void;
  error?: boolean;
  errorMessage?: string;
  icon?: (object: T) => React.ReactNode;
  actionButton?: [string, () => void];
  disabled?: boolean;
};

export const GenericCustomField = <T extends { id: string }, K extends string | null>(
  props: GenericFieldProps<T, K>
) => {
  const {
    value,
    search,
    onChange,
    error = false,
    setObjectAttributes,
    required = false,
    useGetData,
    label,
    nameKey,
    descriptionKey,
    params,
    onChangeKey,
    actionButton,
    icon,
    disabled = false,
  } = props;
  const { data } = useGetData(value || "", params);
  useEffect(() => {
    if (data && setObjectAttributes) {
      setObjectAttributes(data ?? {});
    }
  }, [data]);

  const handleNameAndDescription = (obj: T): [string, string] => {
    try {
      let name = "",
        description = "";
      if (props.getName) {
        name = props.getName(obj);
      } else {
        name = obj[nameKey] as string;
      }
      if (props.getDescription) {
        description = props.getDescription(obj);
      } else {
        description = descriptionKey ? (obj[descriptionKey] as string) : "";
      }
      return [name, description];
    } catch (e) {
      return [obj.id, ""];
    }
  };

  return (
    <SearchableDropdown<T>
      label={label}
      required={required}
      error={error}
      value={data || null}
      onChange={(newValue: any) =>
        newValue != null
          ? onChange(onChangeKey ? newValue[onChangeKey] : newValue.id)
          : onChange(null)
      }
      getOptions={search}
      isOptionEqualToValue={(a, b) => a.id === b.id}
      getSummaryDescription={(obj) => handleNameAndDescription(obj)}
      icon={(option) => (icon ? icon(option) : null)}
      actionButton={actionButton}
      disabled={disabled}
    />
  );
};

type GenericListCustomFieldProps<
  T extends { id: string },
  K extends string[] | null,
  P extends APIFetchOptions
> = {
  useGetData: APIGetListHook<T, P>;
  search: (searchText: string) => Awaitable<T[]>;
  setObjectAttributes?: (obj: any) => void;
  label: string;
  nameKey: keyof T;
  descriptionKey: keyof T;
  params: P;
  required?: boolean;
  value: K;
  onChange: (value: K[] | null, optionalParams?: any) => void;
  error?: boolean;
  errorMessage?: string;
  onChangeKey?: keyof T;
  getName?: (object: T) => string;
  getDescription?: (object: T) => string;
  disabled?: boolean;
};

export const GenericListCustomField = <
  T extends { id: string },
  K extends string[],
  P extends APIFetchOptions
>(
  props: GenericListCustomFieldProps<T, K, P>
) => {
  const {
    value,
    search,
    onChange,
    error,
    setObjectAttributes,
    required,
    params,
    useGetData,
    label,
    nameKey,
    descriptionKey,
    onChangeKey,
    disabled = false,
  } = props;
  const { data } = useGetData({ id: value, ...params });

  const handleNameAndDescription = (obj: T): [string, string] => {
    try {
      let name = "",
        description = "";
      if (props.getName) {
        name = props.getName(obj);
      } else {
        name = obj[nameKey] as string;
      }
      if (props.getDescription) {
        description = props.getDescription(obj);
      } else {
        description = obj[descriptionKey] as string;
      }
      return [name, description];
    } catch (e) {
      return [obj.id, ""];
    }
  };

  useEffect(() => {
    if (data && data.results && data.results.length && setObjectAttributes) {
      setObjectAttributes(data.results[0] ?? {});
    }
  }, [data]);

  return (
    <SearchableDropdown<T>
      label={label}
      required={required}
      error={error}
      multiple={true}
      value={value.length === 0 ? [] : data?.results ?? []}
      onChange={(newValue: any) =>
        newValue != null &&
        onChange(newValue.map((item: any) => (onChangeKey ? item[onChangeKey] : item.id)))
      }
      getOptionLabel={(obj) => (props.getName ? props.getName(obj) : (obj[nameKey] as string))}
      getOptions={search}
      isOptionEqualToValue={(a, b) =>
        onChangeKey ? a[onChangeKey] === b[onChangeKey] : a.id === b.id
      }
      getSummaryDescription={(obj) => handleNameAndDescription(obj)}
      disabled={disabled}
    />
  );
};
