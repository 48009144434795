import { Box, useTheme } from "@mui/material";
import { GraphLoader } from "../../organisms/skeleton-loaders/GraphLoader";
import { QuickLinksLoader } from "../../organisms/skeleton-loaders/QuickLinksLoader";
import {
  documentationListColumnsVisibility,
  policyListColumnsVisibility,
} from "../../utilities/columns-visibility/AssetListColumnsVisibility";
import { ListViewLoader } from "./ListViewLoader";

export const AssetListPageLoader = ({ type }: { type: "policy" | "documentation" }) => {
  const theme = useTheme();

  return (
    <Box paddingBottom="60px">
      <Box
        sx={{
          height: "100%",
          gap: "15px",
          display: "flex",
          flexDirection: "column",
          overflow: "auto",
        }}
      >
        <Box display="flex" gap="15px" width="100%" height="300px">
          <Box width="60%">
            <Box
              display="flex"
              flexDirection="column"
              padding="10px"
              gap="10px"
              width="100%"
              border={`1px solid ${theme.palette.custom.primaryBorder}`}
              borderRadius="6px"
              height="100%"
              bgcolor={theme.palette.custom.primaryBackground}
            >
              <GraphLoader
                title="Progress"
                variant="circular"
                height="190px"
                width="190px"
                legend={true}
                legendHeight="68px"
                legendWidth="120px"
              />
            </Box>
          </Box>
          <Box width="40%">
            <QuickLinksLoader />
          </Box>
        </Box>
        <ListViewLoader
          columnsVisibility={
            type === "policy" ? policyListColumnsVisibility : documentationListColumnsVisibility
          }
          tableSize="small"
        />
      </Box>
    </Box>
  );
};
