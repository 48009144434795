import { Box, Typography } from "@mui/material";
import { NavbarIcon } from "../../../atoms/navbar/Icon";
import { DisconnectButton, EditButton } from "./Buttons";

type OpenAIIntegrationProps = {
  secret_key?: string;
};

type EditOpenAIPluginProps = {
  pluginData: OpenAIIntegrationProps;
  onEdit: () => void;
  onDisconnect: () => void;
};

const EditOpenAIPlugin = (props: EditOpenAIPluginProps) => {
  const { pluginData, onEdit, onDisconnect } = props;
  return (
    <Box sx={{ display: "flex", justifyContent: "space-between" }}>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <NavbarIcon
          variant="lock"
          sx={{
            height: "30px",
          }}
        />
        <Typography variant="h2">{pluginData.secret_key ?? "Configured"}</Typography>
      </Box>
      <Box>
        <EditButton onEdit={onEdit} />
        <DisconnectButton onDisconnect={onDisconnect} />
      </Box>
    </Box>
  );
};

export { EditOpenAIPlugin };
