import { Box, useTheme } from "@mui/material";
import { ComplianceElement } from "../../models/types";
import { IColumnVisibility } from "../../molecules/ColumnVisibility";
import { CircularLoader } from "../../molecules/skeleton-loader/CircularLoader";
import { RectangularLoader } from "../../molecules/skeleton-loader/RectangularLoader";
import { ScreenSmallerThen } from "../../utilities/UIHelper";
import { TableLoader } from "./TableLoader";

export const FrameworksDetailLoader = () => {
  const columnsVisibility: IColumnVisibility<ComplianceElement>[] = [
    {
      field: "external_id",
      headerName: "Element",
      visible: true,
    },
    {
      field: "name",
      headerName: "Name",
      visible: true,
    },
    {
      field: "created_by",
      headerName: "Owner",
      visible: true,
    },
    {
      field: "mapped_to",
      headerName: "Progress",
      visible: true,
    },
  ];

  const theme = useTheme();
  const isSmallScreen = ScreenSmallerThen("1100px");

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        justifyContent: "space-between",
        alignItems: "flex-start",
        padding: "0 0 15px 0",
      }}
    >
      <Box
        paddingBottom={"0px"}
        borderRadius="6px"
        border="1px solid"
        width="100%"
        minHeight="100%"
        maxHeight="100%"
        // overflow="auto"
        display="flex"
        flexDirection="column"
        borderColor={theme.palette.custom.primaryBorder}
        bgcolor={theme.palette.custom.primaryBackground}
      >
        <Box
          display="flex"
          justifyContent="space-between"
          padding="10px"
          gap="10px"
          flexWrap="wrap"
        >
          <RectangularLoader
            maxWidth={isSmallScreen ? "100%" : "280px"}
            width="100%"
            height="35px"
          />
        </Box>
        <Box sx={{ width: "100%", display: "flex", height: "800px" }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              paddingX: "20px",
              width: "100%",
              maxWidth: "266px",
              justifyContent: "flex-start",
              overflowY: "auto",
              overflowX: "hidden",
              paddingY: "15px",
              gap: "15px",
              borderRight: "1px solid",
              borderTop: "1px solid",
              borderTopColor: theme.palette.custom.secondaryBorder,
              borderRightColor: theme.palette.custom.secondaryBorder,
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "flex-start",
                gap: "10px",
              }}
            >
              <CircularLoader width="60px" height="60px" />
              <RectangularLoader width="122px" height="60px" />
            </Box>
            <RectangularLoader width="100%" height="48px" />
            <RectangularLoader width="100%" height="48px" />
            <RectangularLoader width="100%" height="17px" />
            <Box sx={{ display: "flex", flexDirection: "column", gap: "5px" }}>
              {[...Array(5)].map((_, idx) => (
                <RectangularLoader key={idx} width="100%" height="62px" />
              ))}
            </Box>
          </Box>
          <Box width="100%" sx={{ msOverflowStyle: "auto" }}>
            <TableLoader columnsVisibility={columnsVisibility} rows={15} />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
