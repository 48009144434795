import { Stack, Typography } from "@mui/material";
import { SmallModal } from "../../components/SmallModal";
import { YesOrNoSelectField } from "../custom-fields/BooleanSelect";
import { useState } from "react";
import { LoadingButton } from "@mui/lab";

type ShowModelOrApplicationCodeModalProps = {
  open: boolean;
  onClose: () => void;
  modelCode: string;
  setModelCode: (value: string) => void;
  onNext: () => void;
};

export const ShowModelOrApplicationCodeModal = (props: ShowModelOrApplicationCodeModalProps) => {
  const { open, onClose, modelCode, setModelCode, onNext } = props;

  return (
    <SmallModal open={open} onClose={onClose} title="Model or Application Code">
      <Stack gap="10px">
        <Typography variant="h3">Is this a model or application code?</Typography>
        <YesOrNoSelectField
          value={modelCode}
          onChange={setModelCode}
          required
          yesLabel="Model Code"
          noLabel="Application Code"
        />
        <LoadingButton variant="contained" onClick={onNext} disabled={modelCode === ""}>
          Next
        </LoadingButton>
      </Stack>
    </SmallModal>
  );
};
