import { Box, Menu, MenuItem, Typography, useTheme } from "@mui/material";
import { NavbarIcon } from "../atoms/navbar/Icon";
import { defaultDateDisplay } from "../utilities/UIHelper";
import UserTag from "../components/UserTag";
import { LoadingButton } from "@mui/lab";
import { ReactComponent as ThreeDotsLightIcon } from "../assets/general/three-dots-light.svg";
import { ReactComponent as ThreeDotsDarkIcon } from "../assets/general/three-dots-dark.svg";
import { useState } from "react";
import { AssetStatusOptions } from "../models/types";
import { useAuth } from "../hooks/useAuth";

type DocumentationFileProps = {
  document: any;
  status: AssetStatusOptions;
  onDelete?: (document: any) => void;
  onEdit?: (document: any) => void;
  onSeeDetails?: (document: any) => void;
  onView?: (document: any) => void;
};

export const DocumentationFile = (props: DocumentationFileProps) => {
  const { document, status, onDelete, onEdit, onSeeDetails, onView } = props;
  const { user } = useAuth();
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === "dark";
  const [isMenuVisible, setMenuVisible] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const toggleMenu = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    setMenuVisible(!isMenuVisible);
  };

  const handleClose = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(null);
    setMenuVisible(!isMenuVisible);
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      gap="8px"
      padding="10px"
      border={`1px solid ${theme.palette.custom.secondaryBorder}`}
      borderRadius="3px"
      // flexGrow="0 0"
      flexBasis="calc(25% - 8px)"
      justifyContent="space-between"
      onClick={() => !isMenuVisible && onView && onView(document)}
      sx={{
        cursor: "pointer",
      }}
    >
      <Box display="flex" flexDirection="column" gap="8px">
        <Box
          height="115px"
          padding="16px 0px"
          gap="8px"
          display="flex"
          sx={{
            backgroundColor: theme.palette.custom.secondaryBackground,
          }}
          alignItems="center"
          justifyContent="center"
        >
          <NavbarIcon
            variant={
              document.type === "hyperlink"
                ? "hyperlink"
                : document.type === "file"
                ? "documentation"
                : document.type === "fairo-data"
                ? "fairo-logo"
                : document.plugin_type
            }
            sx={{
              width: "30px",
              height: "30px",
            }}
            color={
              isDarkMode
                ? theme.palette.custom.whiteTypography
                : theme.palette.custom.blueTypography
            }
          />
        </Box>
        <Box display="flex" gap="8px" justifyContent="space-between" alignItems="center">
          <Typography variant="h3" color={theme.palette.custom.secondaryTypography}>
            {document.name}
          </Typography>
          <Box
            width="16px"
            height="100%"
            alignItems="center"
            alignContent="center"
            onClick={toggleMenu}
          >
            <Box
              sx={{
                display: "flex",
                cursor: "pointer",
                transform: "rotate(90deg)",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {isDarkMode ? <ThreeDotsDarkIcon /> : <ThreeDotsLightIcon />}
            </Box>
          </Box>
          <Menu
            elevation={0}
            id="three-dots-menu"
            MenuListProps={{
              "aria-labelledby": "three-dots-button",
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            sx={{
              gap: "5px",
              "& .MuiPaper-root": {
                "& .MuiMenu-list": {
                  padding: "4px",
                  width: "146px",
                },
                "& .MuiMenuItem-root": {
                  padding: "4px",
                  "&:hover": {
                    backgroundColor: theme.palette.custom.secondaryBackground,
                  },
                },
              },
            }}
          >
            {status === "DRAFT" && document?.updated_by?.id === user?.id ? (
              <>
                <MenuItem>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    width="100%"
                    alignItems="center"
                    gap="5px"
                    onClick={(event) => {
                      event.stopPropagation();
                      onEdit && onEdit(document);
                    }}
                  >
                    <Typography variant="body2">Edit Details</Typography>
                    <NavbarIcon
                      variant="edit-icon"
                      color={
                        !isDarkMode
                          ? theme.palette.custom.blueTypography
                          : theme.palette.custom.whiteTypography
                      }
                      sx={{ height: "16px", width: "16px" }}
                    />
                  </Box>
                </MenuItem>
                <MenuItem>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    width="100%"
                    alignItems="center"
                    gap="5px"
                    onClick={(event) => {
                      event.stopPropagation();
                      onDelete && onDelete(document);
                    }}
                  >
                    <Typography variant="body2">Delete</Typography>
                    <NavbarIcon
                      variant="trash-can"
                      color={theme.palette.custom.redTypography}
                      sx={{ height: "18px", width: "18px" }}
                    />
                  </Box>
                </MenuItem>
              </>
            ) : (
              <MenuItem>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  width="100%"
                  alignItems="center"
                  gap="5px"
                  onClick={(event) => {
                    event.stopPropagation();
                    onSeeDetails && onSeeDetails(document);
                  }}
                >
                  <Typography variant="body2">See Details</Typography>
                </Box>
              </MenuItem>
            )}
          </Menu>
        </Box>
      </Box>
      <Box display="flex" flexDirection="column" gap="8px">
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="body2">Recurrence Start:</Typography>
          <Typography variant="body2">{defaultDateDisplay(document.recurrence_start)}</Typography>
        </Box>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="body2">By:</Typography>
          <UserTag name={document.updated_by.name} />
        </Box>
      </Box>
    </Box>
  );
};
