import { Box, LinearProgress } from "@mui/material";

type QuestionnaireProgressBarProps = {
  questionIndex: number;
  questionnaireLength: number;
  rounded?: boolean;
};

export const QuestionnaireProgressBar = ({
  questionIndex,
  questionnaireLength,
  rounded = true,
}: QuestionnaireProgressBarProps) => {
  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      paddingRight={rounded ? "10px" : 0}
      width="100%"
    >
      <LinearProgress
        sx={{
          height: rounded ? "8px" : "5px",
          width: "100%",
          ...(rounded && { borderRadius: "2px" }),
        }}
        variant="determinate"
        value={(questionIndex / questionnaireLength) * 100}
      />
    </Box>
  );
};
