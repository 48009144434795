import {
  GetJiraIssue,
  GithubRepository,
  JiraIssue,
  JiraProject,
  JiraUser,
  PluginData,
  S3Bucket,
  S3Object,
} from "../models/types";
import queryClient, { QueryKey } from "../state/QueryStore";
import { JiraIssueType } from "./../models/types";
import { httpGetAuthenticated, httpPostAuthenticated } from "./ApiService";

const pluginSettingsEndpoints = {
  githubInfoUrl: () => "/integration/github/get_info",
  githubDisconnectUrl: () => "/integration/github/disconnect",
  createGithubIntegrationUrl: () => "/integration/github/auth_callback",
  githubAuthUrl: () => "/integration/github/get_authorisation_url",
  searchGithubIssuesUrl: () => "/integration/github/search_issues",
  updateGithubWebhookConfigUrl: () => "/integration/github/update_webhook_config",
  searchGithubRepositoriesUrl: () => "/integration/github/search_repositories",

  jiraInfoUrl: () => "/integration/jira/get_info",
  updateJiraIntegrationUrl: () => "/integration/jira/update_token_params",
  searchJiraIssuesUrl: () => "/integration/jira/search_issues",
  jiraDisconnectUrl: () => "/integration/jira/disconnect",
  jiraAuthUrl: () => "/integration/jira/get_authorisation_url",
  createJiraOauthIntegrationUrl: () => "/integration/jira/auth_callback",
  updateJiraWebhookConfigUrl: () => "/integration/jira/update_webhook_config",
  searchJiraProjectsUrl: () => "/integration/jira/search_projects",
  searchJiraUsersUrl: () => "/integration/jira/search_users",
  searchJiraIssueTypesUrl: () => "/integration/jira/search_issue_types",
  jiraCreateIssueUrl: () => "/integration/jira/create_issue",

  awsInfoUrl: () => "/integration/aws/get_info",
  awsGetBucketsUrl: () => "/integration/aws/get_s3_buckets_list",
  awsGetBucketObjectsUrl: () => "/integration/aws/get_s3_objects_list",
  updateAwsIntegrationUrl: () => "/integration/aws/update_token_params",
  awsDisconnectUrl: () => "/integration/aws/disconnect",

  allPluginsMetaDataUrl: () => "/integrations/get_metadata",
  infoUrl: (pluginName: string) => `/integration/${pluginName}/get_info`,
  authUrl: (pluginName: string) => `/integration/${pluginName}/get_authorisation_url`,
  connectOAuth: (pluginName: string) => `/integration/${pluginName}/auth_callback`,
  updateIntegrationUrl: (pluginName: string) => `/integration/${pluginName}/update_token_params`,
  disconnect: (pluginName: string) => `/integration/${pluginName}/disconnect`,
  basePlugin: (pluginName: string) => `/integration/${pluginName}/setup`,
  updateWebhookConfigUrl: (pluginName: string) =>
    `/integration/${pluginName}/update_webhook_config`,
};

export const getGithubAuthenticationUrl = () => {
  return httpGetAuthenticated(pluginSettingsEndpoints.githubAuthUrl());
};

export const createGithubAppIntegration = (code: string, params: any) => {
  return httpGetAuthenticated(pluginSettingsEndpoints.createGithubIntegrationUrl(), {
    params: {
      app_integration_type: "github",
      custom_fields: params,
    },
  });
};

export const editPluginStatus = async (plugin: string, status: string) => {
  const { data } = await httpPostAuthenticated(pluginSettingsEndpoints.basePlugin(plugin), {
    status: status,
  });
  queryClient.invalidateQueries(QueryKey.Plugins);
  return data;
};

export const searchGithubIssues = (search_text: string) => {
  return httpGetAuthenticated(pluginSettingsEndpoints.searchGithubIssuesUrl(), {
    params: {
      query: search_text,
    },
  });
};

export const getGithubRepositories = (search_text?: string) => {
  return httpGetAuthenticated<GithubRepository[]>(
    pluginSettingsEndpoints.searchGithubRepositoriesUrl(),
    {
      params: {
        query: search_text,
      },
    }
  );
};

export const updateGithubWebhookConfig = (githubWebhookConfig: {
  webhooks?: boolean;
  completeTasksWhenIssueCloses?: boolean;
  linkIssueToTasks?: boolean;
  comments?: boolean;
  issues?: boolean;
  pullRequests?: boolean;
}) => {
  const {
    webhooks,
    completeTasksWhenIssueCloses,
    linkIssueToTasks,
    comments,
    issues,
    pullRequests,
  } = githubWebhookConfig;
  return httpPostAuthenticated(pluginSettingsEndpoints.updateGithubWebhookConfigUrl(), {
    webhooks: webhooks,
    complete_tasks_when_issue_closes: completeTasksWhenIssueCloses,
    link_issue_to_tasks: linkIssueToTasks,
    comments: comments,
    issues: issues,
    pull_requests: pullRequests,
  });
};

export const getGithubAppIntegrationInfo = () => {
  return httpGetAuthenticated(pluginSettingsEndpoints.githubInfoUrl());
};

export const deleteGithubIntegration = () => {
  return httpGetAuthenticated(pluginSettingsEndpoints.githubDisconnectUrl());
};

export const getJiraAppIntegrationInfo = () => {
  return httpGetAuthenticated(pluginSettingsEndpoints.jiraInfoUrl());
};

export const updateJiraAppIntegrationParams = (data: {
  site: string;
  username: string;
  token: string;
}) => {
  if (!(data.site.startsWith("http://") || data.site.startsWith("https://"))) {
    // Add missing URL schema
    data.site = `https://${data.site}`;
  }
  return httpPostAuthenticated(pluginSettingsEndpoints.updateJiraIntegrationUrl(), data);
};

export const searchJiraIssuesApi = (search_text: string) => {
  return httpGetAuthenticated<GetJiraIssue[]>(pluginSettingsEndpoints.searchJiraIssuesUrl(), {
    params: {
      search: search_text,
    },
  });
};

export const searchJiraProjects = () => {
  return httpGetAuthenticated<JiraProject[]>(pluginSettingsEndpoints.searchJiraProjectsUrl());
};

export const searchJiraUsers = () => {
  return httpGetAuthenticated<JiraUser[]>(pluginSettingsEndpoints.searchJiraUsersUrl());
};

export const searchJiraIssueTypes = (projectId: string) => {
  return httpGetAuthenticated<JiraIssueType[]>(pluginSettingsEndpoints.searchJiraIssueTypesUrl(), {
    params: {
      project_id: projectId,
    },
  });
};

export const createJiraIssue = (data: JiraIssue) => {
  return httpPostAuthenticated(pluginSettingsEndpoints.jiraCreateIssueUrl(), data);
};

export const updateJiraWebhookConfig = (jiraWebhookConfig: {
  completeTasksWhenIssueCloses?: boolean;
  linkIssueToTasks?: boolean;
}) => {
  const { completeTasksWhenIssueCloses, linkIssueToTasks } = jiraWebhookConfig;
  return httpPostAuthenticated(pluginSettingsEndpoints.updateJiraWebhookConfigUrl(), {
    complete_tasks_when_issue_closes: completeTasksWhenIssueCloses,
    link_issue_to_tasks: linkIssueToTasks,
  });
};

export const deleteJiraIntegration = () => {
  return httpGetAuthenticated(pluginSettingsEndpoints.jiraDisconnectUrl());
};

export const getJiraAuthenticationUrl = () => {
  return httpGetAuthenticated(pluginSettingsEndpoints.jiraAuthUrl());
};

export const getAllPluginsMetaData = () => {
  return httpGetAuthenticated<PluginData[]>(pluginSettingsEndpoints.allPluginsMetaDataUrl());
};

export const createJiraAppOauthIntegration = (code: string, params: any) => {
  return httpGetAuthenticated(pluginSettingsEndpoints.createJiraOauthIntegrationUrl(), {
    params: {
      app_integration_type: "jira",
      custom_fields: params,
    },
  });
};

export const getAwsAppIntegrationInfo = () => {
  return httpGetAuthenticated(pluginSettingsEndpoints.awsInfoUrl());
};

export const updateAwsAppIntegrationParams = (data: {
  accessKeyId: string;
  secretAccessKey: string;
}) => {
  return httpPostAuthenticated(pluginSettingsEndpoints.updateAwsIntegrationUrl(), data);
};

export const deleteAwsIntegration = () => {
  return httpGetAuthenticated(pluginSettingsEndpoints.awsDisconnectUrl());
};

export const getS3BucketslistApi = () => {
  return httpGetAuthenticated<S3Bucket[]>(pluginSettingsEndpoints.awsGetBucketsUrl());
};

export const getS3ObjectslistApi = (params: { bucket: string; search: string }) => {
  return httpGetAuthenticated<S3Object[]>(pluginSettingsEndpoints.awsGetBucketObjectsUrl(), {
    params,
  });
};

export const getPluginInfo = async (pluginName: string) => {
  const { data } = await httpGetAuthenticated(pluginSettingsEndpoints.infoUrl(pluginName));
  return data;
};

export const getPluginAuthUrl = async (pluginName: string) => {
  const { data } = await httpGetAuthenticated(pluginSettingsEndpoints.authUrl(pluginName));
  return data;
};

export const createOAuthIntegration = async (pluginName: string) => {
  const { data } = await httpGetAuthenticated(pluginSettingsEndpoints.connectOAuth(pluginName));
  queryClient.invalidateQueries({ queryKey: [QueryKey.Plugins] });
  return data;
};

export const updateIntegrationParams = async (pluginName: string, payload: any) => {
  if (payload.site) {
    if (!(payload.site.startsWith("http://") || payload.site.startsWith("https://"))) {
      // Add missing URL schema
      payload.site = `https://${payload.site}`;
    }
  }
  const { data } = await httpPostAuthenticated(
    pluginSettingsEndpoints.updateIntegrationUrl(pluginName),
    payload
  );
  queryClient.invalidateQueries({ queryKey: [QueryKey.Plugins] });
  return data;
};

export const disconnectIntegration = async (pluginName: string) => {
  const { data } = await httpGetAuthenticated(pluginSettingsEndpoints.disconnect(pluginName));
  queryClient.invalidateQueries({ queryKey: [QueryKey.Plugins] });
  return data;
};

export const updateWebhookConfig = (
  pluginName: string,
  webhookConfig: {
    webhooks?: boolean;
    completeTasksWhenIssueCloses?: boolean;
    linkIssueToTasks?: boolean;
    comments?: boolean;
    issues?: boolean;
    pullRequests?: boolean;
  }
) => {
  const {
    webhooks,
    completeTasksWhenIssueCloses,
    linkIssueToTasks,
    comments,
    issues,
    pullRequests,
  } = webhookConfig;
  return httpPostAuthenticated(pluginSettingsEndpoints.updateWebhookConfigUrl(pluginName), {
    webhooks: webhooks,
    complete_tasks_when_issue_closes: completeTasksWhenIssueCloses,
    link_issue_to_tasks: linkIssueToTasks,
    comments: comments,
    issues: issues,
    pull_requests: pullRequests,
  });
};
