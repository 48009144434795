import { useQuery } from "react-query";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { fetchMetrics } from "../hooks/useMetrics";
import { fetchTestCases } from "../hooks/useTestCases";
import { fetchTestRuns } from "../hooks/useTestRuns";
import { Metric, TestCase, TestRun } from "../models/types";
import { getFinalNumberFromPath, getLastPathByRegex } from "../utilities/UIHelper";

const TEST_CASE_NAVIGATOR_KEY = "test-case-navigator";
const TEST_RUN_NAVIGATOR_KEY = "test-run-navigator";
const METRIC_NAVIGATOR_KEY = "metric-navigator";

export const useTestCasePathState = (locationState: any, path: string) => {
  const testCaseId = locationState?.testCaseId;
  const edit = locationState?.edit;
  const lastPath = getLastPathByRegex(path);
  const testNumber = getFinalNumberFromPath(lastPath);
  const navigate = useNavigate();
  return useQuery<{
    testCaseId: string;
    edit: boolean;
  }>(
    [TEST_CASE_NAVIGATOR_KEY, path],
    async () => {
      if (testCaseId != null) {
        return { testCaseId, edit };
      }

      const testCases = await fetchTestCases({ test_case_num: [testNumber] });
      const testCase = testCases.results.find((t) => t.test_case_num === Number(testNumber));
      if (!testCase) {
        navigate("/tests/test-cases");
        return { testCaseId: null, edit };
      }

      return { testCaseId: testCase.id, edit };
    },
    { suspense: true }
  );
};

export const useTestRunPathState = (locationState: any, path: string) => {
  const testRunId = locationState?.testRunId;
  const edit = locationState?.edit;
  const lastPath = getLastPathByRegex(path);
  const number = getFinalNumberFromPath(lastPath);
  const navigate = useNavigate();
  return useQuery<{
    testRunId: string;
    edit: boolean;
  }>(
    [TEST_RUN_NAVIGATOR_KEY, path],
    async () => {
      if (testRunId != null) {
        return { testRunId, edit };
      }

      const testRuns = await fetchTestRuns({ test_run_num: [number] });
      const testRun = testRuns.results.find((t) => t.test_run_num === Number(number));
      if (!testRun) {
        navigate("/tests/test-runs");
        return { testRunId: null, edit };
      }

      return { testRunId: testRun.id, edit };
    },
    { suspense: true }
  );
};

export const useMetricPathState = (locationState: any, path: string) => {
  const metricId = locationState?.metricId;
  const edit = locationState?.edit;
  const lastPath = getLastPathByRegex(path);
  const number = getFinalNumberFromPath(lastPath);
  const navigate = useNavigate();
  return useQuery<{
    metricId: string;
    edit: boolean;
  }>(
    [METRIC_NAVIGATOR_KEY, path],
    async () => {
      if (metricId != null) {
        return { metricId, edit };
      }

      const metrics = await fetchMetrics({ metric_num: [number] });
      const metric = metrics.results.find((t) => t.metric_num === Number(number));
      if (!metric) {
        navigate("/tests/metrics");
        return { metricId: null, edit };
      }

      return { metricId: metric.id, edit };
    },
    { suspense: true }
  );
};

const navigateToTestCase = (params: {
  navigate: NavigateFunction;
  testCaseId: string;
  testNumber: number;
  edit?: boolean;
  returnLink?: boolean;
}) => {
  const { testCaseId, testNumber, navigate, edit, returnLink = false } = params;
  if (returnLink) {
    return `/tests/test-cases/test-${testNumber}`;
  }
  navigate(`/tests/test-cases/test-${testNumber}`, {
    state: {
      testCaseId,
      edit,
    },
  });
};

const navigateToMetric = (params: {
  navigate: NavigateFunction;
  metricId: string;
  metricNumber: number;
  edit?: boolean;
  returnLink?: boolean;
}) => {
  const { metricId, metricNumber, navigate, edit, returnLink = false } = params;
  if (returnLink) {
    return `/tests/metrics/metric-${metricNumber}`;
  }
  navigate(`/tests/metrics/metric-${metricNumber}`, {
    state: {
      metricId,
      edit,
    },
  });
};

const navigateToTestRun = (params: {
  navigate: NavigateFunction;
  testRunId: string;
  testRunNumber: number;
  edit?: boolean;
  returnLink?: boolean;
}) => {
  const { testRunId, testRunNumber, navigate, edit, returnLink = false } = params;
  if (returnLink) {
    return `/tests/test-runs/test-run-${testRunNumber}`;
  }
  navigate(`/tests/test-runs/test-run-${testRunNumber}`, {
    state: {
      testRunId,
      edit,
    },
  });
};

const navigateToTestRunInNewTab = (test: TestRun) => {
  window.open(`/tests/test-runs/test-run-${test.test_run_num}`, "_blank");
};
const navigateToMetricInNewTab = (metric: Metric) => {
  window.open(`/tests/metrics/metric-${metric.metric_num}`, "_blank");
};
const navigateToTestCaseInNewTab = (testCase: TestCase) => {
  window.open(`/tests/test-cases/test-case-${testCase.test_case_num}`, "_blank");
};

export {
  navigateToMetric,
  navigateToMetricInNewTab,
  navigateToTestCase,
  navigateToTestRun,
  navigateToTestRunInNewTab,
  navigateToTestCaseInNewTab,
};
