import { Box, LinearProgress, Typography, TypographyProps, useTheme } from "@mui/material";
import { isNaN } from "lodash";
import React from "react";
import { ScreenSmallerThen, breakDescriptionIn250Chars } from "../utilities/UIHelper";
import { TableTextWithEllipsis } from "./TableTextWithEllipsis";

type OverviewCardProps = {
  /** Icon component to display on left side of the card */
  icon: React.ReactNode;
  /** Item name */
  name: string;
  /** Description in body2 under the name */
  description?: string;
  /** Progress bar component, can be other components to display on the side of the name */
  progress?: React.ReactNode;
  /** Component to display under name */
  secondComponent?: React.ReactNode;
  /** On name click */
  onClick?: () => void;
  /** Small = 3 cards/row, Medium = 2 cards/row */
  size?: "small" | "medium";
  /** Custom height for the card */
  height?: string;
  /** Variant to apply on the Name */
  titleVariant?: TypographyProps["variant"];
  /** Custom right component to display */
  rightComponent?: React.ReactNode;
  /** Screen width where card will take 100% of the width */
  smallScreenBasis?: string;
  /** Custom component to display with space-between aligned with the name */
  nameRightComponent?: React.ReactNode;
};

export const OverviewCard = ({
  icon,
  name,
  description,
  progress,
  secondComponent,
  onClick,
  size = "medium",
  height,
  titleVariant,
  rightComponent,
  nameRightComponent,
  smallScreenBasis = "850px",
}: OverviewCardProps) => {
  const theme = useTheme();
  const isSmallScreen = ScreenSmallerThen("850px");
  const getCardWidth = (size: "small" | "medium") => {
    if (size === "small") {
      return "25%";
    }
    if (size === "medium") {
      return "40%";
    }
    return "40%";
  };
  return (
    <Box
      gap="10px"
      display="flex"
      justifyContent="center"
      alignItems="center"
      height={isSmallScreen ? "unset" : height ? height : "119px"}
      flex={`1 1 ${getCardWidth(size)}`}
      borderRadius="3px"
      flexDirection="row"
      maxWidth={isSmallScreen ? "100%" : "unset"}
      overflow="hidden"
      bgcolor={theme.palette.custom.primaryBackground}
      border={`1px solid ${theme.palette.custom.primaryBorder}`}
      sx={{
        [`@media(max-width: ${smallScreenBasis})`]: {
          flexBasis: "100%",
        },
      }}
    >
      <Box
        width="100%"
        padding="10px"
        gap="10px"
        display="flex"
        flexDirection={"row"}
        alignItems="center"
      >
        {icon}
        <Box display="flex" flexDirection="column" flexGrow={1} gap="5px">
          <Box
            display="flex"
            maxWidth="100%"
            flexDirection={isSmallScreen || !description ? "column" : "row"}
            flexWrap="wrap"
            gap="5px"
            alignItems={isSmallScreen || !description ? "flex-start" : "center"}
            justifyContent="space-between"
          >
            <Box
              display="flex"
              flexDirection="row"
              gap="5px"
              justifyContent="space-between"
              alignItems="center"
              maxWidth={isSmallScreen || nameRightComponent ? "100%" : "50%"}
              width={isSmallScreen || nameRightComponent ? "100%" : "unset"}
            >
              <TableTextWithEllipsis
                variant={titleVariant}
                fullWidth={false}
                value={name}
                onClick={onClick ? () => onClick() : undefined}
              />
              {nameRightComponent}
            </Box>
            {progress}
          </Box>
          <Typography variant="body2">{breakDescriptionIn250Chars(description, 170)}</Typography>
          {secondComponent}
        </Box>
        {rightComponent}
      </Box>
    </Box>
  );
};

export const CustomProgressBar = ({
  completed,
  total,
  fullWidth,
  showCount = false,
  width,
}: {
  completed: number;
  total: number;
  fullWidth?: boolean;
  showCount?: boolean;
  width?: string;
}) => {
  const theme = useTheme();
  const progress = Number(((completed / total) * 100).toFixed(0));
  const isSmallScreen = ScreenSmallerThen("850px");
  return (
    <Box
      width={fullWidth ? "100%" : isSmallScreen ? "100%" : width ? width : "unset"}
      display="flex"
      flexDirection="row"
      gap="8px"
      alignItems={"center"}
      justifyContent={"flex-start"}
    >
      <LinearProgress
        sx={{
          width: fullWidth ? "100%" : isSmallScreen ? "120px" : width ? width : "90px",
          height: "10px",
          borderRadius: "2px",
          color: theme.palette.custom.secondaryBorder,
        }}
        variant="determinate"
        value={isNaN(progress) ? 0 : progress}
        color="success"
        classes={{
          determinate: theme.palette.custom.secondaryBorder,
        }}
      />
      {showCount ? (
        <Typography variant="h4">
          {completed}/{total}
        </Typography>
      ) : (
        <Typography variant="body2">{isNaN(progress) ? "0" : progress}%</Typography>
      )}
    </Box>
  );
};
