import { Box, useTheme } from "@mui/material";
import { NavbarIcon } from "../atoms/navbar/Icon";
import { RegistryFieldStyleRule } from "../models/types";
import { TableTextWithEllipsis } from "../molecules/TableTextWithEllipsis";
import { ColorBox } from "../organisms/modals/tag/CreateTagModal";

export const DisplayRule = (props: {
  rule: RegistryFieldStyleRule;
  onEdit: () => void;
  onRemove: () => void;
  disabled?: boolean;
}) => {
  const { rule, onEdit, onRemove, disabled = false } = props;
  const theme = useTheme();
  return (
    <Box
      display="flex"
      flexDirection="row"
      gap="5px"
      width="100%"
      alignItems="center"
      justifyContent="space-between"
    >
      <Box display="flex" flexDirection="row" maxWidth="80%" gap="5px" alignItems="center">
        <ColorBox size="16px" selected={true} color={rule.color} onClick={() => {}} />
        <TableTextWithEllipsis value={`Value ${rule.operator} ${rule.value}`} />
      </Box>
      {!disabled && (
        <Box display="flex" flexDirection="row" gap="5px" alignItems="center">
          <Box onClick={() => onEdit()} sx={{ cursor: "pointer" }}>
            <NavbarIcon
              variant="edit-icon"
              selected={true}
              sx={{ width: "15px", height: "15px", minHeight: "15px", minWidth: "15px" }}
            />
          </Box>
          <Box onClick={() => onRemove()} sx={{ cursor: "pointer" }}>
            <NavbarIcon
              variant="trash-can"
              color={theme.palette.custom.redTypography}
              sx={{ width: "15px", height: "15px", minHeight: "15px", minWidth: "15px" }}
            />
          </Box>
        </Box>
      )}
    </Box>
  );
};
