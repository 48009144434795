import { Box, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { IconButton } from "../../atoms/IconButton";
import FairoLogo from "../../atoms/navbar/Logo";
import config from "../../config/config";
import { useOrganizationContext } from "../../context/OrganizationContext";
import { useSubscription } from "../../context/SubscriptionContext";
import { useAppThemeContext } from "../../context/ThemeContext";
import { useAuth } from "../../hooks/useAuth";
import { useNotifications } from "../../hooks/useNotification";
import { usePluginInfo } from "../../hooks/usePlugins";
import Navigation from "../../pages/navigation/Navigation";
import { getPlanHierarchy } from "../../utilities/PlanHierarchy";
import { FeaturePaywallModal } from "../modals/FeaturePaywallModal";
import { HeaderConditionalComponent } from "./HeaderConditionalComponent";
import { OrganizationThreeDotsMenu } from "./OrganizationThreeDotsMenu";

export const AuthenticatedHeader = () => {
  const [mlflowPaywallOpen, setMlflowPaywallOpen] = useState(false);
  const { currentPlan } = useSubscription();
  const { user, isAuditor } = useAuth();
  const { pathname } = useLocation();
  const isAdmin = user?.permissions === "Admin";
  const theme = useTheme();
  const { toggleTheme } = useAppThemeContext();
  const { setNotificationsOpen } = useOrganizationContext();
  const navigate = useNavigate();
  const { data: notifications } = useNotifications({
    "read[]": "False",
    page_size: 1,
  });
  const isEnterprise = currentPlan?.name === "Fairo Enterprise";
  const { data: mlflowData } = usePluginInfo("mlflow");
  const { data: databricksData } = usePluginInfo("databricks");
  const isDatabricksTrackingServer = mlflowData?.tracking_server === "Databricks";
  const isCustomTrackingServer = mlflowData?.tracking_server === "Custom";
  const [hideFirst, setHideFirst] = useState<boolean>(true);
  useEffect(() => {
    setHideFirst(true);
    if (
      pathname.includes("organization") ||
      pathname.includes("audit-log") ||
      pathname.includes("version-history") ||
      pathname.includes("workflow")
    ) {
      setHideFirst(false);
    }
    if (pathname.includes("audits")) {
      setHideFirst(false);
      if (pathname.includes("reports")) {
        setHideFirst(true);
      }
    }
  }, [pathname]);
  return (
    <Box
      bgcolor={theme.palette.custom.primaryBackground}
      width="100%"
      alignItems="center"
      gap="20px"
      borderBottom={`2.5px solid ${theme.palette.custom.secondaryBorder}`}
      display="flex"
      flexDirection="row"
      padding="10px 20px"
    >
      <FairoLogo labelVisible={false} width={"27px"} />
      <Box display="flex" width="100%" justifyContent="space-between" alignItems="center">
        <Navigation
          rightComponent={<></>}
          showOnlyFirst={false}
          showFirstLast={false}
          hideFirst={hideFirst}
        />
        <Box gap="5px" display="flex" flexDirection="row" alignItems="center">
          <HeaderConditionalComponent />
          {!isAuditor() && (
            <IconButton
              variant={isDatabricksTrackingServer ? "databricks" : "mlflow"}
              height={isDatabricksTrackingServer ? "23px" : "28px"}
              padding="0px"
              width={isDatabricksTrackingServer ? "23px" : "28px"}
              onClick={
                getPlanHierarchy(currentPlan?.name ?? "") >= getPlanHierarchy("Fairo Enterprise")
                  ? () => {
                      if (isDatabricksTrackingServer) {
                        window.open(databricksData?.workspace_url, "_blank");
                      } else if (isCustomTrackingServer) {
                        window.open(mlflowData?.server_url, "_blank");
                      } else {
                        window.open(`${config.mlflow.baseUrl}/mlflow-static`, "_blank");
                      }
                    }
                  : () => {
                      setMlflowPaywallOpen(true);
                    }
              }
            />
          )}
          <IconButton
            variant="theme"
            height="28px"
            width="28px"
            padding="0px"
            color={theme.palette.custom.primaryTypography}
            strokeWidth="1.5"
            onClick={toggleTheme}
          />
          <IconButton
            color={theme.palette.custom.primaryTypography}
            strokeWidth="1.5"
            variant={
              notifications?.results && notifications.results.length > 0
                ? "notification_alert"
                : "notification"
            }
            height="28px"
            padding="0px"
            width="28px"
            onClick={() => setNotificationsOpen(true)}
          />
          {isAdmin && (
            <IconButton
              selected={pathname.includes("/organization/settings")}
              variant="settings"
              strokeWidth="1.5"
              color={theme.palette.custom.primaryTypography}
              height="26px"
              padding="0px"
              width="26px"
              onClick={() => navigate("/organization/settings")}
            />
          )}
          <OrganizationThreeDotsMenu />
        </Box>
      </Box>
      <FeaturePaywallModal
        open={mlflowPaywallOpen}
        onClose={() => {
          setMlflowPaywallOpen(false);
        }}
        route="mlflow"
      />
    </Box>
  );
};
