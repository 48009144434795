import { Box, Typography } from "@mui/material";
import { NavbarIcon } from "../../../atoms/navbar/Icon";
import { DisconnectButton, EditButton } from "./Buttons";

type EditAWSPluginProps = {
  onDisconnect: () => void;
  onEdit: () => void;
  pluginData: any;
};

const EditAWSPlugin = (props: EditAWSPluginProps) => {
  const { onDisconnect, onEdit, pluginData } = props;
  return (
    <Box sx={{ display: "flex", justifyContent: "space-between" }}>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <NavbarIcon
          variant="lock"
          sx={{
            height: "30px",
          }}
        />
        <Typography variant="h2">
          {`${pluginData.secret_access_key}`}&nbsp;
          {`(S3 Buckets: ${pluginData?.buckets})`}
        </Typography>
      </Box>
      <Box>
        <EditButton onEdit={onEdit} />
        <DisconnectButton onDisconnect={onDisconnect} />
      </Box>
    </Box>
  );
};
export { EditAWSPlugin };
