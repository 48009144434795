import { Box, IconButton, Typography, useTheme } from "@mui/material";
import { useState } from "react";
import { NavbarIcon } from "../atoms/navbar/Icon";

type Props = {
  user: any;
};

export const OrgChartCard = ({ user }: Props) => {
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === "dark";
  const [showDetails, setShowDetails] = useState(false);

  return (
    <Box
      sx={{
        width: "224px",
        display: "flex",
        flexDirection: "column",
        textAlign: "left",
        gap: "8px",
        borderRadius: "6px",
        border: "1px solid",
        borderColor: theme.palette.custom.secondaryBorder,
        background: theme.palette.custom.primaryBackground,
        paddingBottom: "8px",
      }}
    >
      <Box
        sx={{
          backgroundColor: user.department_color,
          display: "flex",
          justifyContent: "center",
          paddingY: "9px",
          borderTopLeftRadius: "6px",
          borderTopRightRadius: "6px",
        }}
      >
        <Typography variant="h4" fontSize="12px" color={theme.palette.custom.white}>
          {user.job_title}
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          gap: "12px",
          paddingX: "14px",
        }}
      >
        <NavbarIcon
          variant="user"
          sx={{
            width: "40px",
            height: "40px",
          }}
          backgroundColor={theme.palette.custom.primaryBackground}
          color={"#FFFFFF"}
        />
        <Box
          sx={{
            display: "flex",
            gap: "10px",
            justifyContent: "space-between",
            flexGrow: 1,
            justifyItems: "center",
            alignItems: "center",
          }}
        >
          <Typography variant="h4">{user.name}</Typography>
          <IconButton
            onClick={(event) => {
              event.stopPropagation();
              setShowDetails(!showDetails);
            }}
            sx={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <NavbarIcon variant={showDetails ? "chevron-up-sm" : "chevron-down-sm"} />
          </IconButton>
        </Box>
      </Box>
      {showDetails && (
        <Box
          sx={{
            paddingX: "11px",
          }}
        >
          <Box
            sx={{
              paddingTop: "10px",
              display: "flex",
              gap: "15px",
              borderTop: "1px solid",
              borderColor: theme.palette.custom.secondaryBorder,
              justifyContent: "space-between",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "5px",
              }}
            >
              <Typography variant="body1" fontSize="12px">
                {`Department: ${user.department}`}
              </Typography>
              <Typography variant="body1" fontSize="12px">
                {`Reporting to: ${user.manager_name}`}
              </Typography>
            </Box>
            <NavbarIcon
              variant="edit-icon"
              sx={{
                width: "9px",
                height: "9px",
              }}
              color={isDarkMode ? theme.palette.custom.white : theme.palette.custom.blue[400]}
            />
          </Box>
        </Box>
      )}
    </Box>
  );
};
