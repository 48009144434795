import { Box, Button, Stack, Typography, useTheme } from "@mui/material";
import { useState } from "react";
import { SmallModal } from "../../../components/SmallModal";
import { NavbarIcon } from "../../../atoms/navbar/Icon";
import { SearchableDropdown } from "../../../components/SearchableDropdown";
import {
  GithubRepository,
  HuggingFaceDataset,
  HuggingFaceModel,
  PluginData,
  S3Object,
} from "../../../models/types";
import { useNavigate } from "react-router-dom";
import { usePlugins } from "../../../hooks/usePlugins";
import { LoadingButton } from "@mui/lab";
import { SelectedPluginType } from "../../plugins/SelectedPluginType";
import { openSnackbar } from "../../../context/SnackbarContext";
import { addResource } from "../../../services/ResourceService";
import config from "../../../config/config";
import { useOrganizationContext } from "../../../context/OrganizationContext";

type AddResourceModalProps = {
  /** Should the modal be open? */
  open: boolean;
  /** Callback to close the modal */
  onClose: () => void;
  /** Async callback to create the resource */
  onCreate: (plugin: string, resourceId: string, resourceType: string) => Promise<void>;
  /** Set Plugin */
  setSelectedPlugin?: (plugin: PluginData | null) => void;
};

export const AddResourceModal = (props: AddResourceModalProps) => {
  const { open, onClose, onCreate, setSelectedPlugin: _setSelectedPlugin } = props;

  const [loading, setLoading] = useState<boolean>(false);

  const theme = useTheme();
  const innerTextColor = theme.palette.text.secondary;

  const { data, refetch } = usePlugins({});
  const { setSelectedPlugin } = useOrganizationContext();
  const [plugin, setPlugin] = useState<PluginData | null>(null);
  const [selectedResource, setSelectedResource] = useState<
    S3Object | GithubRepository | HuggingFaceDataset | HuggingFaceModel | null
  >(null);
  const navigate = useNavigate();

  const handleSetPlugin = (plugin: PluginData | null) => {
    if (plugin) {
      if (plugin.status !== "Active") {
        if (_setSelectedPlugin) {
          _setSelectedPlugin(plugin);
          return;
        }
        setSelectedPlugin(plugin);
        return;
      } else {
        setPlugin(plugin);
      }
    } else {
      setPlugin(null);
    }
  };

  const handleSave = async () => {
    try {
      setLoading(true);
      let resourceId = "";
      let resourceType = "";
      if (plugin?.plugin_type === "aws") {
        resourceId = (selectedResource as S3Object)?.external_id;
        resourceType = "S3_FILE";
      }
      if (plugin?.plugin_type === "github") {
        resourceId = (selectedResource as GithubRepository)?.full_name;
        resourceType = "REPOSITORY";
      }
      if (plugin?.plugin_type === "hugging_face") {
        resourceId = (selectedResource as HuggingFaceDataset | HuggingFaceModel)?.id;
        if ((selectedResource as HuggingFaceModel).modelId) {
          resourceType = "MODEL";
        } else {
          resourceType = "DATASET";
        }
      }
      await onCreate(plugin?.plugin_type ?? "", resourceId ?? "", resourceType ?? "");
      onClose();
    } catch (error) {
      openSnackbar("Failed to add External Resources", "error");
    } finally {
      setLoading(false);
    }
  };

  const options = async (search: string) => {
    try {
      const result = await refetch();
      const data = result.data;
      return data
        ? data?.filter((option) => {
            return (
              // @todo remove this while adding new plugins with proper content
              option.plugin_type === "aws" ||
              option.plugin_type === "hugging_face" ||
              (option.plugin_type === "github" &&
                (option.plugin_display_name.toLowerCase().includes(search.toLowerCase()) ||
                  option.plugin_description.toLowerCase().includes(search.toLowerCase())))
            );
          }) || []
        : [];
    } catch (error) {
      openSnackbar("Failed to fetch plugins", "error");
      return [];
    }
  };

  return (
    <SmallModal open={open} onClose={onClose} title="Add Resource">
      <Stack gap="10px">
        <Typography variant="h3" color={innerTextColor}>
          Plugin
        </Typography>
        <SearchableDropdown<PluginData>
          label="Select Plugin"
          required
          error={false}
          value={plugin || null}
          onChange={handleSetPlugin}
          getOptions={(search: string) => options(search)}
          isOptionEqualToValue={(a, b) => a === b}
          renderOption={(props, option) => {
            return (
              <Box
                component="li"
                {...props}
                key={option.id}
                sx={{ cursor: "pointer" }}
                alignItems="center"
                padding="5px"
                display="flex"
                flexDirection="row"
                gap="5px"
                width="100%"
              >
                <Box
                  display="flex"
                  flex={1}
                  flexDirection="row"
                  gap="5px"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Box display="flex" flexDirection="row" gap="5px" alignItems="center" flex={1}>
                    <NavbarIcon
                      variant={option.plugin_logo_url}
                      sx={{ width: "20px", height: "20px", minHeight: "20px", minWidth: "20px" }}
                    />
                    <Typography variant="h4">
                      {option.plugin_display_name}
                      {option.status !== "Active" ? ` (${option.status})` : ""}
                    </Typography>
                  </Box>
                  {option.status !== "Active" && (
                    <Button sx={{ height: "30px" }}>Set Up Now</Button>
                  )}
                </Box>
              </Box>
            );
          }}
          getOptionLabel={(option) =>
            `${option.plugin_display_name}${
              option.status !== "Active" ? ` (${option.status})` : ""
            }`
          }
        />
        {plugin && (
          <SelectedPluginType
            type={plugin.plugin_type}
            setSelectedResource={setSelectedResource}
            selectedResource={selectedResource}
          />
        )}
        <LoadingButton variant="contained" fullWidth loading={loading} onClick={handleSave}>
          Add
        </LoadingButton>
      </Stack>
    </SmallModal>
  );
};
