import { Box, Typography, useTheme } from "@mui/material";
import { RectangularLoader } from "../../molecules/skeleton-loader/RectangularLoader";
import {
  BorderLoader,
  ImpactAssessmentSectionsLoader,
} from "../../organisms/skeleton-loaders/ImpactAssessmentDetailLoader";
import { APITableLoader } from "../../organisms/skeleton-loaders/APITableLoader";
import { TasksColumnsVisibility } from "../../organisms/tables/tasks/TasksColumnsVisibility";

export const UseCaseDetailLoader = () => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        justifyContent: "center",
        alignItems: "flex-start",
        padding: "0 0 15px 0",
      }}
    >
      <Box
        borderRadius="6px"
        border="1px solid"
        width="100%"
        minHeight="100%"
        maxHeight="100%"
        overflow="auto"
        display="flex"
        flexDirection="column"
        borderColor={theme.palette.custom.primaryBorder}
        bgcolor={theme.palette.custom.primaryBackground}
      >
        <Box sx={{ width: "100%", display: "flex", height: "800px" }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              height: "100%",
              maxWidth: "286px",
              justifyContent: "flex-start",
              overflowY: "auto",
              overflowX: "hidden",
              gap: "15px",
              padding: "15px",
              borderRight: "1px solid",
              borderRightColor: theme.palette.custom.secondaryBorder,
            }}
          >
            <RectangularLoader width="100%" height="22px" />
            <RectangularLoader width="100%" height="33px" />
            <RectangularLoader width="100%" height="20px" />
            <ImpactAssessmentSectionsLoader />
          </Box>
          <Box flexGrow={1} display="flex" flexDirection="column" gap="20px" padding="10px">
            <Box display="flex" flexDirection="column" gap="10px">
              <RectangularLoader width="100%" height="44px" />
              <RectangularLoader width="100%" height="44px" />
            </Box>
            <Box display="flex" flexDirection="column" width="100%" gap="15px">
              <BorderLoader width="100%" height="100%" />
            </Box>
            <Box
              sx={{
                maxWidth: "100%",
                width: "100%",
                height: "100%",
                overflow: "visible",
              }}
            >
              <Box height={"100%"}>
                <Box
                  paddingBottom={"0px"}
                  borderRadius="6px"
                  border="1px solid"
                  minHeight="100%"
                  maxHeight="100%"
                  overflow="auto"
                  borderColor={theme.palette.custom.primaryBorder}
                  bgcolor={theme.palette.custom.primaryBackground}
                >
                  <APITableLoader
                    columnsVisibility={TasksColumnsVisibility({
                      onEdit: (taskId: string) => {},
                      showCompleted: false,
                    })}
                    tableSize="small"
                    secondComponent={true}
                    tableSearch={true}
                  />
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
