import { Box, Typography } from "@mui/material";
import { IconButton } from "../../../atoms/IconButton";
import { INavbarIconVariant, NavbarIcon } from "../../../atoms/navbar/Icon";
import { DefaultBadge, StatusBadge } from "../../../atoms/StatusBadge";
import { Resource } from "../../../models/types";
import { IColumnVisibility } from "../../../molecules/ColumnVisibility";
import { TableTextWithEllipsis } from "../../../molecules/TableTextWithEllipsis";
import { getResourceSystemName } from "../../../utilities/UIHelper";

export const externalResourceColumnsVisibility = ({
  editing,
  onUnlink,
}: {
  editing?: boolean;
  onUnlink?: (resource: Resource) => void;
}): IColumnVisibility<Resource>[] => [
  {
    field: "resource_num",
    headerName: "Internal ID",
    visible: true,
    columnMinWidth: 150,
    columnMaxWidth: 150,
    renderer: (resource) => (
      <TableTextWithEllipsis fontWeight={600} value={`Resource-${resource.resource_num}`} />
    ),
  },
  {
    field: "external_id",
    headerName: "External ID",
    visible: true,
    columnMinWidth: 300,
    renderer: (resource) => <TableTextWithEllipsis value={resource.external_id} />,
  },
  {
    field: "system",
    headerName: "System",
    visible: true,
    columnMaxWidth: 150,
    columnMinWidth: 100,
    renderer: (resource) => (
      <Box display="flex" alignItems="center" gap="4px">
        <NavbarIcon
          variant={resource.system.replace(/_/g, "-") as INavbarIconVariant}
          sx={{ height: "20px", width: "20px" }}
        />
        <Typography variant="body2" textTransform="capitalize">
          {getResourceSystemName(resource.system)}
        </Typography>
      </Box>
    ),
  },
  {
    field: "mapped_resource",
    headerName: "Fairo Mapped Internal Resource",
    visible: true,
    columnMaxWidth: 350,
    columnMinWidth: 350,
    renderer: (resource) => {
      const hasMappedResource =
        !!resource?.mapped_resource_type && !!resource?.mapped_resource_name;
      if (!hasMappedResource) return null;
      return (
        <DefaultBadge
          value={`${resource.mapped_resource_type.split(".")[1]}-${resource?.mapped_resource_name}`}
        />
      );
    },
  },
  {
    field: "status",
    headerName: "Status",
    visible: true,
    columnMinWidth: 160,
    columnMaxWidth: 180,
    renderer: (resource) => <StatusBadge status={resource.status as any} />,
  },
  ...(onUnlink && editing
    ? ([
        {
          field: "updated_by",
          headerName: "",
          visible: true,
          columnMaxWidth: 60,
          columnMinWidth: 60,
          renderer: (asset) => (
            <IconButton
              variant="unlink"
              onClick={(e) => {
                e.stopPropagation();
                onUnlink(asset);
              }}
              width="30px"
              height="30px"
            />
          ),
        },
      ] as IColumnVisibility<Resource>[])
    : []),
];
