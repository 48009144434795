import { LoadingButton } from "@mui/lab";
import { Box, Typography, Switch, CircularProgress } from "@mui/material";
import { useState } from "react";
import { updateWebhookConfig } from "../../../services/PluginService";
import { PluginData } from "../../../models/types";
import { openSnackbar } from "../../../context/SnackbarContext";

type WebhooksOverviewProps = {
  plugin: PluginData;
  pluginData: any;
  setOpenWebhookConfigModal?: (open: boolean) => void;
  setOpenMessageHistoryModal?: (open: boolean) => void;
};

export const WebhooksOverview = (props: WebhooksOverviewProps) => {
  const { plugin, pluginData, setOpenWebhookConfigModal, setOpenMessageHistoryModal } = props;
  const [loading, setLoading] = useState<boolean>(false);
  const [webhooksOn, setWebhooksOn] = useState<boolean>(pluginData.webhooks ?? false);

  const updateWebhok = async (checked: boolean) => {
    setLoading(true);
    try {
      await updateWebhookConfig(
        plugin.plugin_type,
        checked
          ? {
              webhooks: checked,
            }
          : {
              webhooks: checked,
              issues: checked,
              pullRequests: checked,
              comments: checked,
              linkIssueToTasks: checked,
              completeTasksWhenIssueCloses: checked,
            }
      );
      setWebhooksOn(checked);
    } catch (e) {
      openSnackbar("Failed to update webhook config", "error");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          gap: "10px",
        }}
      >
        <Typography variant="h2">Webhooks</Typography>
        {!loading && (
          <Box display="flex" gap="8px" alignItems="center">
            <Typography variant="h4">Off</Typography>
            <Switch
              size="medium"
              checked={webhooksOn}
              onChange={(_, checked) => {
                updateWebhok(checked);
              }}
              edge="end"
            />
            <Typography variant="h4">On</Typography>
          </Box>
        )}
        {loading && <CircularProgress size={22} />}
      </Box>
      {webhooksOn && (
        <>
          <LoadingButton
            variant="contained"
            onClick={() => setOpenWebhookConfigModal && setOpenWebhookConfigModal(true)}
          >
            Webhook Configure
          </LoadingButton>
          <LoadingButton
            onClick={() => setOpenMessageHistoryModal && setOpenMessageHistoryModal(true)}
          >
            View Message History
          </LoadingButton>
        </>
      )}
    </>
  );
};
