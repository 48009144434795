import { Stack, Typography } from "@mui/material";
import { SmallModal } from "../../components/SmallModal";
import { LoadingButton } from "@mui/lab";
import { YesOrNoSelectField } from "../custom-fields/BooleanSelect";

type CreateModelYesOrNoModalProps = {
  open: boolean;
  onClose: () => void;
  createModel: string;
  setCreateModel: (value: string) => void;
  onNext: () => void;
};

export const CreateModelYesOrNoModal = (props: CreateModelYesOrNoModalProps) => {
  const { open, onClose, createModel, setCreateModel, onNext } = props;

  return (
    <SmallModal open={open} onClose={onClose} title="Create Model">
      <Stack gap="10px">
        <Typography variant="h3">
          We didn't see a model associated with it. Would you like to create one and associate it
          with the use case?
        </Typography>
        <YesOrNoSelectField
          value={createModel}
          onChange={setCreateModel}
          required
          yesLabel="Yes"
          noLabel="No"
        />
        <LoadingButton variant="contained" onClick={onNext} disabled={createModel === ""}>
          Next
        </LoadingButton>
      </Stack>
    </SmallModal>
  );
};
