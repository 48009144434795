import { Box, Checkbox, IconButton, Typography, useTheme } from "@mui/material";
import { QuestionChoice } from "../../models/types";
import { NavbarIcon } from "../../atoms/navbar/Icon";

type MultipleSelectionFieldProps = {
  value: string;
  onChange: (newValue: string) => void;
  required?: boolean;
  choices: QuestionChoice[];
};

const MultipleSelectionOption = (props: {
  onClick: () => void;
  label: string;
  selected: boolean;
}) => {
  const theme = useTheme();
  return (
    <Box
      border={`1px solid ${theme.palette.custom.primaryBorder}`}
      borderRadius="3px"
      onClick={() => props.onClick()}
      sx={{
        cursor: "pointer",
        "&:hover": {
          backgroundColor: theme.palette.custom.secondaryBackground,
        },
        backgroundColor: props.selected
          ? theme.palette.custom.secondaryBackground
          : theme.palette.custom.primaryBackground,
      }}
      display="flex"
      flexDirection="row"
      alignItems="center"
    >
      <Checkbox
        value={props.label}
        size="small"
        checked={props.selected}
        onClick={() => props.onClick()}
        sx={{
          padding: "0px",
          height: "34px",
          width: "34px",
        }}
      />
      <Typography
        fontWeight={props.selected ? 600 : 400}
        variant="h4"
        color={theme.palette.custom.secondaryTypography}
      >
        <div
          dangerouslySetInnerHTML={{
            __html: props.label,
          }}
        />
      </Typography>
    </Box>
  );
};

export const MultipleSelectionField = ({
  value,
  onChange,
  required,
  choices,
}: MultipleSelectionFieldProps) => {
  const valueArray: string[] = JSON.parse(value);
  return (
    <Box display="flex" flexDirection="column" width="100%" gap="10px">
      {choices.map((choice) => (
        <MultipleSelectionOption
          onClick={() => {
            const newValue = valueArray.includes(choice.value)
              ? valueArray.filter((v) => v !== choice.value)
              : [...valueArray, choice.value];
            onChange(JSON.stringify(newValue));
          }}
          label={choice.label}
          selected={valueArray.includes(choice.value)}
        />
      ))}
    </Box>
  );
};

export const MultipleSelectionReadOnly = ({
  value,
  choices,
}: {
  value: string;
  choices: QuestionChoice[];
}) => {
  const valueArray = JSON.parse(value);
  console.log(valueArray);
  return (
    <Box display="flex" flexDirection="column" gap="5px">
      {choices.map((choice) => {
        if (valueArray.includes(choice.value)) {
          return (
            <Typography variant="body2">
              <div
                dangerouslySetInnerHTML={{
                  __html: choice?.label,
                }}
              />
            </Typography>
          );
        }
        return <></>;
      })}
    </Box>
  );
};
