import { LoadingButton } from "@mui/lab";
import { Box, TextField, useTheme } from "@mui/material";
import { PluginData } from "../../models/types";
import { PluginGuideHandler } from "../PluginGuideHandler";

type PluginTokenAuthFormProps = {
  auth: any;
  plugin: PluginData;
  pluginParams: any;
  pluginData: any;
  setPluginParams: (e: any) => void;
  updatePlugin: (params: any) => Promise<void>;
  onCancel: () => void;
};

const PluginTokenAuthForm = (props: PluginTokenAuthFormProps) => {
  const { auth, plugin, pluginData, pluginParams, setPluginParams, updatePlugin, onCancel } = props;
  const theme = useTheme();
  const changePluginParams = (e: any) => {
    setPluginParams({
      ...pluginParams,
      [e.target.name]: e.target.value,
    });
  };
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "15px",
      }}
    >
      {auth.fields.map((field: any) => (
        <TextField
          fullWidth={true}
          id="outlined-basic"
          label={field.placeholder_text}
          name={field.key.substring(plugin.plugin_type.length + 1)}
          type={field.type}
          variant="outlined"
          onChange={(e) => changePluginParams(e)}
          sx={{ borderRadius: "5px" }}
          required
        />
      ))}
      <LoadingButton variant="contained" onClick={() => updatePlugin(pluginParams)}>
        {pluginData ? "Save Changes" : "Validate"}
      </LoadingButton>
      {pluginData && onCancel && (
        <LoadingButton
          sx={{
            color: theme.palette.custom.redTypography,
          }}
          onClick={() => onCancel()}
        >
          Cancel
        </LoadingButton>
      )}
      {!pluginData && <PluginGuideHandler pluginType={plugin.plugin_type} />}
    </Box>
  );
};

export { PluginTokenAuthForm };
