import { Box, Divider, useTheme } from "@mui/material";
import { RectangularLoader } from "../../molecules/skeleton-loader/RectangularLoader";
import { TestRun } from "../../models/types";
import { IColumnVisibility } from "../../molecules/ColumnVisibility";
import { ScreenSmallerThen } from "../../utilities/UIHelper";
import { GraphLoader } from "../../organisms/skeleton-loaders/GraphLoader";
import { APITableLoader } from "../../organisms/skeleton-loaders/APITableLoader";

export const TestPageLoader = () => {
  const theme = useTheme();

  const testRunColumnsVisibility: IColumnVisibility<TestRun>[] = [
    {
      field: "test_run_num",
      headerName: "Test Run",
      visible: true,
      columnMaxWidth: 130,
      columnMinWidth: 130,
    },
    {
      field: "name",
      headerName: "Name",
      visible: true,
      columnMinWidth: 250,
    },
    {
      field: "status",
      headerName: "Status",
      visible: true,
      columnMaxWidth: 160,
      columnMinWidth: 160,
    },
    {
      field: "test_results",
      headerName: "Progress",
      visible: true,
      columnMinWidth: 270,
      columnMaxWidth: 270,
    },
  ];

  const fillBlankSpace = true;
  const isSmallScreen = ScreenSmallerThen("1100px");
  const bigFixedHeight = `calc(80vh - 56px)`;
  const bigFixedHeightSize = isSmallScreen ? "100%" : bigFixedHeight;

  return (
    <>
      <Box
        display="flex"
        flexDirection={isSmallScreen ? "column" : "row"}
        sx={{ borderTopRightRadius: "6px", borderTopLeftRadius: "6px" }}
        flexWrap="wrap"
        paddingBottom="120px"
        gap={isSmallScreen ? "20px" : "0px"}
      >
        <Box
          display="flex"
          flexDirection="column"
          bgcolor={theme.palette.custom.primaryBackground}
          border={"1px solid"}
          borderRight={isSmallScreen ? `1px solid ${theme.palette.custom.primaryBorder}` : 0}
          width={isSmallScreen ? "100%" : "50%"}
          borderColor={theme.palette.custom.primaryBorder}
          height={`calc(80vh - 56px)`}
          sx={
            isSmallScreen
              ? { borderRadius: "6px" }
              : { borderBottomLeftRadius: "6px", borderTopLeftRadius: "6px" }
          }
        >
          <GraphLoader
            title="Results Timeline"
            variant="rectangular"
            timeFilter={true}
            dateFilter={true}
            height="190px"
            width="501px"
            legend={true}
            legendHeight="48px"
            legendWidth="88px"
          />
          <Divider />
          <GraphLoader
            title="Overall Results"
            variant="circular"
            height="190px"
            width="190px"
            legend={true}
            legendHeight="68px"
            legendWidth="98px"
          />
        </Box>
        <Box width={isSmallScreen ? "100%" : "50%"} minHeight="100%" overflow="visible">
          <Box
            sx={{
              maxWidth: "100%",
              width: "100%",
              height: fillBlankSpace ? "100%" : "unset",
              overflow: "visible",
            }}
          >
            <Box height={bigFixedHeight ? bigFixedHeightSize : fillBlankSpace ? "100%" : "unset"}>
              <Box
                paddingBottom={"0px"}
                border="1px solid"
                minHeight="100%"
                maxHeight="100%"
                overflow="auto"
                borderColor={theme.palette.custom.primaryBorder}
                bgcolor={theme.palette.custom.primaryBackground}
                sx={{
                  borderTopLeftRadius: isSmallScreen ? "6px" : "0px",
                  borderTopRightRadius: "6px",
                  borderBottomLeftRadius: isSmallScreen ? "6px" : "0px",
                  borderBottomRightRadius: "6px",
                }}
              >
                <APITableLoader
                  columnsVisibility={testRunColumnsVisibility}
                  tableSize={"big"}
                  secondComponent={true}
                  tableSearch={true}
                  filtersVisibility={false}
                  dateFilters={false}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};
