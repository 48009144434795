import { Box, Button, Checkbox, MenuItem, Select, Typography, useTheme } from "@mui/material";
import React from "react";

type StatusFilterProps<T extends string> = {
  statusOptions: T[];
  selectedStatus: T[];
  setSelectedStatus: (status: T[]) => void;
  label: string;
};

export const StatusFilter = <T extends string>({
  statusOptions,
  selectedStatus,
  setSelectedStatus,
  label,
}: StatusFilterProps<T>) => {
  const theme = useTheme();
  const [value, setValue] = React.useState<T>(statusOptions[0]);
  const handleChange = (option: T) => {
    if (selectedStatus.includes(option)) {
      setSelectedStatus(selectedStatus.filter((status) => status !== option));
    } else {
      setSelectedStatus([...selectedStatus, option]);
    }
  };

  const handleChangeAll = () => {
    setSelectedStatus([]);
  };

  const filtersCount = selectedStatus.length > 0 ? `(${selectedStatus.length})` : "(All)";

  return (
    <Select
      sx={{
        height: "35px",
        width: 150,
        borderRadius: "3px",
        borderColor: theme.palette.custom.gray,
        "&.MuiInputBase-root .MuiSelect-select": {
          padding: "5px",
        },
      }}
      renderValue={() => (
        <Box
          display="flex"
          padding="0 0 0 4px"
          flexDirection="row"
          gap="5px"
          alignItems="center"
          width="100%"
          height="30px"
          overflow="hidden"
          textOverflow="ellipsis"
        >
          <Typography variant="body2">{label}</Typography>
          <Typography color={theme.palette.custom.secondaryTypography} variant="h4">
            {filtersCount}
          </Typography>
        </Box>
      )}
      inputProps={{ style: { width: 100 } }}
      MenuProps={{
        MenuListProps: {
          sx: {
            padding: "4px",
            "& .MuiMenuItem-root": {
              padding: "1px 4px",
              gap: "5px",
              "&:hover": {
                backgroundColor: theme.palette.custom.secondaryBackground,
              },
            },
          },
        },
      }}
      value={value}
    >
      {statusOptions?.map((column, i) => (
        <MenuItem
          key={i}
          value={column}
          sx={{
            display: "flex",
            justifyContent: "space-between",
            height: 30,
            ".MuiCheckbox-root": {
              color: `${theme.palette.custom.blueTypography}!important`,
            },
          }}
          onClick={() => handleChange(column)}
        >
          <Typography textTransform="capitalize" variant="body2">
            {column.toLowerCase()}
          </Typography>
          <Checkbox
            value={column}
            size="small"
            checked={selectedStatus.includes(column)}
            onChange={(e) => handleChange(column)}
            sx={{
              padding: "0px",
            }}
          />
        </MenuItem>
      ))}
      <MenuItem
        sx={{
          display: "flex",
          justifyContent: "space-between",
          ".MuiCheckbox-root": {
            color: `${theme.palette.custom.blueTypography}!important`,
          },
          borderTop: `1px solid ${theme.palette.custom.secondaryBorder}`,
          padding: "8px 0px",
          marginTop: "4px",
        }}
        disabled={selectedStatus.length === 0}
        onClick={() => handleChangeAll()}
      >
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          width="100%"
          padding="10px 0px 6px 0px"
        >
          <Button
            size="small"
            disabled={selectedStatus.length === 0}
            sx={{
              height: "25px",
              fontSize: "12px",
              fontWeight: 400,
            }}
            variant="contained"
          >
            Clear Filters
          </Button>
        </Box>
      </MenuItem>
    </Select>
  );
};
