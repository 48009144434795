import { RegistryFieldStyleRule, RegistryFieldType } from "../models/types";

export const evaluateColorLogic = (
  rules: RegistryFieldStyleRule[],
  value: string,
  field_type: RegistryFieldType
) => {
  try {
    const numberFieldTypes: RegistryFieldType[] = [
      "Number",
      "Number with Unit",
      "Monetary Value",
      "Percentage",
    ];
    const isNumberType = numberFieldTypes.some((type) => type === field_type);
    const fieldValue = isNumberType ? Number(value) : value;
    if (!rules) {
      return null;
    }
    let color: string | null = null;
    rules.map((rule) => {
      const ruleValue = isNumberType ? Number(rule.value) : rule.value;
      switch (rule.operator) {
        case ">":
          if (fieldValue > ruleValue) {
            color = rule.color;
          }
          break;
        case "<":
          if (fieldValue < ruleValue) {
            color = rule.color;
          }
          break;
        case "==":
          if (fieldValue === ruleValue) {
            color = rule.color;
          }
          break;
        case ">=":
          if (fieldValue >= ruleValue) {
            color = rule.color;
          }
          break;
        case "<=":
          if (fieldValue <= ruleValue) {
            color = rule.color;
          }
          break;
        case "!=":
          if (fieldValue !== ruleValue) {
            color = rule.color;
          }
          break;
        case "count":
          break;
        case "begins with":
          if (String(fieldValue).startsWith(String(rule.value))) {
            color = rule.color;
          }
          break;
        case "ends with":
          if (String(fieldValue).endsWith(String(rule.value))) {
            color = rule.color;
          }
          break;
        case "contains":
          if (String(fieldValue).includes(String(rule.value))) {
            color = rule.color;
          }
          break;
        case "is null":
          if (!fieldValue) {
            color = rule.color;
          }
          break;
        case "is not null":
          if (fieldValue) {
            color = rule.color;
          }
          break;
        case "does not begin with":
          if (!String(fieldValue).startsWith(String(rule.value))) {
            color = rule.color;
          }
          break;
        case "does not end with":
          if (!String(fieldValue).endsWith(String(rule.value))) {
            color = rule.color;
          }
          break;
        case "does not contain":
          if (!String(fieldValue).includes(String(rule.value))) {
            color = rule.color;
          }
          break;
        default:
          const _: never = rule.operator;
          break;
      }
      return null;
    });
    return color;
  } catch (error) {
    return null;
  }
};
