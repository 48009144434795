import { useQuery } from "react-query";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { fetchResources } from "../hooks/useResources";
import { Resource } from "../models/types";
import { getFinalNumberFromPath, getLastPathByRegex } from "../utilities/UIHelper";

const RESOURCE_NAVIGATOR_KEY = "resource-navigator";
export const useResourcePathState = (locationState: any, path: string) => {
  const resourceId = locationState?.resourceId;
  const edit = locationState?.edit;
  const lastPath = getLastPathByRegex(path);
  const number = getFinalNumberFromPath(lastPath);
  const navigate = useNavigate();
  return useQuery<{
    resourceId: string;
    edit: boolean;
  }>(
    [RESOURCE_NAVIGATOR_KEY, path],
    async () => {
      if (resourceId != null) {
        return { resourceId, edit };
      }

      const list = await fetchResources({ resource_num: [number] });
      const obj = list.results.find((o) => o.resource_num === Number(number));
      if (!obj) {
        navigate("/assets/external-resources");
        return { resourceId: null, edit };
      }

      return { resourceId: obj.id, edit };
    },
    { suspense: true }
  );
};

const navigateToResource = (params: {
  navigate: NavigateFunction;
  resourceId: string;
  resourceNumber: number;
  edit?: boolean;
  returnLink?: boolean;
}) => {
  const { resourceId, resourceNumber, edit, navigate, returnLink = false } = params;
  if (returnLink) {
    return `/assets/external-resources/resource-${resourceNumber}`;
  }
  navigate(`/assets/external-resources/resource-${resourceNumber}`, {
    state: {
      resourceId,
      edit,
    },
  });
};

const navigateToResourceInNewTab = (resource: Resource) => {
  window.open(`/assets/external-resources/resource-${resource.resource_num}`, "_blank");
};

export { navigateToResource, navigateToResourceInNewTab };
