import { Box, Typography } from "@mui/material";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { DefaultBadge } from "../../atoms/StatusBadge";
import { SearchableDropdown } from "../../components/SearchableDropdown";
import { navigateToMetricInNewTab } from "../../components/TestNavigator";
import { useMetric, useMetrics } from "../../hooks/useMetrics";
import { FieldHandlerProps, Metric } from "../../models/types";
import { Awaitable } from "../../utilities/common";
import { navigateToObject } from "../../utilities/GenericNavigateHelper";
import { BadgeOption } from "../BadgeOption";
import { RectangularLoader } from "../skeleton-loader/RectangularLoader";

export const MetricCustomField = (
  props: FieldHandlerProps & {
    search: (searchText: string) => Awaitable<Metric[]>;
    setObjectAttributes?: (obj: any) => void;
  }
) => {
  const { value, search, onChange, error, setObjectAttributes, required } = props;
  const { data: metric } = useMetric(value || "", { fairo_data: true });
  useEffect(() => {
    if (metric && setObjectAttributes) {
      setObjectAttributes(metric ?? {});
    }
  }, [metric]);
  return (
    <SearchableDropdown<Metric>
      label="Metric"
      required={required}
      error={error}
      value={metric || null}
      onChange={(newValue) => (newValue != null ? onChange(newValue.id) : onChange(null))}
      getOptions={search}
      isOptionEqualToValue={(a, b) => a.id === b.id}
      getSummaryDescription={(obj) => [obj.name, obj.description ?? ""]}
    />
  );
};

export const MetricListCustomField = (
  props: FieldHandlerProps & {
    search: (searchText: string) => Awaitable<Metric[]>;
    setObjectAttributes?: (obj: any) => void;
  }
) => {
  const { value, search, onChange, error, setObjectAttributes, required } = props;
  const { data: items } = useMetrics({ id: value });
  useEffect(() => {
    if (items && items.results && items.results.length && setObjectAttributes) {
      setObjectAttributes(items.results[0] ?? {});
    }
  }, [items]);
  return (
    <SearchableDropdown<Metric>
      label="Metric"
      required={required}
      error={error}
      multiple={true}
      value={value.length === 0 ? [] : items?.results ?? []}
      onChange={(newValue) => newValue != null && onChange(newValue.map((item) => item.id))}
      getOptionLabel={(obj) => obj.name}
      getOptions={search}
      isOptionEqualToValue={(a, b) => a.id === b.id}
      getSummaryDescription={(obj) => [obj.name, obj?.description ?? ""]}
    />
  );
};

type MetricListReadOnlyCustomFieldProps = {
  value: string[];
  oldBadge?: boolean;
};

export const MetricListReadOnlyCustomField = ({
  value,
  oldBadge = true,
}: MetricListReadOnlyCustomFieldProps) => {
  const navigate = useNavigate();
  const { data: metrics, isLoading } = useMetrics({ id: value, fairo_data: true });

  return isLoading ? (
    <RectangularLoader height="20px" width="100%" />
  ) : metrics?.count && value.length > 0 ? (
    <Box display="flex" gap="5px" flexWrap="wrap">
      {metrics.results.map((metric, index) => {
        return oldBadge ? (
          <BadgeOption
            key={index}
            name={metric?.name}
            onClick={() =>
              navigateToObject(
                navigate,
                "Metric",
                metric.id,
                () => {},
                () => {}
              )
            }
            maxWidth="325px"
            description={metric?.description}
          />
        ) : (
          <DefaultBadge
            key={index}
            value={metric?.name}
            onClick={() => navigateToMetricInNewTab(metric)}
          />
        );
      })}
    </Box>
  ) : (
    <Typography variant="body2">No metrics</Typography>
  );
};
