import { LoadingButton } from "@mui/lab";
import { Box, IconButton, Stack, TextField, Typography, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import Dropzone from "react-dropzone";
import { NavbarIcon } from "../../../atoms/navbar/Icon";
import CKEditorField from "../../../components/custom-fields/CKEditorField";
import { DocumentationFilesDetailsForm } from "../../../components/DocumentationFilesDetailsForm";
import { FairoPolicyTemplate } from "../../../components/FairoPolicyTemplate";
import { DropZoneIndicator } from "../../../components/FileAttachmentForm";
import { SmallModal } from "../../../components/SmallModal";
import { openSnackbar } from "../../../context/SnackbarContext";
import { useVersion } from "../../../hooks/useVersionHistory";

type UploadFileProps = {
  open: boolean;
  onClose: () => void;
  onUpload: (data: {
    file: File;
    name: string;
    description?: string;
    recurrenceStart?: Date | null;
  }) => void;
  recurrence?: number;
  assetType?: "policy" | "documentation" | "vendor";
  document?: any;
  template_version_id: string | null;
  hideRecurrence?: boolean;
};

export const MAX_FILE_SIZE_MB = 10;

export const UploadFile = (props: UploadFileProps) => {
  const { open, onClose, onUpload, assetType, recurrence, document, template_version_id } = props;
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === "dark";

  const [file, setFile] = useState<File | null>(null);
  const [name, setName] = useState<string>(document?.name ?? "");
  const [description, setDescription] = useState<string>(document?.description ?? "");
  const [recurrenceStart, setRecurrenceStart] = useState<Date | null>(
    document?.recurrence_start ? new Date(document?.recurrence_start) : null
  );

  const { data: version } = useVersion(template_version_id ?? "");

  useEffect(() => {
    if (document) {
      const documentFile = new File([], document?.name, { type: document?.file_type });
      setFile(documentFile);
      setName(document?.name);
      setDescription(document?.description);
      setRecurrenceStart(document?.recurrence_start ? new Date(document?.recurrence_start) : null);
    } else {
      setFile(null);
      setName("");
      setDescription("");
      setRecurrenceStart(null);
    }
  }, [document, open]);

  return (
    <SmallModal
      open={open}
      onClose={onClose}
      size="small"
      sx={{
        ".MuiPaper-root": {
          padding: "0 !important",
        },
      }}
    >
      <Stack gap="10px">
        <Box
          display="flex"
          padding="10px 10px 0px 10px"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography variant="h3">{document ? "Edit File" : "Upload File"}</Typography>
          <IconButton aria-label="close" onClick={onClose} sx={{ padding: 0 }}>
            <NavbarIcon variant="dialog-close" sx={{ width: "auto", height: "auto" }} />
          </IconButton>
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          gap="10px"
          border={`1px solid ${theme.palette.custom.secondaryBorder}`}
          padding="10px"
          borderLeft="0"
          borderRight="0"
        >
          <Dropzone
            noClick
            multiple={false}
            onDrop={async (acceptedFiles, fileRejections) => {
              if (fileRejections.length) {
                const errorMessage = fileRejections[0].errors[0].message;
                if (/File is larger than/.test(errorMessage)) {
                  // error doesn't display the size in human readable format. Show custom message
                  openSnackbar(`File size limit is ${MAX_FILE_SIZE_MB}MB`, "error");
                } else {
                  openSnackbar(errorMessage, "error");
                }
              } else if (acceptedFiles.length) {
                const fileToUpload = acceptedFiles[0];
                setFile(fileToUpload);
                setName(fileToUpload.name);
              }
            }}
            maxSize={MAX_FILE_SIZE_MB * 1024 * 1024}
          >
            {({ getRootProps, getInputProps, isDragAccept, open: openFilePicker }) => (
              <Box {...getRootProps()} position="relative">
                <input {...getInputProps()} />
                {isDragAccept && <DropZoneIndicator />}
                {!file ? (
                  <Stack gap="10px">
                    {assetType === "documentation" && (
                      <Typography variant="h3">Add Documentation</Typography>
                    )}
                    <Box
                      display="flex"
                      flexDirection="column"
                      gap="10px"
                      border={`1px dashed ${theme.palette.custom.grayTypography}`}
                      borderRadius="3px"
                      padding="16px 10px"
                      sx={{
                        backgroundColor: theme.palette.custom.secondaryBackground,
                      }}
                    >
                      <Typography variant="body2" textAlign="center">
                        Select your file or drag and drop it, supported files .pdf, .docx up to
                        10MB.
                      </Typography>
                      <Box display="flex" justifyContent="center">
                        <LoadingButton
                          variant="contained"
                          onClick={openFilePicker}
                          sx={{
                            width: "154px",
                          }}
                        >
                          Upload File
                        </LoadingButton>
                      </Box>
                    </Box>
                    {assetType === "policy" && (
                      <Typography variant="body2" textAlign="center">
                        Or
                      </Typography>
                    )}
                  </Stack>
                ) : (
                  <Box display="flex" flexDirection="column" gap="10px">
                    <Box
                      display="flex"
                      gap="10px"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <Box display="flex" gap="10px" alignItems="center">
                        <NavbarIcon
                          variant="documentation"
                          sx={{
                            width: "16.36px",
                            height: "19.64px",
                          }}
                          color={
                            isDarkMode
                              ? theme.palette.custom.whiteTypography
                              : theme.palette.custom.blueTypography
                          }
                        />
                        <Typography variant="h4">{file.name}</Typography>
                      </Box>
                      <Box onClick={() => setFile(null)} sx={{ cursor: "pointer" }}>
                        <NavbarIcon
                          variant="trash-can"
                          color={theme.palette.custom.redTypography}
                          sx={{
                            width: "14px",
                            height: "16px",
                            minHeight: "16px",
                            minWidth: "14px",
                          }}
                        />
                      </Box>
                    </Box>
                    {(assetType === "policy" || assetType === "vendor") && (
                      <>
                        <Typography variant="h3" color={theme.palette.custom.secondaryTypography}>
                          Name
                        </Typography>
                        <TextField
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                          required
                        />
                        {assetType === "vendor" && (
                          <>
                            <Typography
                              variant="h3"
                              color={theme.palette.custom.secondaryTypography}
                            >
                              Description
                            </Typography>
                            <CKEditorField value={description} onChange={setDescription} />
                          </>
                        )}
                      </>
                    )}
                    {assetType === "documentation" && (
                      <DocumentationFilesDetailsForm
                        name={name}
                        setName={setName}
                        namePlaceholder="File name-1"
                        description={description}
                        setDescription={setDescription}
                        recurrenceStart={recurrenceStart}
                        setRecurrenceStart={setRecurrenceStart}
                        recurrence={recurrence ?? 0}
                        hideRecurrence={props.hideRecurrence}
                      />
                    )}
                  </Box>
                )}
              </Box>
            )}
          </Dropzone>
          {version && assetType === "policy" && (
            <FairoPolicyTemplate
              title="Download Our Template"
              description="Edit it in your favorite tool."
              fileName="Policy Template"
              template={JSON.parse(version?.object).value}
            />
          )}
        </Box>
        <Box padding="0px 10px 10px 10px" width="100%">
          <LoadingButton
            variant="contained"
            fullWidth
            onClick={() =>
              onUpload({
                file: file!,
                name,
                description,
                recurrenceStart,
              })
            }
            disabled={!file}
          >
            {document ? "Save" : "Upload"}
          </LoadingButton>
        </Box>
      </Stack>
    </SmallModal>
  );
};
