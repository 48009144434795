import { Box, IconButton, Typography, useTheme } from "@mui/material";
import { NavbarIcon } from "../../atoms/navbar/Icon";
import { QuestionChoice } from "../../models/types";

type SingleSelectionFieldFieldProps = {
  value: string;
  onChange: (newValue: string) => void;
  required?: boolean;
  choices: QuestionChoice[];
};

const SingleSelectionOption = (props: {
  onClick: () => void;
  label: string;
  selected: boolean;
}) => {
  const theme = useTheme();
  return (
    <Box
      border={`1px solid ${theme.palette.custom.primaryBorder}`}
      borderRadius="3px"
      onClick={() => props.onClick()}
      sx={{
        cursor: "pointer",
        "&:hover": {
          backgroundColor: theme.palette.custom.secondaryBackground,
        },
        backgroundColor: props.selected
          ? theme.palette.custom.secondaryBackground
          : theme.palette.custom.primaryBackground,
      }}
      display="flex"
      flexDirection="row"
      alignItems="center"
    >
      <IconButton>
        <NavbarIcon
          variant={props.selected ? "radio-checked" : "radio-unchecked"}
          sx={{
            height: "18px",
            width: "18px",
            minHeight: "18px",
            minWidth: "18px",
          }}
        />
      </IconButton>
      <Typography
        fontWeight={props.selected ? 600 : 400}
        variant="h4"
        color={theme.palette.custom.secondaryTypography}
      >
        <div
          dangerouslySetInnerHTML={{
            __html: props.label,
          }}
        />
      </Typography>
    </Box>
  );
};

export const SingleSelectionField = ({
  choices,
  onChange,
  value,
  required,
}: SingleSelectionFieldFieldProps) => {
  return (
    <Box display="flex" flexDirection="column" width="100%" gap="10px">
      {choices.map((choice) => (
        <SingleSelectionOption
          onClick={() => onChange(choice.value)}
          label={choice.label}
          selected={value === choice.value}
        />
      ))}
    </Box>
  );
};

export const SingleSelectionReadOnly = ({
  value,
  choices,
}: {
  value: string;
  choices: QuestionChoice[];
}) => {
  const selectedChoice = choices.find((choice) => choice.value === value);
  return selectedChoice ? (
    <Typography variant="body2">
      <div
        dangerouslySetInnerHTML={{
          __html: selectedChoice?.label,
        }}
      />
    </Typography>
  ) : null;
};
