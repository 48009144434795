import { Box, Button, CircularProgress, MenuItem, Select, Switch, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { NavbarIcon } from "../atoms/navbar/Icon";
import { ConfirmationModal } from "../components/ConfirmationModal";
import { StringCustomField } from "../components/CustomFieldForm";
import { openSnackbar } from "../context/SnackbarContext";
import { PluginType } from "../models/types";
import { DisconnectButton, EditButton } from "../molecules/organization/integrations/Buttons";
import { getPluginInfo } from "../services/PluginService";

type MLFlowAutologgingFormProps = {
  pluginData?: {
    autologging: boolean;
    tracking_server: string;
    token: string;
    server_url: string;
    webhook: boolean;
    external_id: string;
    status: "Active" | "Inactive";
  };
  active: boolean;
  onSetup: (params: any) => Promise<void> | void;
  onChangePlugin?: (plugin: PluginType) => void;
  onDisconnect?: () => void;
  onEdit?: () => void;
};

const MLFlowAutologgingForm = (props: MLFlowAutologgingFormProps) => {
  const { active, onSetup, onChangePlugin, pluginData, onEdit, onDisconnect } = props;
  const [trackingServer, setTrackingServer] = useState("Fairo");
  const [loading, setLoading] = useState(true);
  const [autoLogging, setAutoLogging] = useState(false);
  const [databricksSetup, setDatabricksSetup] = useState(false);
  const [customUrl, setCustomUrl] = useState("");
  const [customToken, setCustomToken] = useState("");
  const [editing, setEditing] = useState(false);
  const [openConfirmation, setOpenConfirmation] = useState(false);

  type TrackingServerOptionsType = "Fairo" | "Databricks" | "Custom";

  const loadDatabricks = async () => {
    try {
      await getPluginInfo("databricks");
      setDatabricksSetup(true);
    } catch (error: any) {
      setDatabricksSetup(false);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadDatabricks();
  }, []);

  useEffect(() => {
    if (pluginData) {
      setAutoLogging(pluginData.autologging);
      setTrackingServer(pluginData.tracking_server);
      setCustomUrl(pluginData.server_url);
      setCustomToken(pluginData.token);
      setEditing(false);
    } else {
      setEditing(true);
    }
  }, [pluginData]);

  const isUpdated =
    (pluginData &&
      (pluginData?.autologging !== autoLogging ||
        pluginData?.tracking_server !== trackingServer ||
        pluginData?.server_url !== customUrl ||
        pluginData?.token !== customToken)) ||
    (!pluginData &&
      (autoLogging !== false ||
        trackingServer !== "Fairo" ||
        customUrl !== "" ||
        customToken !== ""));

  const trackingServerOptions: TrackingServerOptionsType[] = ["Fairo", "Databricks", "Custom"];

  const onUpdate = async () => {
    try {
      await onSetup({
        autologging: autoLogging,
        tracking_server: trackingServer,
        server_url: customUrl,
        token: customToken,
      });
      if (!active) {
        openSnackbar("MLflow successfully activated", "success");
        setEditing(false);
      }
    } catch (error: any) {
      openSnackbar("Failed to update MLFlow autologging", "error");
    } finally {
      setLoading(false);
    }
  };

  const getIconVariant = (option: "Fairo" | "Databricks" | "Custom"): React.ReactElement => {
    switch (option) {
      case "Fairo":
        return (
          <NavbarIcon
            variant="fairo-logo"
            sx={{
              minWidth: "20px",
              width: "20px",
              minHeight: "20px",
              height: "20px",
              padding: "2.5px",
            }}
          />
        );
      case "Databricks":
        return (
          <NavbarIcon
            variant="databricks"
            sx={{
              minWidth: "20px",
              width: "20px",
              minHeight: "20px",
              height: "20px",
            }}
          />
        );
      case "Custom":
        return (
          <NavbarIcon
            variant="settings"
            sx={{
              minWidth: "20px",
              width: "20px",
              minHeight: "20px",
              height: "20px",
            }}
          />
        );
    }
  };

  return (
    <Box display="flex" flexDirection="column" gap="3px">
      <ConfirmationModal
        open={openConfirmation}
        onClose={() => {
          setOpenConfirmation(false);
        }}
        title={`Edit MLFlow Plugin`}
        description={`Are you sure you want to edit the MLFlow plugin? Remember to make sure all fields are correct before selecting the validation button.`}
        acceptText={`Yes, edit plugin`}
        onAccept={() => setEditing(true)}
      />
      <Box display="flex" flexDirection="row" alignItems="center" justifyContent="space-between">
        <Typography variant="h3">Tracking Server</Typography>
        <Select
          labelId="status-select-label"
          id="status-select"
          value={trackingServer}
          error={false}
          disabled={!editing}
          onChange={(e: any) => {
            if (e.target.value === "Databricks" && !databricksSetup) {
              onChangePlugin && onChangePlugin("databricks");
            } else {
              setCustomUrl("");
              setCustomToken("");
              setTrackingServer(e.target.value);
            }
          }}
          fullWidth={false}
          sx={{
            minWidth: "220px",
          }}
        >
          {trackingServerOptions.map((status, key) => (
            <MenuItem value={status} key={key}>
              <Box
                display="flex"
                flexDirection="row"
                alignItems="center"
                justifyContent="space-between"
                width="100%"
              >
                <Box display="flex" flexDirection="row" gap="5px" alignItems="center">
                  {getIconVariant(status)}
                  {status}
                </Box>
                {status === "Databricks" && !databricksSetup && !loading && (
                  <Button variant="contained" sx={{ height: "20px", fontSize: "12px" }}>
                    Setup
                  </Button>
                )}
              </Box>
            </MenuItem>
          ))}
        </Select>
      </Box>
      {trackingServer === "Custom" && (
        <Box display="flex" gap="5px" flexDirection="column">
          <Typography variant="h3">Server URL</Typography>
          <StringCustomField
            error={false}
            errorMessage=""
            label="Server URL"
            value={customUrl}
            onChange={(value) => setCustomUrl(value)}
          />
          <Typography variant="h3">Token</Typography>
          <StringCustomField
            error={false}
            errorMessage=""
            label="Token"
            value={customToken}
            onChange={(value) => setCustomToken(value)}
          />
        </Box>
      )}

      <Box display="flex" flexDirection="row" alignItems="center" justifyContent="space-between">
        <Typography variant="h3">AutoLog Metrics</Typography>
        {loading ? (
          <CircularProgress size={15} />
        ) : (
          <Switch
            disabled={!editing}
            size="medium"
            checked={autoLogging}
            onChange={(_, checked) => setAutoLogging(checked)}
            edge="end"
          />
        )}
      </Box>

      {editing && (
        <Box display="flex" justifyContent="flex-end" flexGrow={1}>
          {pluginData && (
            <Button variant="text" onClick={() => setEditing(false)} color="error">
              Cancel
            </Button>
          )}
          <Button fullWidth={false} disabled={!isUpdated} onClick={() => onUpdate()}>
            Save Changes
          </Button>
        </Box>
      )}
      {!editing && onEdit && onDisconnect && (
        <Box display="flex" flexGrow={1} justifyContent="flex-end">
          <EditButton onEdit={() => setOpenConfirmation(true)} />
          <DisconnectButton onDisconnect={onDisconnect} />
        </Box>
      )}
    </Box>
  );
};

export { MLFlowAutologgingForm };
