import { Checkbox, MenuItem, Select, Typography, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { QueryFilter } from "../models/types";
import ShowAll from "./ShowAll";

type FilterProps<T extends { id: string }, F extends string> = {
  /** Hook to fetch the data */
  filterData: IFilterVisibility<T>[];
  /** Type of filter */
  filterType: string;
  /** Name for the filter */
  filterName: string;
  /** Callback to add new categories */
  onUpdateParams: (data: QueryFilter<F>[]) => void;
  /** Callback to deselect all */
  setDeselectAll: (deselectAll: boolean) => void;
  /** Callback to set the filter */
  setFilterData: (data: IFilterVisibility<T>[]) => void;
};

export interface IFilterVisibility<T> {
  id: keyof T & string;
  name: string;
  visible: boolean;
}

export const Filter = <T extends { id: string }, F extends string>(props: FilterProps<T, F>) => {
  const { filterData, filterType, filterName, onUpdateParams, setDeselectAll, setFilterData } =
    props;

  const theme = useTheme();
  const [currentDescription, setCurrentDescription] = useState("All");
  const [isShowAllCategories, setIsShowAllCategories] = useState<boolean>(
    filterData?.every((c) => c.visible)
  );
  const [filters, setFilters] = useState<IFilterVisibility<T>[]>(filterData);

  const handleChange = (name: string, id: string, value: boolean) => {
    const newArray = filters.map((col) => (col.id === id ? { ...col, visible: value } : col));

    const isAllCategories: boolean = newArray?.every((c) => c.visible);
    setIsShowAllCategories(isAllCategories);
    setDeselectAll(false);

    setFilters(newArray);

    const selectedCategories =
      name === "All" && value === true
        ? []
        : newArray
            .filter((c) => c.visible)
            .map((c) => {
              return {
                filterOption: { label: filterType, value: filterType as F },
                value: c.id as F,
              };
            });
    setFilterData(newArray);
    onUpdateParams(selectedCategories);
    setCurrentDescription(getSelectedCategoriesDescription(newArray));
  };

  const description = (
    <Typography
      variant="h4"
      color={
        currentDescription === "No filters selected"
          ? theme.palette.custom.grayTypography
          : theme.palette.custom.secondaryTypography
      }
    >
      &nbsp;{currentDescription}
    </Typography>
  );

  const handleChangeAll = async (value: boolean) => {
    const newArray = await Promise.all(filters.map((c) => ({ ...c, visible: value })));
    setFilters(newArray);

    const isAllCategories: boolean = newArray?.every((c) => c.visible);
    setIsShowAllCategories(isAllCategories);

    setDeselectAll(!isAllCategories);

    onUpdateParams([]);
    setFilterData(newArray);
    setCurrentDescription(getSelectedCategoriesDescription(newArray));
  };

  const isEmptyVisibleCategories: boolean = filters?.every((c) => c.visible === false);

  const getSelectedCategoriesDescription = (newArray: any[]) => {
    if (newArray?.every((c: { visible: any }) => c.visible)) {
      return "(All)";
    }
    const count = newArray.filter((c: { visible: any }) => c.visible).length;
    if (count === 0) return "No filters selected";
    return `(${count})`;
  };

  useEffect(() => {
    setCurrentDescription(getSelectedCategoriesDescription(filters));
  }, []);

  return (
    <Select
      sx={{
        height: "35px",
        width: 200,
        borderRadius: "3px",
        borderColor: (theme as any).palette.custom.gray,
        "&.MuiInputBase-root .MuiSelect-select": {
          padding: "5px",
        },
      }}
      inputProps={{ style: { width: 100 } }}
      MenuProps={{
        MenuListProps: {
          sx: {
            padding: "4px",
            "& .MuiMenuItem-root": {
              padding: "1px 4px",
              gap: "5px",
              "&:hover": {
                backgroundColor: theme.palette.custom.secondaryBackground,
              },
            },
          },
        },
      }}
      value={1}
      renderValue={() => (
        <ShowAll
          justifyContent="flex-start"
          showIcon={false}
          isEmpty={isEmptyVisibleCategories}
          rightComponent={description}
          title={`${filterName}`}
        />
      )}
    >
      <MenuItem
        value={1}
        sx={{
          padding: 0,
          ".MuiCheckbox-root": {
            color: `${theme.palette.custom.blueTypography}!important`,
          },
        }}
      >
        <ShowAll
          isEmpty={false}
          justifyContent="space-between"
          showIcon={false}
          rightComponent={
            <Checkbox
              size="small"
              checked={isShowAllCategories}
              onChange={(e) => handleChangeAll(e.target.checked)}
              sx={{
                padding: "0px",
              }}
            />
          }
        />
      </MenuItem>
      {filters &&
        filters?.map((filter, i) => (
          <MenuItem
            key={i}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              height: 30,
              ".MuiCheckbox-root": {
                color: `${theme.palette.custom.blueTypography}!important`,
              },
            }}
            onClick={() => handleChange(filter.name, filter.id, !filter.visible)}
          >
            <Typography variant="body2">{filter.name}</Typography>
            <Checkbox
              value={filter.id}
              size="small"
              checked={filter.visible}
              onChange={(e) => handleChange(filter.name, filter.id, e.target.checked)}
              sx={{
                padding: "0px",
              }}
            />
          </MenuItem>
        ))}
    </Select>
  );
};
