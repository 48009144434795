import { LoadingButton } from "@mui/lab";
import { Box, Typography, useTheme } from "@mui/material";
import UserTag from "../components/UserTag";
import { useAuth } from "../hooks/useAuth";
import { useExecutionTasks } from "../hooks/useExecutionTasks";
import { APIGetListHook, Asset } from "../models/types";
import { CardTable } from "../organisms/CardTable";
import { DocumentationFile } from "./DocumentationFile";

type DocumentationFilesProps = {
  asset: Asset;
  showAddDocumentation?: boolean;
  setAddDocumentation?: () => void;
  setOpenAssignDocument?: () => void;
  onRequestChanges?: () => void;
  onApproveDocument?: () => void;
  onCancelApproval?: () => void;
  onRejectApproval?: () => void;
  onDelete?: (document: any) => void;
  onEdit?: (document: any) => void;
  onSeeDetails?: (document: any) => void;
  onView?: (document: any) => void;
  showTitle?: boolean;
  rowsPerPage: number;
};

export const DocumentationFiles = (props: DocumentationFilesProps) => {
  const {
    asset,
    showAddDocumentation,
    setAddDocumentation,
    setOpenAssignDocument,
    onRequestChanges,
    onApproveDocument,
    onCancelApproval,
    onRejectApproval,
    onDelete,
    onEdit,
    onSeeDetails,
    onView,
    showTitle = true,
    rowsPerPage,
  } = props;
  const { user } = useAuth();
  const theme = useTheme();

  const useAssetDocumentationFiles: APIGetListHook<any, any> = (params) => {
    const { page = 1, page_size: _pageSize = 8 } = params;
    const pageData = asset?.metadata?.documents?.slice((page - 1) * _pageSize, page * _pageSize);
    return {
      data: {
        count: asset?.metadata?.documents?.length ?? 0,
        next: null,
        previous: null,
        results: pageData,
      },
      isLoading: false,
    };
  };

  const { data: tasks, isLoading } = useExecutionTasks({
    "parent_object_id[]": [asset?.id],
    status: "PENDING",
    task_type: ["Review"],
  });

  const requestChangesAssignee = tasks?.results?.map((task) => task.assignee);

  return (
    <CardTable
      renderItem={(document: any) => (
        <DocumentationFile
          document={document}
          status={asset.status}
          onDelete={onDelete}
          onEdit={onEdit}
          onView={onView}
          onSeeDetails={onSeeDetails}
        />
      )}
      hasBorderRadiusTop={false}
      hasPaddingTop={false}
      hasPaddingBottom={false}
      params={{}}
      useGetData={useAssetDocumentationFiles}
      rowsPerPage={rowsPerPage}
      title={showTitle ? `Documentation (${asset?.metadata?.documents?.length ?? 0})` : undefined}
      titleVariant="h3"
      status={asset?.status}
      rightComponent={
        <Box display="flex">
          {asset?.status === "DRAFT" && (
            <>
              <LoadingButton onClick={() => setOpenAssignDocument && setOpenAssignDocument()}>
                Submit for Approval
              </LoadingButton>
              {!showAddDocumentation && (
                <LoadingButton
                  variant="contained"
                  onClick={() => setAddDocumentation && setAddDocumentation()}
                >
                  Add Documentation
                </LoadingButton>
              )}
            </>
          )}
          {(asset?.status === "NEEDS APPROVAL" || asset?.status === "ACTION REQUIRED") && (
            <>
              {asset?.metadata?.submitted_by?.id === user?.id &&
                asset?.metadata?.approver?.id !== user?.id && (
                  <>
                    {requestChangesAssignee?.find((assigee) => assigee === user?.id) && (
                      <LoadingButton
                        onClick={() => setOpenAssignDocument && setOpenAssignDocument()}
                      >
                        Re-submit for Approval
                      </LoadingButton>
                    )}
                    <LoadingButton
                      variant="contained"
                      onClick={() => onCancelApproval && onCancelApproval()}
                      color="error"
                    >
                      Cancel Approval
                    </LoadingButton>
                  </>
                )}
              {asset?.metadata?.approver?.id === user?.id && (
                <Box display="flex" gap="5px" alignItems="center">
                  <LoadingButton onClick={() => onRequestChanges && onRequestChanges()}>
                    Request Changes
                  </LoadingButton>
                  <LoadingButton
                    variant="contained"
                    onClick={() => onApproveDocument && onApproveDocument()}
                  >
                    Approve
                  </LoadingButton>
                  <LoadingButton
                    variant="contained"
                    onClick={() => onRejectApproval && onRejectApproval()}
                    color="error"
                  >
                    Reject
                  </LoadingButton>
                </Box>
              )}
            </>
          )}
        </Box>
      }
      secondComponent={
        tasks?.count
          ? tasks.results.map((task) => (
              <Box width="100%" display="flex" flexDirection="column" gap="5px">
                <Box width="100%" display="flex" gap="5px" alignItems="center">
                  <Typography variant="h3" color={theme.palette.custom.secondaryTypography}>
                    Changes Requested By
                  </Typography>
                  <UserTag name={task.updated_by.name} />
                </Box>
                <Typography variant="body2">{task.comments}</Typography>
              </Box>
            ))
          : null
      }
      showRowsMenu={false}
      height="100%"
      padding={"0px"}
      showBorder={false}
    />
  );
};
