import { Box, Typography, useTheme } from "@mui/material";
import { INavbarIconVariant, NavbarIcon } from "../../../atoms/navbar/Icon";

type ImpactAssessmentSectionMenuOptionProps = {
  name: string;
  onClick: () => void;
  selected: boolean;
  variant?: INavbarIconVariant;
  strokeWidth?: string;
  iconColor?: string;
};

export const ImpactAssessmentSectionMenuOption = ({
  name,
  onClick,
  selected,
  variant,
  strokeWidth,
  iconColor,
}: ImpactAssessmentSectionMenuOptionProps) => {
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === "dark";
  return (
    <Box
      sx={{
        paddingY: "6px",
        paddingX: "6px",
        "&:hover": {
          backgroundColor: isDarkMode ? "background.paper" : "background.default",
          color: theme.palette.custom.primaryTypography,
          cursor: "pointer",
          borderRadius: "3px",
        },
        backgroundColor: selected ? (isDarkMode ? "background.paper" : "background.default") : "",
        color: selected ? theme.palette.custom.primaryTypography : "",
        borderRadius: selected ? "3px" : "",
      }}
      onClick={() => onClick()}
      display="flex"
      flexDirection="row"
      gap="5px"
      alignItems="center"
    >
      {variant && (
        <NavbarIcon
          variant={variant}
          selected={selected}
          color={iconColor}
          sx={{
            width: "22px",
            height: "22px",
            minHeight: "22px",
            minWidth: "22px",
          }}
          strokeWidth={strokeWidth}
        />
      )}
      <Typography
        variant="h4"
        fontWeight={400}
        sx={{
          "&:hover": {
            cursor: "pointer",
          },
          color: selected ? theme.palette.custom.hyperlink : theme.palette.custom.primaryTypography,
        }}
      >
        {name}
      </Typography>
    </Box>
  );
};
