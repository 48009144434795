import {
  Box,
  Dialog,
  DialogTitle,
  IconButton,
  keyframes,
  SxProps,
  Theme,
  Typography,
  useTheme,
} from "@mui/material";
import { ReactNode } from "react";
import { NavbarIcon } from "../atoms/navbar/Icon";
import { RectangularLoader } from "../molecules/skeleton-loader/RectangularLoader";
import { ModalLoader } from "../organisms/skeleton-loaders/ModalLoader";

type SmallModalProps = {
  /** Whether the modal is open */
  open: boolean;
  /** Callback to close the modal */
  onClose: () => void;
  /** If specified, the modal displays the title along with a close icon */
  title?: string;
  /** Whether the modal is open */
  children: ReactNode;
  /** Bigger modal */
  size?: "small" | "medium" | "large" | "xl";
  rightComponent?: ReactNode;
  customTitle?: ReactNode;
  isLoading?: boolean;
  maxHeight?: string;
  minHeight?: string;
  sx?: SxProps<Theme>;
  showCloseButton?: boolean;
  hideContentPadding?: boolean;
  preventBackdrop?: boolean;
  action?: ReactNode;
  transparentBackdrop?: boolean;
  position?: "right" | "center";
};

const widths = {
  small: "430px",
  medium: "693px",
  large: "1080px",
  xl: "80%",
  undefined: "430px",
};

export const SmallModal = (props: SmallModalProps) => {
  const {
    open,
    onClose,
    children,
    title,
    size = "small",
    rightComponent,
    customTitle,
    isLoading = false,
    maxHeight,
    minHeight,
    sx,
    hideContentPadding = false,
    showCloseButton = true,
    preventBackdrop = false,
    action,
    transparentBackdrop = false,
    position = "center",
  } = props;
  const theme = useTheme();
  const handleClose = (event: any, reason: string) => {
    if (preventBackdrop && reason === "backdropClick") {
      return;
    }
    onClose();
  };

  const slideInFromRight = keyframes`
      from {
        transform: translateX(100%);
      }
      to {
        transform: translateX(0);
      }
    `;

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      hideBackdrop={transparentBackdrop}
      disableScrollLock={transparentBackdrop}
      PaperProps={{
        style: {
          borderRadius: 6,
          width: widths[size],
          margin: 0,
          padding: hideContentPadding ? "0px" : "15px",
          background: theme.palette.custom.primaryBackground,
          border: `1px solid ${theme.palette.custom.tableBorder}`,
          maxWidth: widths[size],
          ...(position === "center" && {
            maxHeight: maxHeight,
            minHeight: minHeight,
          }),
          ...(position === "right" && {
            position: "absolute",
            right: 0,
            top: 0,
            height: "100vh",
            minHeight: "100vh",
            animation: `${slideInFromRight} 0.3s ease-out`, // Add the sliding animation here
            transform: "translateX(0)",
            ...(transparentBackdrop && { pointerEvents: "auto" }),
          }),
        },
      }}
      sx={{
        ...(transparentBackdrop && { pointerEvents: "none" }),
        "& .MuiDialogTitle-root": { p: 0, m: 0 },
        "& .MuiDialogContent-root": { p: 0, m: 0 },
        "& .MuiLoadingButton-root": { height: "35px" },
        "& .MuiTypography-h2": { fontWeight: 600 },
        "& .MuiTypography-h3": { fontWeight: 600 },
        ...sx,
      }}
    >
      {title != null && (
        <DialogTitle
          padding={hideContentPadding ? "15px 15px 0 15px !important" : "0px"}
          marginBottom="10px !important"
        >
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            justifyItems="flex-start"
          >
            <Box display="flex" flexGrow={1} alignItems="center">
              {isLoading ? (
                <RectangularLoader width="100%" maxWidth="300px" height="26px" />
              ) : customTitle ? (
                customTitle
              ) : (
                <Typography variant="h2">{title}</Typography>
              )}
            </Box>
            <Box
              sx={{ lineHeight: 0 }}
              display="flex"
              alignItems="center"
              flexDirection="row"
              gap="5px"
            >
              {rightComponent}
              {showCloseButton && (
                <IconButton aria-label="close" onClick={onClose} sx={{ padding: 0 }}>
                  <NavbarIcon variant="dialog-close" sx={{ width: "auto", height: "auto" }} />
                </IconButton>
              )}
            </Box>
          </Box>
        </DialogTitle>
      )}
      {position === "center" &&
        (isLoading ? <ModalLoader showPadding={title === undefined} /> : children)}
      {position === "right" && (
        <Box
          display="flex"
          flexDirection="column"
          flexGrow={1}
          justifyContent="space-between"
          overflow="auto"
        >
          <Box display="flex" flexDirection="column" flexGrow={1} overflow="auto">
            {isLoading ? <ModalLoader showPadding={title === undefined} /> : children}
          </Box>
          {action && (
            <Box
              display="flex"
              flexDirection="row"
              borderTop={`1px solid ${theme.palette.custom.secondaryBorder}`}
              padding="10px 0 0 0"
              margin="10px 0 0 0"
            >
              {action}
            </Box>
          )}
        </Box>
      )}
    </Dialog>
  );
};
