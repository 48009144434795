import { createTheme } from "@mui/material";
import type {} from "@mui/x-date-pickers/themeAugmentation";
import { ReactComponent as CancelIcon } from "../assets/general/cancel.svg";
import { ReactComponent as ChevronDown } from "../assets/general/chevron-down.svg";
import { palette } from "./Palette";

export const DarkTheme = createTheme({
  palette: {
    custom: {
      ...palette,
      // new settings for colors, use this new props
      //background
      mainBackground: "#2B2D2F",
      primaryBackground: palette.grey[700],
      secondaryBackground: palette.grey[600],

      // typography
      primaryTypography: palette.grey[50],
      secondaryTypography: palette.grey[50],
      grayTypography: palette.grey[500],
      redTypography: palette.red[400],
      yellowTypography: palette.yellow[400],
      greenTypography: palette.green[400],
      whiteTypography: "#FFFFFF",
      blueTypography: palette.blue[400],
      hyperlink: "#FFFFFF",

      // button
      hoverButton: "#515151",
      selectedButton: "#252525",
      withoutFillButton: "#FFFFFF",
      mainButton: palette.blue[400],
      disabledButton: palette.grey[500],
      deleteButton: palette.red[400],

      // overlay
      overlay: "rgba(6, 6, 6, 0.65)",

      // borders
      primaryBorder: palette.grey[600],
      secondaryBorder: palette.grey[500],
      tertiaryBorder: palette.grey[400],
      disabledBorder: palette.grey[500],

      // alerts
      successAlert: palette.green[400],
      errorAlert: palette.red[400],

      // old settings
      tableBorder: palette.grey[600],
      tableHover: palette.grey[600],
      primary: palette.grey[50],
      secondary: palette.grey[50],
      gray: palette.grey[500],
      grayHover: "#3A3A3A",
      error: palette.red[400],
      warning: palette.yellow[400],
      success: palette.green[400],
      white: "#FFFFFF",
      // blue: "#0191FD",
      errorHover: palette.red[300],
      activeStep: "#E0E0E0",
      columnHeader: "#FFFFFF", // SD-484
      user: palette.grey[50],
      planTitle: "#FFFFFF",
      textEditorBackground: palette.grey[700],
      baloo2: "'Baloo 2', cursive",
      raleway: "Inter",
      navButtonBackground: palette.grey[800],
      navArrowBackground: palette.blue[400],
      overviewIconInactive: "#929292",
      overviewIconActive: "#E0E0E0",
      cardBackgroundActive: palette.grey[800],
      cardBackgroundInactive: palette.grey[700],

      timestamp: palette.grey[500],

      defaultStatusColor: "#FFFFFF",
      defaultStatusBackgroundColor: palette.grey[600],
      primaryStatusColor: palette.blue[100],
      primaryStatusBackgroundColor: palette.blue[700],
      secondaryStatusBackgroundColor: palette.grey[600],
      secondaryStatusColor: palette.grey[100],
      warningStatusColor: palette.yellow[100],
      warningStatusBackgroundColor: palette.yellow[700],
      successStatusColor: palette.green[100],
      successStatusBackgroundColor: palette.green[700],
      errorStatusColor: palette.red[100],
      errorStatusBackgroundColor: palette.red[700],
      navbarIcon: palette.grey[50],
    },
    mode: "dark",
    primary: {
      main: palette.grey[50],
    },
    secondary: {
      main: palette.grey[50],
    },
    common: {
      black: "#000",
      white: "#FFF",
    },
    background: {
      default: "#2F2F2F",
      paper: "#2B2D2F",
    },
    success: {
      main: palette.green[400],
    },
    error: {
      main: palette.red[400],
    },
    warning: {
      main: palette.yellow[400],
    },
    action: {
      disabled: palette.grey[500],
    },
    text: {
      primary: palette.grey[50],
      secondary: palette.grey[50],
    },
  },

  typography: {
    h1: {
      fontFamily: "'Inter', sans-serif",
      color: "#F1F1F1",
      fontSize: 16,
      fontWeight: 600,
      letterSpacing: "0%",
    },
    h2: {
      fontFamily: "'Inter', sans-serif",
      color: "#F1F1F1",
      fontSize: 16,
      fontWeight: 600,
      letterSpacing: "0%",
    },
    h3: {
      fontFamily: "'Inter', sans-serif",
      color: "#F1F1F1",
      fontSize: 14,
      fontWeight: 600,
      letterSpacing: "0%",
    },
    h4: {
      fontFamily: "'Inter', sans-serif",
      color: "#F1F1F1",
      fontSize: 14,
      fontWeight: 600,
      letterSpacing: "0%",
    },
    h5: {
      fontFamily: "'Inter', sans-serif",
      fontSize: 12,
      fontWeight: 600,
      lineHeight: 1,
    },
    subtitle1: {
      fontFamily: "'Inter', sans-serif",
      color: "#E0E0E0",
      fontSize: 16,
      fontWeight: 500,
      letterSpacing: "0%",
    },
    body1: {
      fontFamily: "'Inter', sans-serif",
      color: "#E0E0E0",
      fontSize: 14,
      fontWeight: 400,
      letterSpacing: "0%",
    },
    button: {
      fontFamily: "'Inter', sans-serif",
      color: "#E0E0E0",
      fontSize: 14,
      fontWeight: 700,
      letterSpacing: "0%",
      textTransform: "none",
    },
    body2: {
      fontFamily: "'Inter', sans-serif",
      color: "#E0E0E0",
      fontSize: 14,
      fontWeight: 400,
      letterSpacing: "0%",
    },
    small: {
      fontFamily: "'Inter', sans-serif",
      fontSize: "12px",
      color: "#E0E0E0",
      fontWeight: 400,
    },
    fontFamily: ['"Baloo2"', "Baloo Bhai 2", "Inter", "sans-serif"].join(","),
    navbar: {
      color: "#929292",
      fontSize: "12px",
      fontWeight: 400,
      fontFamily: "'Inter', sans-serif",
    },
    navbarSelected: {
      color: "#ffffff",
      fontSize: "12px",
      fontWeight: 400,
      fontFamily: "'Inter', sans-serif",
    },
    logo: {
      color: "#F1F1F1",
      fontSize: "22px",
      fontWeight: "600",
      fontFamily: "'Baloo Bhai 2', cursive",
    },
    code: {
      color: "#F1F1F1",
      fontSize: "14px",
      fontFamily: "'Fira Code', monospace",
      fontWeight: 400,
    },
  },
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          "& fieldset": {
            borderColor: `${palette.grey[500]} !important`, // Default state
          },
          "&:hover fieldset": {
            borderColor: `${palette.grey[50]} !important`, // Hover state
          },
          "&.Mui-focused fieldset": {
            borderWidth: "1.5px !important",
            borderColor: `${palette.grey[50]} !important`, // Focused state
          },
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        switchBase: {
          opacity: 1,
          "&:hover": {
            backgroundColor: "rgba(0,0,0,0)",
          },
          "&.Mui-checked": {
            "&:hover": {
              backgroundColor: "rgba(0,0,0,0)",
            },
          },
          "&.Mui-checked + .MuiSwitch-track": {
            opacity: 0.4,
            backgroundColor: `${palette.blue[400]} !important`,
          },
          "&.Mui-checked .MuiSwitch-thumb": {
            backgroundColor: `${palette.blue[400]} !important`,
            boxShadow: "0px 1px 3px 0px #00000033",
          },
        },
        track: {
          opacity: 1,
          backgroundColor: "#E0E0E0",
        },
        thumb: {
          boxShadow: "0px 1px 3px 0px #00000033",
          backgroundColor: "#FFFFFF",
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        variant: "outlined",
        size: "small",
        fullWidth: true,
      },
      styleOverrides: {
        root: {
          color: palette.grey[50],
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              borderColor: palette.grey[500],
            },
            "&.Mui-focused fieldset": {
              borderWidth: "1px",
              borderColor: palette.grey[50], // Outline color when focused
            },
          },
          "& .MuiInputLabel-root": {
            color: palette.grey[500], // Label color

            "&.Mui-focused": {
              color: palette.grey[50], // Label color when focused
            },
          },
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          color: palette.grey[500],
          "&.Mui-focused": {
            color: palette.grey[50],
          },
          fontSize: "14px",
          fontWeight: 400,
          fontFamily: "'Inter', sans-serif",
          "&.Mui-error": {
            color: palette.grey[500],
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          borderRadius: "3px !important",
          height: "35px",
          fontSize: "14px",
          fontWeight: 400,
          display: "flex",
          alignItems: "center",
          fontFamily: "'Inter', sans-serif",
        },
        multiline: {
          height: "unset",
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          backgroundColor: "inherit",
          "&:hover": {
            backgroundColor: "#515151",
          },
          "&.Mui-selected": {
            backgroundColor: palette.grey[600],
          },
          color: "#FFFFFF",
        },
      },
    },
    MuiButtonBase: {
      styleOverrides: {
        root: {
          fontFamily: "'Inter', sans-serif",
          fontWeight: 700,
        },
      },
    },
    MuiButton: {
      defaultProps: {
        variant: "contained",
      },
      styleOverrides: {
        root: {
          fontSize: "14px",
          fontFamily: "'Inter', sans-serif",
          fontWeight: 700,
          height: "35px",
          textTransform: "none",
          borderRadius: "3px",
          boxShadow: "none",
          "&:hover": {
            boxShadow: "none",
          },
          "&:active": {
            boxShadow: "none",
          },
          "&:focus": {
            boxShadow: "none",
          },
        },
        contained: {
          color: "#FFFFFF",
        },
        containedPrimary: {
          backgroundColor: palette.blue[400],
        },
        text: {
          ":default": {
            color: palette.red[400],
          },
        },
        outlined: {
          color: palette.grey[50],
          borderColor: palette.grey[50],
          "&:hover": {
            borderColor: palette.grey[50],
          },
        },
        textPrimary: {
          color: "#FFFFFF",
          textDecoration: "underline",
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: "#EEF7FF",
          color: "#515151",
          fontFamily: "'Inter', sans-serif",
          fontSize: 14,
          fontWeight: 400,
          borderRadius: "6px",
          padding: "10px",
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontFamily: "'Inter', sans-serif",
          fontSize: "14px",
          fontWeight: 400,
          color: palette.grey[50],
          "&:hover": {
            backgroundColor: palette.grey[700],
          },
        },
      },
    },
    MuiSelect: {
      defaultProps: {
        IconComponent: ChevronDown,
        variant: "outlined",
      },
      styleOverrides: {
        select: {
          padding: "8.5px 14px",
          maxHeight: "35px",
          color: palette.grey[50],
          fontFamily: "'Inter', sans-serif",
          fontSize: "14px",
          fontWeight: 400,
        },
        icon: {
          margin: "5px",
          color: "#FFFFFF",
        },
      },
    },
    MuiDialog: {
      defaultProps: {
        slotProps: {
          backdrop: {
            style: {
              backgroundColor: "#3A3A3A66",
              opacity: "40%",
            },
          },
        },
      },
      styleOverrides: {
        paper: {
          backgroundColor: `${palette.grey[800]}!important`,
          borderRadius: "6px",
          borderWidth: "1px",
          borderColor: palette.grey[600],
          borderStyle: "solid",
        },
      },
    },
    MuiBreadcrumbs: {
      styleOverrides: {
        root: {
          color: palette.grey[50],
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          color: palette.grey[50],
          background: palette.grey[800],
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          background: palette.grey[800],
          overflow: "hidden",
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          background: palette.grey[800],
        },
      },
    },
    MuiLink: {
      defaultProps: {
        underline: "always",
        color: "#FFFFFF",
      },
      styleOverrides: {
        root: {
          textDecorationColor: "inherit",
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        noOptions: {
          padding: "4px",
        },
        tag: {
          margin: "0 3px",
          height: "26px",
        },
        option: {
          padding: "2px 4px",
          fontSize: "14px",
          fontFamily: "'Inter', sans-serif",
          fontWeight: 400,
        },
        inputRoot: {
          borderRadius: "3px",
          "& input": {
            maxHeight: "35px",
          },
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          borderRadius: "3px",
        },
      },
    },
    MuiChip: {
      defaultProps: {
        deleteIcon: <CancelIcon />,
      },
      styleOverrides: {
        root: {
          height: "24px",
          borderRadius: "38px",
          border: "1px solid",
          borderColor: palette.grey[500],
          background: palette.grey[600],
        },
        label: {
          paddingLeft: "6px",
          paddingRight: "6px",
        },
        deleteIcon: {
          color: palette.grey[800],
          stroke: palette.grey[50],
          width: "17px",
          height: "17px",
          marginRight: "4px",
          marginLeft: "-4px",
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          fontFamily: "'Inter', sans-serif",
          fontSize: "14px",
          fontWeight: 400,
          padding: "unset",
          paddingLeft: "10px",
          color: "unset",
        },
      },
    },
    MuiCalendarPicker: {
      styleOverrides: {
        root: {
          fontWeight: 500,
          backgroundColor: palette.grey[700],
          borderRadius: "3px",
        },
      },
    },
    MuiPickersCalendarHeader: {
      styleOverrides: {
        root: {
          padding: 0,
          margin: "10px 18px",
        },
        label: {
          fontSize: "15px",
          fontWeight: 600,
          lineHeight: "18px",
          margin: "15px",
          height: "20px",
        },
        switchViewIcon: {
          color: palette.blue[400],
        },
      },
    },
    MuiPickersArrowSwitcher: {
      styleOverrides: {
        button: {
          color: palette.blue[400],
        },
        spacer: {
          width: "5px",
          padding: 0,
          margin: 0,
        },
      },
    },
    MuiDayPicker: {
      styleOverrides: {
        weekDayLabel: {
          fontSize: "15px",
          fontWeight: 500,
          margin: "5px 12px",
          height: "18px",
          width: "18px",
          color: palette.grey[500],
        },
        weekContainer: {
          margin: "1px",
          padding: "0",
        },
        slideTransition: {
          minHeight: "180px",
        },
      },
    },
    MuiMonthPicker: {
      styleOverrides: {
        root: {
          "&.MuiMonthPicker-root": {
            "& .PrivatePickersMonth-root": {
              fontSize: "15px",
              fontWeight: 500,
              border: `1px ${palette.grey[700]} solid`,
              borderRadius: "3px",
              padding: "10px",
            },
            "& .Mui-selected": {
              fontSize: "15px",
              fontWeight: 500,
              color: "#FFFFFF",
              backgroundColor: `${palette.blue[400]}!important`,
              border: `1px ${palette.grey[700]} solid`,
              borderRadius: "3px",
              padding: "10px",
            },
          },
        },
      },
    },
    MuiPickersDay: {
      styleOverrides: {
        root: {
          fontFamily: "'Inter', sans-serif",
          fontSize: "15px",
          "&.Mui-selected": {
            color: "#FFFFFF",
            backgroundColor: `${palette.blue[400]}!important`,
            border: `1px ${palette.grey[700]} solid`,
            borderRadius: "3px",
            padding: "10px",
          },
          "&:not(.Mui-selected)": {
            border: `1px ${palette.grey[700]} solid`,
            borderRadius: "3px",
          },
        },
        today: {
          color: palette.blue[400],
          backgroundColor: `${palette.grey[700]}!important`,
        },
        dayWithMargin: {
          borderRadius: "3px",
          margin: "5px 12px",
          fontSize: "15px",
          fontWeight: 500,
          height: "18px",
          width: "18px",
          textAlign: "right",
          color: palette.grey[50],
          backgroundColor: `${palette.grey[700]}!important`,
        },
      },
    },
    MuiDatePicker: {
      defaultProps: {
        OpenPickerButtonProps: {
          style: {
            color: palette.blue[400],
          },
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          borderRadius: "3px",
        },
      },
    },
    MuiYearPicker: {
      styleOverrides: {
        root: {
          "& .PrivatePickersYear-root": {
            "& .PrivatePickersYear-yearButton": {
              fontFamily: "'Inter', sans-serif",
              fontSize: "15px",
              fontWeight: 500,
              margin: "2px 0",
              "&.Mui-selected": {
                color: "#FFFFFF",
                backgroundColor: `${palette.blue[400]}!important`,
                border: `1px ${palette.grey[700]} solid`,
                borderRadius: "3px",
              },
              "&:not(.Mui-selected)": {
                border: `1px ${palette.grey[700]} solid`,
                borderRadius: "3px",
              },
              "&.Mui-disabled": {
                color: palette.grey[500],
                cursor: "default",
                pointerEvents: "none",
              },
            },
          },
        },
      },
    },
    MuiLinearProgress: {
      styleOverrides: {
        root: {
          backgroundColor: `${palette.grey[600]}!important`,
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          marginTop: "6px",
          color: "#2F2F2F",
          backgroundColor: "#2F2F2F",
          boxShadow: "0px 4px 6px 2px rgba(0, 0, 0, 0.12)",
          border: `1px solid ${palette.grey[500]}`,
        },
      },
    },
    MuiSkeleton: {
      styleOverrides: {
        root: {
          backgroundColor: `${palette.grey[600]}75`,
        },
      },
    },
    MuiStepIcon: {
      styleOverrides: {
        root: {
          color: `${palette.grey[700]} !important`,
          "&.Mui-completed": {
            color: `${palette.blue[400]} !important`,
          },
        },
        text: {
          fontFamily: "'Inter', sans-serif",
          fill: "#FFFFFF",
          fontSize: "14px",
          fontWeight: 500,
        },
      },
    },
    MuiStepLabel: {
      styleOverrides: {
        label: {
          fontFamily: "'Inter', sans-serif",
          color: "#FFFFFF !important",
          fontSize: "14px",
          fontWeight: "600 !important",
        },
      },
    },
    MuiStepConnector: {
      styleOverrides: {
        line: {
          width: "50px",
        },
        root: {
          "&.Mui-active .MuiStepConnector-line": {
            borderColor: "#FFFFFF",
          },
          "&.Mui-completed .MuiStepConnector-line": {
            borderColor: "#FFFFFF",
          },
        },
      },
    },
  },
});
