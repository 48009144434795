import { Box, TextField, Typography, useTheme } from "@mui/material";
import { ColorBox, ColorList } from "./CreateTagModal";
import { useState } from "react";
import { getBadgeColor } from "../../../utilities/BadgeColors";

type ColorPaletteProps = {
  color: string;
  onChange: (color: string) => void;
  colors?: string[];
};

const ColorPalette = (props: ColorPaletteProps) => {
  const theme = useTheme();
  const {
    color,
    onChange,
    colors = ["#505258", "#6A2D2B", "#685903", "#2C532C", "#1D315E", "#764207", "#573197"],
  } = props;
  const isDarkMode = theme.palette.mode === "dark";
  const innerTextColor = theme.palette.custom.secondaryTypography;
  const [colorError, setColorError] = useState<string | null>(null);
  const exampleColor = getBadgeColor(color);

  const cleanInvalidCharsForHexColor = (value: string): string => {
    // also check the limit of hex color length
    if (value.length > 7) {
      return value.slice(0, 7);
    }
    return `#${value.replace(/[^0-9A-F]/gi, "")}`;
  };

  const isValidHexColorRegex = (value: string): boolean => {
    // accept between 1 and 6 characters
    return /^#[0-9A-Fa-f]{6}$/i.test(value);
  };

  const handleChange = (color: string) => {
    if (!isValidHexColorRegex(color)) {
      setColorError("Invalid hex color");
    }
    setColorError("");
    onChange(color);
  };

  return (
    <Box display="flex" flexDirection="column" gap="10px">
      <Typography color={innerTextColor} variant="h3">
        Color
      </Typography>
      <ColorList
        onChangeColor={(selectedColor: string) => handleChange(selectedColor)}
        colors={colors}
        selectedColor={color}
      />
      {/* <Box display="flex" flexDirection="row" alignItems="center" gap="8px">
        <ColorBox size="35px" selected={true} onClick={() => {}} color={color} />
        <TextField
          value={color}
          onChange={(e) => handleChange(cleanInvalidCharsForHexColor(e.target.value))}
          label="Color"
        />
      </Box>
      {colorError && typeof colorError === "string" && (
        <Typography fontSize="12px" variant="body2" color="error">
          {colorError}
        </Typography>
      )} */}
      <Typography color={innerTextColor} variant="h3">
        Example
      </Typography>
      <Box display="flex">
        <Box
          padding="4px"
          borderRadius="3px"
          alignItems="center"
          display="flex"
          flexDirection="row"
          sx={{
            background: isDarkMode ? `${exampleColor.typography}50` : exampleColor.background,
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          }}
          gap="8px"
        >
          <Typography
            variant="h5"
            color={isDarkMode ? exampleColor.background : exampleColor.typography}
          >
            Example
          </Typography>
          <Box
            padding="4px 8px"
            borderRadius="1.5px"
            alignItems="center"
            display="flex"
            bgcolor={isDarkMode ? exampleColor.background : exampleColor.typography}
            gap="8px"
          >
            <Typography variant="h5" color={isDarkMode ? exampleColor.background : "#FFFFFF"}>
              0.5
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export { ColorPalette };
