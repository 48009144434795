import { Box, Typography } from "@mui/material";
import { NavbarIcon } from "../../../atoms/navbar/Icon";
import { PluginData } from "../../../models/types";
import { DisconnectButton, EditButton } from "./Buttons";

type DatabricksIntegrationProps = {
  token?: string;
};

type EditDatabricksPluginProps = {
  pluginData: DatabricksIntegrationProps;
  plugin: PluginData;
  onEdit: () => void;
  onDisconnect: () => void;
};

const EditDatabricksPlugin = (props: EditDatabricksPluginProps) => {
  const { pluginData, plugin, onEdit, onDisconnect } = props;
  return (
    <Box sx={{ display: "flex", justifyContent: "space-between" }}>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <NavbarIcon
          variant="lock"
          sx={{
            height: "30px",
          }}
        />
        <Typography variant="h2">{pluginData.token}</Typography>
      </Box>
      <Box>
        <EditButton onEdit={onEdit} />
        <DisconnectButton onDisconnect={onDisconnect} />
      </Box>
    </Box>
  );
};

export { EditDatabricksPlugin };
