import { LoadingButton } from "@mui/lab";
import { Box, CircularProgress, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { ConfirmationModal } from "../../../components/ConfirmationModal";
import { SmallModal } from "../../../components/SmallModal";
import { openSnackbar } from "../../../context/SnackbarContext";
import { PluginData, PluginType } from "../../../models/types";
import { PluginCategory } from "../../../molecules/PluginCategory";
import { PluginAuthForm } from "../../../molecules/form/PluginAuthForm";
import { PluginConfigureModalHeader } from "../../../molecules/organization/integrations/PluginConfigureModalHeader";
import { PluginEdit } from "../../../molecules/organization/integrations/PluginEdit";
import { PluginSetupAuth } from "../../../molecules/organization/integrations/PluginSetupAuth";
import { WebhooksOverview } from "../../../molecules/organization/webhooks/WebhooksOverview";
import { openGuide } from "../../../services/IntercomService";
import {
  disconnectIntegration,
  getPluginInfo,
  updateIntegrationParams,
} from "../../../services/PluginService";

type PluginConfigureModalProps = {
  open: boolean;
  onClose: () => void;

  plugin: PluginData;
  setPlugin: (plugin: PluginData) => void;
  setOpenWebhookConfigModal?: (open: boolean) => void;
  setOpenMessageHistoryModal?: (open: boolean) => void;
  allPlugins: PluginData[];
  closeOnOauthRedirect?: boolean;
};

export const PluginConfigureModal = (props: PluginConfigureModalProps) => {
  const {
    open,
    onClose,
    plugin,
    setOpenWebhookConfigModal,
    setOpenMessageHistoryModal,
    allPlugins,
    setPlugin,
    closeOnOauthRedirect = false,
  } = props;

  const [showEditPlugin, setShowEditPlugin] = useState<boolean>(false);
  const [action, setAction] = useState<"edit" | "disconnect" | "">("");
  const [openConfirmationModal, setOpenConfirmationModal] = useState<boolean>(false);
  const [title, setTitle] = useState<string>("");
  const [description, setDescription] = useState("");
  const [acceptText, setAcceptText] = useState("");

  const [isPluginLoading, setIsPluginLoading] = useState<boolean>(true);
  const [pluginData, setPluginData] = useState<any>();
  const [showOpenGuide, setShowOpenGuide] = useState<boolean>(false);

  const [oauthRedirect, setOauthRedirect] = useState<boolean>(false);

  const categories = plugin.plugin_metadata?.tags?.map((tag: any) => {
    const splitTags = tag.split("-");
    let name = "";
    for (let i = 0; i < splitTags.length; i++) {
      if (i !== 0) {
        name += " ";
      }
      name += splitTags[i][0].toUpperCase() + splitTags[i].substring(1);
    }
    return name;
  });

  const [active, setActive] = useState(plugin.status === "Active");

  const loadPluginData = async () => {
    try {
      const resp = await getPluginInfo(plugin.plugin_type);
      setPluginData(resp);
    } catch (e) {
      setActive(false);
    }

    setIsPluginLoading(false);
  };

  useEffect(() => {
    setIsPluginLoading(true);
    setShowEditPlugin(false);
    setShowOpenGuide(false);
    setActive(plugin.status === "Active");

    if (plugin.status === "Active") {
      loadPluginData();
    } else {
      setIsPluginLoading(false);
    }

    for (let i = 0; i < plugin.auth.length; i++) {
      const auth = plugin.auth[i];
      if (auth.auth_type === "token_auth") {
        setShowOpenGuide(plugin.guide_checklist !== 0);
      }
    }
  }, [plugin]);

  const updatePlugin = async (params: any) => {
    setIsPluginLoading(true);
    try {
      await updateIntegrationParams(plugin.plugin_type, params);
      await loadPluginData();
      setShowEditPlugin(false);
      setActive(true);
    } catch (e) {
      openSnackbar("Error Validating details", "error");
    } finally {
      setIsPluginLoading(false);
    }
  };

  const disconnectPlugin = async () => {
    try {
      await disconnectIntegration(plugin.plugin_type);
      loadPluginData();
    } catch (e) {
      openSnackbar("Error deleting the Plugin", "error");
    } finally {
      setIsPluginLoading(false);
    }
  };

  const onConfirm = async () => {
    if (action === "edit") {
      setShowEditPlugin(true);
    }
    if (action === "disconnect") {
      setIsPluginLoading(true);
      disconnectPlugin();
    }
  };
  const onEdit = () => {
    setTitle(`Edit ${plugin.plugin_display_name} Plugin`);
    setDescription(
      `Are you sure you want to edit the ${plugin.plugin_display_name} plugin? Remember to make sure all fields are correct before selecting the validation button.`
    );
    setAcceptText("Yes, edit plugin");
    setAction("edit");
    setOpenConfirmationModal(true);
  };

  const onDisconnect = () => {
    setTitle(`Disconnect ${plugin.plugin_display_name} Plugin`);
    setDescription(
      `Are you sure you want to disconnect the ${plugin.plugin_display_name} plugin? Once is disconnected you will need to re-connect it again.`
    );
    setAcceptText("Yes, disconnect plugin");
    setAction("disconnect");
    setOpenConfirmationModal(true);
  };

  const onCloseModal = () => {
    setShowEditPlugin(false);
    setShowOpenGuide(false);
    setOauthRedirect(false);
    onClose();
  };

  const handlePluginChange = (pluginType: PluginType) => {
    const hasPlugin = allPlugins.find((p) => p.plugin_type === pluginType);
    if (hasPlugin) {
      setPlugin(hasPlugin);
    }
  };

  const showConnectedPluginDetails = active && pluginData && !showEditPlugin;
  const showSetupPlugin = !active && !showEditPlugin;
  const showWebhook = active && plugin.webhook;

  useEffect(() => {
    if (oauthRedirect && closeOnOauthRedirect) {
      onCloseModal();
    }
  }, [oauthRedirect]);

  return (
    <>
      <ConfirmationModal
        open={openConfirmationModal}
        onClose={() => {
          setOpenConfirmationModal(false);
        }}
        title={title}
        description={description}
        acceptText={acceptText}
        onAccept={() => onConfirm()}
      />
      <SmallModal
        customTitle={
          <PluginConfigureModalHeader
            title={plugin.plugin_display_name}
            icon={plugin.plugin_logo_url}
            active={active}
          />
        }
        title="Integrations"
        open={open}
        onClose={onCloseModal}
        size="medium"
        isLoading={isPluginLoading}
      >
        {isPluginLoading ? (
          <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
            <CircularProgress />
          </Box>
        ) : (
          <Stack gap="15px">
            {categories && (
              <Box
                sx={{
                  display: "flex",
                  gap: "10px",
                }}
              >
                {categories.map((category: any) => {
                  return <PluginCategory name={category} />;
                })}
              </Box>
            )}
            <Typography variant="body1">{plugin.plugin_description}</Typography>
            {showSetupPlugin && (
              <PluginSetupAuth
                onChangePlugin={handlePluginChange}
                plugin={plugin}
                onSetup={updatePlugin}
                setOauthRedirect={() => setOauthRedirect(true)}
              />
            )}
            {showConnectedPluginDetails && (
              <PluginEdit
                allPlugins={allPlugins}
                setPlugin={setPlugin}
                active={active}
                onDisconnect={onDisconnect}
                onEdit={onEdit}
                plugin={plugin}
                onSetup={updatePlugin}
                pluginData={pluginData}
              />
            )}
            {showEditPlugin && (
              <PluginAuthForm
                plugin={plugin}
                pluginData={pluginData}
                updatePlugin={updatePlugin}
                onCancel={() => setShowEditPlugin(false)}
                setOauthRedirect={() => setOauthRedirect(true)}
              />
            )}
            {showWebhook && (
              <WebhooksOverview
                plugin={plugin}
                pluginData={pluginData}
                setOpenMessageHistoryModal={setOpenMessageHistoryModal}
                setOpenWebhookConfigModal={setOpenWebhookConfigModal}
              />
            )}
            {showOpenGuide && (
              <LoadingButton onClick={() => openGuide(plugin.guide_checklist)}>
                Open Guide
              </LoadingButton>
            )}
          </Stack>
        )}
      </SmallModal>
    </>
  );
};
