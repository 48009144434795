import { Box, Typography } from "@mui/material";
import { DisconnectButton } from "./Buttons";

type EditGithubPluginProps = {
  pluginData: any;
  onDisconnect: () => void;
};

const EditGithubPlugin = ({ pluginData, onDisconnect }: EditGithubPluginProps) => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <Typography variant="h2">
        {`${pluginData.account_name}`}&nbsp;
        {`(Repositories Connected: ${pluginData?.repos_count})`}
      </Typography>
      <DisconnectButton onDisconnect={onDisconnect} />
    </Box>
  );
};
export { EditGithubPlugin };
