import { HuggingFaceDataset, HuggingFaceModel } from "../models/types";
import { httpGetAuthenticated } from "../services/ApiService";

const endpoints = {
  base: () => "/integration/hugging_face",
};

export const getHuggingFaceModels = async (params: any) => {
  const { data } = await httpGetAuthenticated<HuggingFaceModel[]>(
    `${endpoints.base()}/get_models`,
    {
      params,
    }
  );
  return data;
};

export const getHuggingFaceDatasets = async (params: any) => {
  const { data } = await httpGetAuthenticated<HuggingFaceDataset[]>(
    `${endpoints.base()}/get_datasets`,
    {
      params,
    }
  );
  return data;
};
