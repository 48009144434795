import React, { ReactNode, useEffect } from "react";
import { useLocation } from "react-router-dom";
import SecondaryBox from "../atoms/box/SecondaryBox";

interface ErrorBoundaryProps {
  children: ReactNode;
  box?: boolean;
}

interface ErrorBoundaryState {
  hasError: boolean;
  box?: boolean;
}

class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false, box: props.box };
  }

  static getDerivedStateFromError(error: Error): ErrorBoundaryState {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
    console.error("Error caught by ErrorBoundary:", error, errorInfo);
  }

  resetError = () => {
    this.setState({ hasError: false });
  };

  render() {
    if (this.state.hasError) {
      return this.state.box ? (
        <SecondaryBox>
          <div style={{ paddingBottom: "20px", textAlign: "center" }}>
            <h1>Something went wrong.</h1>
            <p>Please try refreshing the page or contact support if the issue persists.</p>
          </div>
        </SecondaryBox>
      ) : (
        <div style={{ padding: "20px", textAlign: "center" }}>
          <h1>Something went wrong.</h1>
          <p>Please try refreshing the page or contact support if the issue persists.</p>
        </div>
      );
    }

    return this.props.children;
  }
}

const ErrorBoundaryWrapper: React.FC<ErrorBoundaryProps> = ({ children, box = false }) => {
  const location = useLocation();
  const errorBoundaryRef = React.useRef<ErrorBoundary>(null);

  useEffect(() => {
    // Reset the error boundary state on route change
    if (errorBoundaryRef.current) {
      errorBoundaryRef.current.resetError();
    }
  }, [location]);

  return (
    <ErrorBoundary box={box} ref={errorBoundaryRef}>
      {children}
    </ErrorBoundary>
  );
};

export default ErrorBoundaryWrapper;
