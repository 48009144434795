import { LoadingButton } from "@mui/lab";
import { PluginAuth } from "../../models/types";
type PluginOAuthFormProps = {
  auth: PluginAuth;
  setOauthRedirect?: () => void;
};
const PluginOAuthForm = (props: PluginOAuthFormProps) => {
  const { auth, setOauthRedirect } = props;
  return (
    <LoadingButton
      variant="contained"
      onClick={() => {
        window.open(auth.auth_url, "_blank");
        setOauthRedirect && setOauthRedirect();
      }}
    >
      Configure through OAuth
    </LoadingButton>
  );
};

export { PluginOAuthForm };
