import { Box, TextField, Typography } from "@mui/material";
import { useEffect, useRef, useState } from "react";

type InputOnFocusProps = {
  value: string;
  onChange: (value: string) => Promise<void>;
};

export const InputOnFocus = ({ value, onChange }: InputOnFocusProps) => {
  const [isFocused, setIsFocused] = useState(false);
  const [inputValue, setInputValue] = useState(value);
  const inputRef = useRef<HTMLInputElement>(null);
  const [loading, setLoading] = useState(false);
  const handleUpdate = async () => {
    try {
      setLoading(true);
      await onChange(inputValue);
      setIsFocused(false);
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  return isFocused ? (
    <Box display="flex" flexDirection="row" gap="5px" alignItems="center">
      <TextField
        ref={inputRef}
        variant="standard"
        sx={{
          "& .MuiInputBase-input": {
            fontSize: 22,
            fontWeight: "600",
            borderBottom: "none",
          },
          textDecoration: "underline",
          "& .MuiInput-underline:before": {
            borderBottom: "none",
          },
          "& .MuiInput-underline:hover:before": {
            borderBottom: "none",
          },
          "& .MuiInput-underline:hover:after": {
            borderBottom: "none",
          },
          "& .MuiInput-underline:after": {
            borderBottom: "none",
          },
        }}
        value={inputValue}
        onChange={(e) => setInputValue(e.target.value)}
        onBlur={() => handleUpdate()}
      />
    </Box>
  ) : (
    <Typography
      onClick={() => {
        setIsFocused(true);
        setTimeout(() => inputRef.current?.focus(), 1);
      }}
      variant="h1"
      fontSize={22}
    >
      {inputValue}
    </Typography>
  );
};
