import { Box } from "@mui/material";
import { isArray } from "lodash";
import { DefaultBadge } from "../atoms/StatusBadge";

type DefaultBadgeListProps = {
  badges: string[];
  renderIcon?: (badge: string) => React.ReactNode;
  onClick?: (badge: string) => void;
  expanded?: boolean;
};

export const DefaultBadgeList = ({
  badges,
  renderIcon,
  onClick,
  expanded,
}: DefaultBadgeListProps) => {
  return (
    <Box
      display="flex"
      flexDirection="row"
      alignItems="center"
      width="100%"
      maxWidth="100%"
      gap="5px"
      flexWrap="wrap"
    >
      {badges &&
        isArray(badges) &&
        badges?.map((badge, index) => (
          <DefaultBadge
            renderIcon={renderIcon ? () => renderIcon(badge) : undefined}
            key={index}
            value={badge}
            expanded={expanded}
            onClick={onClick ? () => onClick(badge) : undefined}
          />
        ))}
    </Box>
  );
};
