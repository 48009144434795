import { useQuery } from "react-query";
import { PluginDataFetchOptions, PluginType } from "../models/types";
import {
  getAllPluginsMetaData,
  getGithubRepositories,
  getPluginInfo,
  getS3BucketslistApi,
  getS3ObjectslistApi,
} from "../services/PluginService";
import { QueryKey } from "../state/QueryStore";

export const usePlugins = (params: PluginDataFetchOptions) => {
  return useQuery([QueryKey.Plugins], async () => {
    const { data } = await getAllPluginsMetaData();
    return data;
  });
};

export const usePluginsPaginated = (params: PluginDataFetchOptions) => {
  return useQuery([QueryKey.Plugins, params], async () => {
    const { data } = await getAllPluginsMetaData();
    return {
      count: data.length,
      next: null,
      previous: null,
      results: params.id
        ? data.filter((plugin) => params.id?.some((id) => plugin.plugin_type === id))
        : data,
    };
  });
};

export const usePlugin = (pluginId: string, params?: any) => {
  return useQuery([QueryKey.Plugins, pluginId], async () => {
    const { data } = await getAllPluginsMetaData();
    const plugin = data.find((plugin) => plugin.plugin_type === pluginId);
    return plugin;
  });
};

export const searchPlugins = async (searchText: string) => {
  const plugins = await getAllPluginsMetaData();
  return plugins.data.filter((plugin) => plugin.plugin_display_name.includes(searchText));
};

export const searchGithubRepositories = async (searchText?: string) => {
  const repositories = await getGithubRepositories(searchText);
  return repositories.data;
};

export const searchBuckets = async (searchText: string) => {
  const buckets = await getS3BucketslistApi();
  return buckets.data;
};

export const searchS3Objects = async (bucket: string, search: string) => {
  const objects = await getS3ObjectslistApi({ bucket, search });
  return objects.data;
};

export const usePluginInfo = (pluginType: PluginType) => {
  return useQuery([QueryKey.Plugins, pluginType], async () => {
    const data = await getPluginInfo(pluginType);
    return data;
  });
};
