import { getHuggingFaceDatasets, getHuggingFaceModels } from "../services/HuggingFaceService";
import queryClient, { QueryKey } from "../state/QueryStore";

export const fetchHuggingFaceModels = async (params: any) => {
  return queryClient.fetchQuery([QueryKey.HuggingFace, params], async () => {
    const data = await getHuggingFaceModels(params);
    return data;
  });
};

export const fetchHuggingFaceDatasets = async (params: any) => {
  return queryClient.fetchQuery([QueryKey.HuggingFace, params], async () => {
    const data = await getHuggingFaceDatasets(params);
    return data;
  });
};

export const searchHuggingFaceModels = async (searchText: string) => {
  const results = await fetchHuggingFaceModels({ search: searchText });
  return results;
};

export const searchHuggingFaceDatasets = async (searchText: string) => {
  const results = await fetchHuggingFaceDatasets({ search: searchText });
  return results;
};
