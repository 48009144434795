import { DefaultBadge } from "../../atoms/StatusBadge";
import { navigateToDocumentationInNewPage } from "../../components/AssetNavigator";
import { useAsset } from "../../hooks/useAssets";
import { RectangularLoader } from "../../molecules/skeleton-loader/RectangularLoader";

type AssetBadgeProps = {
  assetId: string;
};

export const AssetBadge = ({ assetId }: AssetBadgeProps) => {
  const { data: asset, isLoading } = useAsset(assetId);

  return isLoading ? (
    <RectangularLoader height="20px" width="200px" />
  ) : (
    <DefaultBadge value={asset.name} onClick={() => navigateToDocumentationInNewPage(asset)} />
  );
};
