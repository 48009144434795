import { Typography, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { SearchableDropdown } from "../../../components/SearchableDropdown";
import {
  useDatabricksCatalogs,
  useDatabricksModels,
  useDatabricksSchemas,
} from "../../../hooks/useDatabricks";
import { DatabricksCatalog, DatabricksSchema } from "../../../models/types";

type CreateDatabricksModelProps = {
  model: { name: string } | null;
  setModel: (model: { name: string } | null) => void;
};
export const CreateDatabricksModel = ({ model, setModel }: CreateDatabricksModelProps) => {
  const theme = useTheme();
  const textColor = theme.palette.custom.secondaryTypography;
  const [catalog, setCatalog] = useState<DatabricksCatalog | null>(null);
  const [schema, setSchema] = useState<DatabricksSchema | null>(null);
  const { data: catalogs, isLoading: isLoadingCatalog } = useDatabricksCatalogs();
  const { data: schemas, isLoading: isLoadingSchema } = useDatabricksSchemas({
    catalog_name: catalog?.name ?? "",
  });
  const { data: models, isLoading: isLoadingModels } = useDatabricksModels({
    catalog_name: catalog?.name,
    schema_name: schema?.name,
  });

  useEffect(() => {
    setSchema(null);
    setModel(null);
  }, [catalog]);

  useEffect(() => {
    setModel(null);
  }, [schema]);

  return (
    <>
      <Typography variant="h3" color={textColor}>
        Catalog
      </Typography>
      <SearchableDropdown<DatabricksCatalog>
        value={catalog}
        onChange={setCatalog}
        disabled={isLoadingCatalog}
        showLoading={isLoadingCatalog}
        getOptions={(search: string) =>
          catalogs?.filter((option) => option.name.includes(search.toLowerCase())) ?? []
        }
        getOptionLabel={(option) => option.name}
        isOptionEqualToValue={(a, b) => a === b}
      />
      <Typography variant="h3" color={textColor}>
        Schema
      </Typography>
      <SearchableDropdown<DatabricksSchema>
        value={schema}
        onChange={setSchema}
        showLoading={isLoadingSchema}
        disabled={!catalog || isLoadingSchema}
        getOptions={(search: string) =>
          schemas?.filter((option) => option.name.includes(search.toLowerCase())) ?? []
        }
        getOptionLabel={(option) => option.name}
        isOptionEqualToValue={(a, b) => a === b}
      />
      <Typography variant="h3" color={textColor}>
        Model
      </Typography>
      <SearchableDropdown<{ name: string }>
        value={model}
        showLoading={isLoadingModels}
        onChange={(option) => setModel(option)}
        getOptions={(search: string) =>
          models?.filter((option) => option.name.includes(search.toLowerCase())) ?? []
        }
        disabled={!schema || isLoadingModels}
        getOptionLabel={(option) => option.name}
        isOptionEqualToValue={(a, b) => a.name === b.name}
      />
    </>
  );
};
