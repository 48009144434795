import { Box, Button, TextField, Typography, useTheme } from "@mui/material";
import { SmallModal } from "../../../components/SmallModal";
import { RegistryFieldStyleRule, RuleLogicOperator } from "../../../models/types";
import { useEffect, useState } from "react";
import { ColorPalette } from "../tag/ColorPalette";
import { SearchableDropdown } from "../../../components/SearchableDropdown";
import { EvidenceOperators } from "../../../utilities/UIHelper";

type AddRegistryFieldRuleModalProps = {
  /** Should he modal be open? */
  open: boolean;
  /** Callback to close the modal */
  onClose: () => void;
  rule?: RegistryFieldStyleRule;
  onSave: (rule: RegistryFieldStyleRule) => void;
};

export const AddRegistryFieldRuleModal = (props: AddRegistryFieldRuleModalProps) => {
  const { open, onClose, rule, onSave } = props;
  const theme = useTheme();
  const [value, setValue] = useState<string>("");
  const [operator, setOperator] = useState<{ key: RuleLogicOperator; label: string } | null>(null);
  const [color, setColor] = useState<string>("");
  const innerTextColor = theme.palette.custom.secondaryTypography;
  useEffect(() => {
    setValue(rule?.value || "");
    setOperator(EvidenceOperators.find((o) => o.key === rule?.operator) || null);
    setColor(rule?.color || "#615E5E");
  }, [rule]);

  const disabledButton = !value || !operator || !color;

  return (
    <SmallModal open={open} onClose={onClose} title="Add Rule">
      <Box display="flex" flexDirection="column" gap="10px">
        <Typography variant="h3" color={innerTextColor}>
          Operator
        </Typography>
        <SearchableDropdown<{ key: RuleLogicOperator; label: string }>
          value={operator}
          onChange={(newValue) => (newValue !== null ? setOperator(newValue) : setOperator(null))}
          getOptions={(search: string) =>
            EvidenceOperators.filter((item) =>
              item.label.toUpperCase().includes(search.toUpperCase())
            )
          }
          getOptionLabel={(item) => item.label}
          isOptionEqualToValue={(a, b) => a.key === b.key}
        />
        <Typography variant="h3" color={innerTextColor}>
          Value
        </Typography>
        <TextField
          error={false}
          value={value}
          onChange={(e) => setValue(e.target.value)}
          required
          label="Value"
        />
        <ColorPalette color={color} onChange={setColor} />
        <Button
          disabled={disabledButton}
          variant="contained"
          onClick={() => {
            onSave({
              value,
              operator: operator!.key,
              color,
            });
          }}
        >
          {rule ? "Save" : "Add Rule"}
        </Button>
      </Box>
    </SmallModal>
  );
};
