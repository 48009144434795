import { GithubRepository, PluginType, S3Object } from "../../models/types";
import { AddGithubRepository } from "./AddGithubRepository";
import { AddHuggingFaceData } from "./AddHuggingFaceData";
import { AddS3PluginDataForm } from "./AddPluginData";

type SelectedPluginTypeProps = {
  type: PluginType;
  setSelectedResource: (newField: any) => void;
  selectedResource: any;
};

const SelectedPluginType = (props: SelectedPluginTypeProps) => {
  const { type, setSelectedResource, selectedResource } = props;
  switch (type) {
    case "aws":
      return (
        <AddS3PluginDataForm
          setSelectedResource={setSelectedResource}
          selectedResource={selectedResource}
        />
      );
    case "github":
      return (
        <AddGithubRepository
          setSelectedResource={setSelectedResource}
          selectedResource={selectedResource}
        />
      );
    case "hugging_face":
      return (
        <AddHuggingFaceData
          setSelectedResource={setSelectedResource}
          selectedResource={selectedResource}
        />
      );
    default:
      return null;
  }
};

export { SelectedPluginType };
