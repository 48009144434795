import { Box, Typography, useTheme } from "@mui/material";
import { INavbarIconVariant, NavbarIcon } from "../../../atoms/navbar/Icon";

type PluginConfigureModalHeaderProps = {
  title: string;
  icon: INavbarIconVariant;
  active?: boolean;
  webhook?: boolean;
};

const PluginConfigureModalHeader = ({
  title,
  icon,
  active,
  webhook,
}: PluginConfigureModalHeaderProps) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        gap: "13.5px",
      }}
    >
      <NavbarIcon
        variant={icon}
        sx={{
          width: "50",
          height: "50",
        }}
      />
      <Box
        sx={{
          display: "flex",
          gap: "5px",
        }}
      >
        <Typography variant="h2">{title}</Typography>
        {active && (
          <Typography
            variant="h2"
            color={
              active ? theme.palette.custom.greenTypography : theme.palette.custom.grayTypography
            }
          >
            {active ? "(Active)" : "(Inactive)"}
          </Typography>
        )}
        {webhook && (
          <Typography
            variant="h2"
            color={
              active ? theme.palette.custom.greenTypography : theme.palette.custom.grayTypography
            }
          >
            (Webhook Configuration)
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export { PluginConfigureModalHeader };
