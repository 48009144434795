import { Box, Stack, useTheme } from "@mui/material";
import { ScreenSmallerThen } from "../../utilities/UIHelper";
import { RectangularLoader } from "../../molecules/skeleton-loader/RectangularLoader";
import { APITableLoader } from "../../organisms/skeleton-loaders/APITableLoader";
import { IColumnVisibility } from "../../molecules/ColumnVisibility";
import { User } from "../../models/types";
import SecondaryBox from "../../atoms/box/SecondaryBox";

export const OrganizationUsersPageLoader = () => {
  const isSmallScreen = ScreenSmallerThen();
  const pageSize = isSmallScreen ? "unset" : "calc(80vh - 56px)";
  const theme = useTheme();

  const columnsVisibilityRegistered: IColumnVisibility<User>[] = [
    {
      field: "name",
      headerName: "Name",
      visible: true,
      columnMinWidth: 300,
    },
    {
      field: "email",
      headerName: "Email",
      visible: true,
      columnMaxWidth: 500,
      columnMinWidth: 300,
    },
    {
      field: "date_joined",
      headerName: "Joined on",
      visible: true,
      columnMaxWidth: 140,
      columnMinWidth: 110,
    },
    {
      field: "permissions",
      headerName: "Permissions",
      visible: true,
      columnMaxWidth: 200,
      columnMinWidth: 150,
    },
    {
      field: "is_active",
      headerName: "Status",
      visible: true,
      columnMaxWidth: 200,
      columnMinWidth: 150,
    },
  ];

  const columnsVisibilityInvited: IColumnVisibility<User>[] = [
    {
      field: "name",
      headerName: "Name",
      visible: true,
      columnMinWidth: 300,
    },
    {
      field: "email",
      headerName: "Email",
      visible: true,
      columnMaxWidth: 500,
      columnMinWidth: 300,
    },
    {
      field: "invited_on",
      headerName: "Invited On",
      visible: true,
      columnMaxWidth: 140,
      columnMinWidth: 110,
    },
    {
      field: "is_invited",
      headerName: "Re-send invitation",
      visible: true,
      columnMaxWidth: 145,
      columnMinWidth: 145,
    },
    {
      field: "is_active",
      headerName: "Cancel invitation",
      visible: true,
      columnMaxWidth: 132,
      columnMinWidth: 132,
    },
  ];
  return (
    <Box width="100%" display="flex" flexDirection="column" gap="10px">
      <Box
        display="flex"
        flexDirection="column"
        flexGrow={1}
        width="100%"
        border={`1px solid ${theme.palette.custom.primaryBorder}`}
        borderRadius="6px"
        bgcolor={theme.palette.custom.primaryBackground}
      >
        <Box width="100%" padding="10px">
          <RectangularLoader height="17px" width="108px" />
        </Box>
        <APITableLoader columnsVisibility={columnsVisibilityRegistered} secondComponent={true} />
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        flexGrow={1}
        width="100%"
        border={`1px solid ${theme.palette.custom.primaryBorder}`}
        borderRadius="6px"
        bgcolor={theme.palette.custom.primaryBackground}
      >
        <Box width="100%" padding="10px">
          <RectangularLoader height="17px" width="108px" />
        </Box>
        <APITableLoader columnsVisibility={columnsVisibilityInvited} secondComponent={true} />
      </Box>
    </Box>
  );
};
