import { Box, Typography, useTheme } from "@mui/material";

type ColoredBadgeProps = {
  name?: string;
  value: string | Number;
  color: any;
  onClick?: () => void;
};

export const ColoredBadge = ({ name, value, color, onClick }: ColoredBadgeProps) => {
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === "dark";
  return (
    <Box
      padding="4px"
      borderRadius="3px"
      alignItems="center"
      onClick={() => onClick && onClick()}
      display="flex"
      flexDirection="row"
      sx={{
        cursor: "pointer",
        background: isDarkMode ? `${color.typography}50` : color.background,
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
      }}
      gap="8px"
    >
      {name && (
        <Typography variant="h5" color={isDarkMode ? color.background : color.typography}>
          {name}
        </Typography>
      )}
      <Box
        padding="4px 8px"
        borderRadius="1.5px"
        alignItems="center"
        display="flex"
        bgcolor={isDarkMode ? color.background : color.typography}
        gap="8px"
      >
        <Typography variant="h5" color={isDarkMode ? color.typography : "#FFFFFF"}>
          {value.toString()}
        </Typography>
      </Box>
    </Box>
  );
};
