import { Box, useTheme } from "@mui/material";
import { AxiosResponse } from "axios";
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { NavbarIcon } from "../../atoms/navbar/Icon";
import { ConfirmationModal } from "../../components/ConfirmationModal";
import { CreatePolicyOption } from "../../components/CreatePolicyOption";
import { openSnackbar } from "../../context/SnackbarContext";
import { useQuestionnaireAnswers } from "../../hooks/useAnswers";
import { useAssets } from "../../hooks/useAssets";
import { useAuth } from "../../hooks/useAuth";
import { usePlugins } from "../../hooks/usePlugins";
import { PartialUser, PluginData, S3Object } from "../../models/types";
import { DocumentationFiles } from "../../molecules/DocumentationFiles";
import { addAsset, editAsset } from "../../services/DataService";
import { addFile } from "../../services/FileService";
import { QueryKey } from "../../state/QueryStore";
import { DOCUMENTATION_ASSET_TYPE_ID, downloadFile } from "../../utilities/UIHelper";
import { AddPluginDataModal } from "../modals/AddPluginDataModal";
import { UploadFile } from "../modals/assets/UploadFile";
import { PluginConfigureModal } from "../modals/integrations/PluginConfigureModal";

type DocumentationProps = {
  value: string;
  onChange: (newVal: any) => void;
  relatedObjectId: string;
  relatedObjectName: string;
  setCustomTitle: (customTitle: string) => void;
};

type Document = {
  id: string;
  type: string;
  value: any;
  file_name: string;
  file_size: number;
  file_type: string;
  recurrence_start?: any;
  name: string;
  description: string | null;
  updated_by: PartialUser;
  updated_on: string;
};

export const DocumentationCustomField = forwardRef((props: DocumentationProps, ref) => {
  const { value, onChange, relatedObjectId, setCustomTitle, relatedObjectName } = props;

  const theme = useTheme();
  const [docs, setDocs] = useState<string[]>([]);
  const [currentDoc, setCurrentDoc] = useState<string>(value);
  const [openUploadFile, setOpenUploadFile] = useState(false);
  const [openAddFromPlugin, setOpenAddFromPlugin] = useState(false);
  const [removedDocument, setRemovedDocument] = useState<Document | null>(null);
  const [editDocument, setEditDocument] = useState<Document | null>(null);
  const assetName = `${relatedObjectName} ${currentDoc}`;
  const { user } = useAuth();
  const { data: assetResponse } = useAssets({
    name: [assetName],
    "metadata_related_use_case[]": [relatedObjectId],
  });

  const hasAssetCreated =
    assetResponse && assetResponse.results && assetResponse.results.length > 0;
  const assetDetails = hasAssetCreated ? assetResponse.results[0] : null;
  const assetId = hasAssetCreated ? assetDetails?.id : null;
  const documents = hasAssetCreated ? assetDetails?.metadata?.documents ?? [] : [];
  const { data: allPlugins, isLoading: isLoadingPlugins } = usePlugins({});
  const [selectedPlugin, setSelectedPlugin] = useState<PluginData | null>(null);

  const { data: documentationTypeResponse } = useQuestionnaireAnswers({
    question: "use_case_documentation_options",
    "related_object_id[]": [relatedObjectId],
  });

  const onViewDocument = async (document: any) => {
    if (document.type === "file") {
      if (
        document.file_type.toString().includes("pdf") ||
        document.file_type.toString().includes("word")
      ) {
        downloadFile(document.value, document.file_name);
        return;
      }
      window.open(document.value, "_blank");
    }
    if (document.type === "plugin-data") {
      window.open(document.value, "_blank");
    }
  };

  useEffect(() => {
    if (
      documentationTypeResponse &&
      documentationTypeResponse.results &&
      documentationTypeResponse.results.length > 0
    ) {
      const options = JSON.parse(documentationTypeResponse.results[0].answer);
      setDocs(options);
      if (options.length > 0) {
        setCurrentDoc(options[0]);
        setCustomTitle(`${options[0]}? (1/${options.length})`);
      } else {
        setCustomTitle("Documentation?");
      }
    }
  }, [documentationTypeResponse]);

  useImperativeHandle(ref, () => ({
    validate: () => {
      if (currentDoc !== docs[docs.length - 1]) {
        const nextDoc = docs[docs.indexOf(currentDoc) + 1];
        setCustomTitle(`${nextDoc}? (${docs.indexOf(nextDoc) + 1}/${docs.length})`);
        setCurrentDoc(nextDoc);
        return false;
      }
      return true;
    },
  }));

  useEffect(() => {
    if (assetId && !JSON.parse(value).some((a: string) => a === assetId)) {
      onChange(JSON.stringify([...JSON.parse(value), assetId]));
    }
  }, [assetId]);
  const onAddPluginData = async (
    pluginData: Exclude<S3Object, "id">,
    name?: string,
    description?: string,
    recurrenceStart?: Date | null
  ) => {
    try {
      let newAssetID = assetId;
      if (editDocument) {
        documents.forEach((document: any) => {
          if (document.id === editDocument.id) {
            document.value = pluginData.url;
            document.plugin_type = pluginData.type;
            document.file_name = pluginData.name.split("/")[pluginData.name.split("/").length - 1];
            document.file_size = pluginData.size;
            document.recurrence_start = recurrenceStart;
            document.name = name;
            document.description = description;
            document.updated_by = {
              id: user?.id,
              name: user?.name,
            };
            document.updated_on = new Date().toISOString();
          }
        });
      } else {
        if (hasAssetCreated) {
          newAssetID = assetId;
        } else {
          const { data: createdAsset } = await addAsset({
            name: assetName,
            type: DOCUMENTATION_ASSET_TYPE_ID,
            description: "",
            recurrence: 10000,
            status: "DRAFT",
            metadata: {
              related_use_case: relatedObjectId,
            },
          });
          newAssetID = createdAsset.id;
        }
        if (!newAssetID) {
          openSnackbar("Failed to create asset", "error");
          return;
        }
        documents.push({
          id: uuidv4(),
          type: "plugin-data",
          value: pluginData.url,
          plugin_type: pluginData.type,
          file_name: pluginData.name.split("/")[pluginData.name.split("/").length - 1],
          file_size: pluginData.size,
          recurrence_start: recurrenceStart,
          name: name,
          description: description,
          updated_by: {
            id: user?.id,
            name: user?.name,
          },
          updated_on: new Date().toISOString(),
        });
      }
      if (newAssetID) {
        await editAsset(newAssetID, {
          metadata: {
            related_use_case: relatedObjectId,
            asset_type: "documentation",
            documents: documents,
          },
        });
      }
    } catch {
      openSnackbar("Failed to add plugin data", "error");
    } finally {
      setOpenAddFromPlugin(false);
      setEditDocument(null);
    }
  };
  const onUploadFile = async (data: {
    file: File;
    name: string;
    description?: string;
    recurrenceStart?: Date | null;
  }) => {
    try {
      let file: AxiosResponse<any, any>;
      let newAssetID = assetId;
      if (editDocument && assetId) {
        if (data.file.name !== editDocument?.file_name) {
          file = await addFile(assetId, data.file, QueryKey.Asset);
        }
        documents.forEach((document: any) => {
          if (document.id === editDocument.id) {
            document.value = file?.data.file_object ?? editDocument.value;
            document.file_name = file?.data.filename ?? editDocument.file_name;
            document.file_size = file?.data.filesize ?? editDocument.file_size;
            document.file_type = data.file.type ?? editDocument.file_type;
            document.recurrence_start = data.recurrenceStart;
            document.name = data.name;
            document.description = data.description;
            document.updated_by = {
              id: user?.id,
              name: user?.name,
            };
            document.updated_on = new Date().toISOString();
          }
        });
      } else {
        if (hasAssetCreated) {
          newAssetID = assetId;
        } else {
          const { data: createdAsset } = await addAsset({
            name: assetName,
            type: DOCUMENTATION_ASSET_TYPE_ID,
            description: "",
            recurrence: 10000,
            status: "DRAFT",
            metadata: {
              related_use_case: relatedObjectId,
            },
          });
          newAssetID = createdAsset.id;
        }
        if (!newAssetID) {
          openSnackbar("Failed to create asset", "error");
          return;
        }
        file = await addFile(newAssetID, data.file, QueryKey.Asset);
        documents.push({
          id: file.data.id,
          type: "file",
          value: file?.data.file_object,
          file_name: file?.data.filename,
          file_size: file?.data.filesize,
          file_type: data.file.type,
          recurrence_start: data.recurrenceStart,
          name: data.name,
          description: data.description,
          updated_by: {
            id: user?.id,
            name: user?.name,
          },
          updated_on: new Date().toISOString(),
        });
      }
      if (newAssetID) {
        await editAsset(newAssetID, {
          metadata: {
            related_use_case: relatedObjectId,
            asset_type: "documentation",
            documents: documents,
          },
        });
      }
    } catch (e) {
      console.log("error::", e);
      openSnackbar("Failed to upload file", "error");
    } finally {
      setOpenUploadFile(false);
      setEditDocument(null);
    }
  };

  const onRemoveDocument = async () => {
    if (!assetId) {
      return;
    }
    try {
      const newDocuments = documents.filter((document: any) => document.id !== removedDocument?.id);
      await editAsset(assetId, {
        metadata: {
          asset_type: "documentation",
          documents: newDocuments,
        },
      });
      setRemovedDocument(null);
    } catch {
      openSnackbar("Failed to remove document", "error");
    }
  };

  return (
    <Box display="flex" flexDirection="column" justifyContent="flex-start" gap="10px">
      {selectedPlugin && (
        <PluginConfigureModal
          allPlugins={allPlugins ?? []}
          open={!!selectedPlugin}
          onClose={() => {
            setSelectedPlugin(null);
          }}
          setPlugin={(plugin: PluginData) => setSelectedPlugin(plugin)}
          plugin={selectedPlugin}
          closeOnOauthRedirect={true}
        />
      )}
      <UploadFile
        template_version_id={null}
        document={editDocument}
        open={openUploadFile || (!!editDocument && editDocument.type === "file")}
        onClose={() => {
          setOpenUploadFile(false);
          setEditDocument(null);
        }}
        onUpload={onUploadFile}
        assetType="documentation"
      />
      <ConfirmationModal
        open={!!removedDocument}
        description={`Are you sure you want to remove Document ${removedDocument?.name}? Once it's removed it's gone for good.`}
        title={"Remove Document"}
        descriptionVariant="body2"
        onClose={() => {
          setRemovedDocument(null);
        }}
        onAccept={onRemoveDocument}
        acceptText="Yes, remove document"
      />
      <AddPluginDataModal
        open={openAddFromPlugin || (!!editDocument && editDocument.type === "plugin-data")}
        onClose={() => {
          setOpenAddFromPlugin(false);
          setEditDocument(null);
        }}
        onSave={onAddPluginData}
        assetType="documentation"
        recurrence={assetDetails?.recurrence}
        document={editDocument}
        setSelectedPlugin={setSelectedPlugin}
      />
      <CreatePolicyOption
        name="Upload a file"
        description="Supported files: .pdf & .docx up to 10MB."
        fullHeight={false}
        icon={
          <NavbarIcon
            variant="upload"
            sx={{
              width: "30px",
              height: "30px",
            }}
            color={theme.palette.custom.hyperlink}
          />
        }
        onClick={() => setOpenUploadFile(true)}
      />
      <CreatePolicyOption
        name="Add From Plugin"
        description="Sync from Sharepoint, Google Drive & more."
        fullHeight={false}
        icon={
          <NavbarIcon
            variant="resources"
            sx={{
              width: "60px",
              height: "60px",
            }}
            color={theme.palette.custom.hyperlink}
          />
        }
        onClick={() => setOpenAddFromPlugin(true)}
      />
      {assetDetails && (
        <DocumentationFiles
          showTitle={false}
          asset={assetDetails}
          showAddDocumentation={false}
          onDelete={(document: any) => {
            setRemovedDocument(document);
          }}
          onRequestChanges={() => {}}
          onEdit={(document) => setEditDocument(document)}
          onSeeDetails={(document: any) => {
            // setOpenSeeDocumentDetails(true);
            // setSeeDocument(document);
          }}
          onView={onViewDocument}
          rowsPerPage={8}
        />
      )}
    </Box>
  );
});
