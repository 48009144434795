import { Box, useTheme } from "@mui/material";
import { PageDetailLayout } from "../../components/layout/PageDetailLayout";
import SecondaryBox from "../../atoms/box/SecondaryBox";
import { DetailsLoader } from "../../organisms/skeleton-loaders/DetailsLoader";
import { APITableLoader } from "../../organisms/skeleton-loaders/APITableLoader";
import { IColumnVisibility } from "../../molecules/ColumnVisibility";
import { ChildrenBoxLoader } from "../../organisms/skeleton-loaders/ChildrenBoxLoader";
import { RectangularLoader } from "../../molecules/skeleton-loader/RectangularLoader";
import { Role, ExecutionTask } from "../../models/types";

export const ActionDetailsLoader = () => {
  const theme = useTheme();

  const rolesTableColumnVisibility: IColumnVisibility<Role>[] = [
    {
      field: "role_num",
      headerName: "Role",
      visible: true,
    },
    { field: "name", headerName: "Name", visible: true },
    {
      field: "updated_by",
      headerName: "Updated By",
      visible: true,
    },
    {
      field: "updated_on",
      headerName: "Updated On",
      visible: true,
    },
    {
      field: "id",
      headerName: "Remove",
      visible: true,
    },
  ];

  const tasksTableColumnVisibility: IColumnVisibility<ExecutionTask>[] = [
    {
      field: "action_type",
      headerName: "Type",
      visible: true,
    },
    {
      field: "task_num",
      headerName: "Task",
      visible: true,
    },
    {
      field: "parent_object",
      headerName: "Execution",
      visible: true,
    },
    {
      field: "due_date",
      headerName: "Due Date",
      visible: true,
    },
  ];

  const fillBlankSpace = true;

  return (
    <PageDetailLayout
      leftComponent={
        <Box sx={{ width: "100%", display: "flex", flexDirection: "column", gap: "20px" }}>
          <SecondaryBox>
            <DetailsLoader />
          </SecondaryBox>
          <SecondaryBox>
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <RectangularLoader width="200px" height="34px" />
              <RectangularLoader width="64px" height="34px" />
            </Box>
          </SecondaryBox>
          <Box
            sx={{
              maxWidth: "100%",
              width: "100%",
              height: fillBlankSpace ? "100%" : "unset",
              overflow: "visible",
            }}
          >
            <Box height={fillBlankSpace ? "100%" : "unset"}>
              <Box
                paddingBottom={"0px"}
                borderRadius="6px"
                border="1px solid"
                minHeight="100%"
                maxHeight="100%"
                overflow="auto"
                borderColor={theme.palette.custom.primaryBorder}
                bgcolor={theme.palette.custom.primaryBackground}
                sx={{ borderTopRightRadius: "0px", borderTopLeftRadius: "0px" }}
              >
                <APITableLoader
                  columnsVisibility={rolesTableColumnVisibility}
                  tableSize="small"
                  secondComponent={true}
                  tableSearch={true}
                />
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              maxWidth: "100%",
              width: "100%",
              height: fillBlankSpace ? "100%" : "unset",
              overflow: "visible",
            }}
          >
            <Box height={fillBlankSpace ? "100%" : "unset"}>
              <Box
                paddingBottom={"0px"}
                borderRadius="6px"
                border="1px solid"
                minHeight="100%"
                maxHeight="100%"
                overflow="auto"
                borderColor={theme.palette.custom.primaryBorder}
                bgcolor={theme.palette.custom.primaryBackground}
                sx={{ borderTopRightRadius: "0px", borderTopLeftRadius: "0px" }}
              >
                <APITableLoader
                  columnsVisibility={tasksTableColumnVisibility}
                  tableSize="small"
                  secondComponent={true}
                  tableSearch={true}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      }
      rightComponent={
        <Box display="flex" flexDirection="column" gap="20px">
          {[...Array(4)].map((_, idx) => (
            <SecondaryBox>
              <Box display="flex" width="100%" flexDirection="column" gap="10px">
                <ChildrenBoxLoader />
              </Box>
            </SecondaryBox>
          ))}
        </Box>
      }
    />
  );
};
