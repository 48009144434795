import { Box, Typography } from "@mui/material";
import { useEffect } from "react";
import { SearchableDropdown } from "../../components/SearchableDropdown";
import { useMetricResult, useMetricResults } from "../../hooks/useMetricResults";
import { FieldHandlerProps, MetricResult } from "../../models/types";
import { Awaitable } from "../../utilities/common";
import { getMetricResultValueFormatted } from "../../utilities/UIHelper";
import { BenchmarkBadge } from "../BenchmarkBadge";
import { RectangularLoader } from "../skeleton-loader/RectangularLoader";

export const MetricResultCustomField = (
  props: FieldHandlerProps & {
    search: (searchText: string) => Awaitable<MetricResult[]>;
    setObjectAttributes?: (obj: any) => void;
  }
) => {
  const { value, search, onChange, error, setObjectAttributes, required } = props;
  const { data: metricResult } = useMetricResult(value || "");
  useEffect(() => {
    if (metricResult && setObjectAttributes) {
      setObjectAttributes(metricResult ?? {});
    }
  }, [metricResult]);
  return (
    <SearchableDropdown<MetricResult>
      label="Metric Result"
      required={required}
      error={error}
      value={metricResult || null}
      onChange={(newValue) => (newValue !== null ? onChange(newValue.id) : onChange(null))}
      getOptionLabel={(obj) =>
        `${obj.metric_name?.name ?? ""}- Result-${obj.metric_result_num}: ${obj.value}`
      }
      getOptions={search}
      isOptionEqualToValue={(a, b) => a.id === b.id}
      getSummaryDescription={(obj) => [
        obj.metric_name?.name ?? "",
        `Result-${obj.metric_result_num}: ${obj.value}`,
      ]}
    />
  );
};

export const MetricResultListCustomField = (
  props: FieldHandlerProps & {
    search: (searchText: string) => Awaitable<MetricResult[]>;
    setObjectAttributes?: (obj: any) => void;
  }
) => {
  const { value, search, onChange, error, setObjectAttributes, required } = props;
  const { data: items } = useMetricResults({ id: value });
  useEffect(() => {
    if (items && items.results && items.results.length && setObjectAttributes) {
      setObjectAttributes(items.results[0] ?? {});
    }
  }, [items]);
  return (
    <SearchableDropdown<MetricResult>
      label="Metric Result"
      required={required}
      error={error}
      multiple={true}
      value={value.length === 0 ? [] : items?.results ?? []}
      onChange={(newValue) => newValue != null && onChange(newValue.map((item) => item.id))}
      getOptionLabel={(obj) =>
        `${obj.metric_name?.name ?? ""}- Result-${obj.metric_result_num}: ${obj.value}`
      }
      getOptions={search}
      isOptionEqualToValue={(a, b) => a.id === b.id}
      getSummaryDescription={(obj) => [
        obj.metric_name?.name ?? "",
        `Result-${obj.metric_result_num}: ${obj.value}`,
      ]}
    />
  );
};

type MetricResultListReadOnlyCustomFieldProps = {
  value: string[];
};

export const MetricResultListReadOnlyCustomField = ({
  value,
}: MetricResultListReadOnlyCustomFieldProps) => {
  const { data: metricResults, isLoading } = useMetricResults({ id: value });

  return isLoading ? (
    <RectangularLoader height="20px" width="100%" />
  ) : metricResults?.count && value.length > 0 ? (
    <Box display="flex" gap="5px" flexWrap="wrap">
      {metricResults?.results.map((result) => (
        <BenchmarkBadge
          metricId={result.metric}
          metricName={result.metric_name?.name ?? ""}
          metricResultValue={getMetricResultValueFormatted(result.value)}
          onClick={() => {}}
        />
      ))}
    </Box>
  ) : (
    <Typography variant="body2">No metric results</Typography>
  );
};
