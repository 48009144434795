import { Box, Tooltip, Typography, useTheme } from "@mui/material";

interface IBadgeProps {
  name: string;
  onClick: () => void;
  hyperlink?: string;
  hyperlinkOnClick?: () => void;
  maxWidth?: string;
  color?: string;
  typography?:
    | "h1"
    | "h2"
    | "h3"
    | "h4"
    | "subtitle1"
    | "body1"
    | "button"
    | "body2"
    | "small"
    | "navbar"
    | "navbarSelected"
    | "logo"
    | "code";
  fontSize?: string;
  borderRadius?: string;
  icon?: React.ReactNode;
  description?: string | null;
}

const BadgeOption = ({
  name,
  onClick,
  hyperlink,
  hyperlinkOnClick,
  color,
  typography = "body2",
  fontSize,
  maxWidth = "100%",
  borderRadius = "38px",
  icon,
  description,
}: IBadgeProps) => {
  const theme = useTheme();
  return (
    <Box
      padding={"4.5px 4px"}
      borderRadius={borderRadius}
      border={`1px solid ${theme.palette.custom.primaryBorder}`}
      alignItems="center"
      onClick={() => !hyperlinkOnClick && onClick()}
      display="flex"
      flexDirection="row"
      maxWidth={maxWidth}
      sx={{
        cursor: "pointer",
        background: color ? `${color}80` : theme.palette.custom.secondaryBackground,
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
      }}
    >
      <Tooltip
        leaveDelay={200}
        enterNextDelay={500}
        enterDelay={500}
        title={description ? description : `${name}${hyperlink ? hyperlink : ""}`}
        arrow
        placement="bottom"
        sx={{
          cursor: "pointer",
        }}
      >
        <Box display="flex" flexDirection="row" alignItems="center" gap="2px">
          {icon}
          <Typography
            color={theme.palette.custom.primaryTypography}
            variant={typography}
            fontSize={fontSize}
            onClick={() => hyperlinkOnClick && onClick()}
            sx={{
              maxWidth: maxWidth,
              overflow: "hidden",
              textOverflow: "ellipsis",
              lineHeight: "1",
              ":hover": {
                cursor: "pointer",
              },
            }}
          >
            {`${name}`}
            {hyperlink && (
              <span
                onClick={() => hyperlinkOnClick && hyperlinkOnClick()}
                style={{
                  textDecoration: "underline",
                  color: theme.palette.custom.blueTypography,
                }}
              >
                {hyperlink}
              </span>
            )}
          </Typography>
        </Box>
      </Tooltip>
    </Box>
  );
};

export { BadgeOption };
