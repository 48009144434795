import { Box, useTheme } from "@mui/material";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { InternalMenuItemOption } from "../../molecules/menu/InternalMenuItemOption";
import { SubMenuDisplay } from "../../molecules/navbar/SubMenuDisplay";
import {
  DEFAULT_ICON_HEIGHT,
  DEFAULT_ICON_WIDTH,
  DEFAULT_INTERNAL_BORDER_RADIUS,
  ScreenSmallerThen,
} from "../../utilities/UIHelper";
import { INavbarIconVariant, NavbarIcon } from "../navbar/Icon";
import { NavbarLabel } from "../navbar/Label";

interface INavbarItem {
  label: string;
  variant: INavbarIconVariant;
  selected?: boolean;
  labelVisible?: boolean;
  fullWidth?: boolean;
  iconColor?: string;
  onClick?: () => void;
  subMenuItems?: {
    label: string;
    route: string;
  }[];
  iconSx?: {
    width?: string;
    height?: string;
    padding?: string;
  };
  expanded: boolean;
  strokeWidth?: string;
}

export const NavbarItem = ({
  label,
  variant,
  selected = false,
  labelVisible,
  fullWidth,
  iconColor,
  onClick,
  iconSx,
  subMenuItems,
  expanded,
  strokeWidth,
}: INavbarItem) => {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isSmallScreen = ScreenSmallerThen();
  const hasSubmenuItems = subMenuItems && subMenuItems.length > 0;
  const externalBoxStyle = {
    display: fullWidth ? "flex" : "inline-block",
    cursor: "pointer",
    justifyContent: isSmallScreen ? "center" : "flex-start",
    transition: "width 0.5s ease",
    padding: selected ? "0px 10px 0px 1px" : "0px 10px",
  };

  const internalBoxStyle = {
    "&: hover": {
      backgroundColor: theme.palette.custom.grayHover,
      borderRadius: DEFAULT_INTERNAL_BORDER_RADIUS,
    },
    backgroundColor:
      !hasSubmenuItems && selected
        ? theme.palette.custom.navButtonBackground
        : theme.palette.custom.primaryBackground,
    borderRadius: DEFAULT_INTERNAL_BORDER_RADIUS,
    cursor: "pointer",
    display: "flex",
    flexGrow: 1,
    paddingRight: expanded ? "2px" : 0,
    alignItems: "center",
    justifyContent: labelVisible ? "flex-start" : "center",
    flexDirection: "row",
    transition: "width 0.5s ease",
    maxWidth: "136px",
    textOverflow: "ellipsis",
    overflow: "hidden",
  };

  const iconBoxStyle = {
    width: DEFAULT_ICON_WIDTH,
    height: DEFAULT_ICON_HEIGHT,
  };
  const { pathname } = useLocation();
  const open = Boolean(anchorEl);
  const navigate = useNavigate();
  const [expandedSubMenu, setExpandedSubMenu] = useState<boolean>(false);
  return (
    <Box
      display="flex"
      width="100%"
      flexDirection="column"
      gap="5px"
      onMouseLeave={() => setAnchorEl(null)}
      onMouseEnter={(e: React.MouseEvent<HTMLElement>) => setAnchorEl(e.currentTarget)}
    >
      <Box
        display="flex"
        width="100%"
        flexDirection="row"
        alignItems="center"
        gap={"6px"}
        sx={externalBoxStyle}
        onClick={() =>
          onClick && !hasSubmenuItems ? onClick() : setExpandedSubMenu(!expandedSubMenu)
        }
      >
        {selected && (
          <Box
            height="100%"
            sx={{
              borderTopRightRadius: "6px",
              borderBottomRightRadius: "6px",
            }}
            borderTop={`1px solid ${theme.palette.custom.hyperlink}`}
            borderRight={`3px solid ${theme.palette.custom.hyperlink}`}
          ></Box>
        )}
        <Box sx={{ ...internalBoxStyle }}>
          <Box sx={iconBoxStyle}>
            <NavbarIcon
              variant={variant}
              selected={selected}
              color={selected ? iconColor : theme.palette.custom.gray}
              sx={{ ...iconSx }}
              strokeWidth={strokeWidth}
            />
          </Box>
          {labelVisible && <NavbarLabel selected={selected && !hasSubmenuItems} label={label} />}
          {expanded && hasSubmenuItems && (
            <NavbarIcon
              selected={selected}
              variant={expandedSubMenu ? "chevron-up-sm" : "chevron-down-sm"}
            />
          )}
        </Box>
      </Box>
      {hasSubmenuItems && expanded && expandedSubMenu && (
        <>
          {subMenuItems?.map((item, index) => (
            <Box key={index} padding="0px 10px">
              <InternalMenuItemOption
                typographyPadding="0px 0px 0px 30px "
                typographyVariant="navbar"
                name={item.label}
                onClick={() => {
                  navigate(item.route);
                }}
                hasSubsections={false}
                expanded={false}
                selected={pathname.includes(item.route)}
              />
            </Box>
          ))}
        </>
      )}
      <SubMenuDisplay
        visible={Boolean(!expanded && open && hasSubmenuItems)}
        top={anchorEl?.offsetTop || 0}
        left={(anchorEl?.offsetLeft || 0) + 50}
        title={label}
        subMenuItems={subMenuItems ?? []}
        onClose={() => setAnchorEl(null)}
      />
    </Box>
  );
};
