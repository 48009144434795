import { Box, Button, Typography, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { YesOrNoSelectField } from "./BooleanSelect";
import { addResource, editResource } from "../../services/ResourceService";
import { AddResourceModal } from "../modals/resources/AddResourceModal";
import { ShowModelOrApplicationCodeModal } from "../modals/ShowModelOrApplicationCodeModal";
import { CreateModelYesOrNoModal } from "../modals/CreateModelYesOrNoModal";
import { APITable } from "../APITable";
import { fetchResources, useResources } from "../../hooks/useResources";
import { IColumnVisibility } from "../../molecules/ColumnVisibility";
import { ExternalResourceFetchOptions, PluginData, Resource } from "../../models/types";
import { EmptyTable } from "../../molecules/EmptyTable";
import { TableTextWithEllipsis } from "../../molecules/TableTextWithEllipsis";
import { NavbarIcon, INavbarIconVariant } from "../../atoms/navbar/Icon";
import { DefaultBadge } from "../../atoms/StatusBadge";
import { RectangularLoader } from "../../molecules/skeleton-loader/RectangularLoader";
import queryClient, { QueryKey, useAssetTypesGet } from "../../state/QueryStore";
import { addAsset, editAsset } from "../../services/DataService";
import { getResourceSystemName, isJSON } from "../../utilities/UIHelper";
import { IconButton } from "../../atoms/IconButton";
import { RemoveRelatedObjectConfirmation } from "../modals/compliances/RemoveRelatedObjectConfirmation";
import { PluginConfigureModal } from "../modals/integrations/PluginConfigureModal";
import { usePlugins } from "../../hooks/usePlugins";
import { useQuestionnaireAnswers } from "../../hooks/useAnswers";
import { ConfirmationModal } from "../../components/ConfirmationModal";
import { fetchAsset } from "../../hooks/useAssets";

type RepositoriesProps = {
  value: string;
  onChange: (newVal: any, models?: string) => void;
  objectId: string;
};

export const Repositories = (props: RepositoriesProps) => {
  const { value, onChange, objectId } = props;
  const theme = useTheme();

  const { data: repositoryResponses, isLoading: isLoadingRepositoryResponse } = useResources({
    "metadata_related_use_case[]": [objectId],
  });
  const { data: repositoryTypeResponse, isLoading } = useQuestionnaireAnswers({
    question: "use_case_repositories",
    "related_object_id[]": [objectId],
  });
  const [repositories, setRepositories] = useState<string[]>([]);
  const [connectRepositories, setConnectRepositories] = useState<string>(
    (repositories.length ?? 0) > 0 ? "Yes" : value === "No" ? value : ""
  );
  const { data: allPlugins, isLoading: isLoadingPlugins } = usePlugins({});
  const [showAddResourceModal, setShowAddResourceModal] = useState<boolean>(false);
  const [openShowModelOrApplicationCode, setOpenShowModelOrApplicationCode] =
    useState<boolean>(false);
  const [modelCode, setModelCode] = useState<string>("");
  const [openCreateYesOrNoModal, setOpenCreateYesOrNoModal] = useState<boolean>(false);
  const [createModel, setCreateModel] = useState<string>("");
  const [name, setName] = useState<string>("");
  const { data: assetTypes } = useAssetTypesGet({ fairo_data: true });
  const [resourceId, setResourceId] = useState<string>("");
  const [selectedRepository, setSelectedRepository] = useState<Resource | null>(null);
  const [selectedPlugin, setSelectedPlugin] = useState<PluginData | null>(null);
  const [models, setModels] = useState<string[]>([]);
  const [openConfirmation, setOpenConfirmation] = useState<boolean>(false);
  const [confirmationTitle, setConfirmationTitle] = useState<string>("");
  const [confirmationDescription, setConfirmationDescription] = useState<string>("");
  const [onAccept, setOnAccept] = useState(() => () => {});
  const [acceptText, setAcceptText] = useState<string>("");
  const [onReject, setOnReject] = useState(() => () => {});
  const [rejectText, setRejectText] = useState<string>("");

  const [params, setParams] = useState<ExternalResourceFetchOptions>({});
  const columnsVisibility: IColumnVisibility<Resource>[] = [
    {
      field: "external_id",
      headerName: "Repository Name",
      visible: true,
      columnMinWidth: 200,
      renderer: (resource) => <TableTextWithEllipsis value={resource.external_id} />,
    },
    {
      field: "system",
      headerName: "System",
      visible: true,
      columnMaxWidth: 150,
      columnMinWidth: 100,
      renderer: (resource) => (
        <Box display="flex" alignItems="center" gap="4px">
          <NavbarIcon
            variant={resource.system.replace(/_/g, "-") as INavbarIconVariant}
            sx={{ height: "20px", width: "20px" }}
          />
          <Typography variant="body2" textTransform="capitalize">
            {getResourceSystemName(resource.system)}
          </Typography>
        </Box>
      ),
    },
    {
      field: "mapped_resource",
      headerName: "Fairo Mapped Internal Resource",
      visible: true,
      columnMaxWidth: 300,
      columnMinWidth: 300,
      renderer: (resource) => {
        const hasMappedResource =
          !!resource?.mapped_resource_type && !!resource?.mapped_resource_name;
        if (!hasMappedResource) return null;
        return (
          <DefaultBadge
            value={`${resource.mapped_resource_type.split(".")[1]}-${
              resource?.mapped_resource_name
            }`}
          />
        );
      },
    },
    {
      field: "updated_by",
      headerName: "",
      visible: true,
      columnMaxWidth: 60,
      columnMinWidth: 60,
      renderer: (asset) => (
        <IconButton
          variant="unlink"
          onClick={(e) => {
            e.stopPropagation();
            setSelectedRepository(asset);
          }}
          width="30px"
          height="30px"
          color={theme.palette.custom.redTypography}
        />
      ),
    },
  ];

  const createResourceConfirmation = async (
    plugin: string,
    resourceId: string,
    resourceType: string
  ) => {
    try {
      const resources = await fetchResources({
        external_id: [resourceId],
        system: [plugin],
        resourceType: [resourceType],
      });
      if (resources.results.length > 0) {
        setConfirmationTitle("Resource Exists");
        setConfirmationDescription(
          "The same resoruce exists. Would you like to connect the existing resource or create a new one?"
        );
        setAcceptText("Create New Resource");
        setOnAccept(() => () => onCreate(plugin, resourceId, resourceType));
        setRejectText("Connect Existing Resource");
        setOnReject(
          () => () =>
            onAddResource(
              resources.results[0].id,
              resources.results[0].system,
              resources.results[0].mapped_resource,
              resources.results[0].metadata
            )
        );
        setOpenConfirmation(true);
      } else {
        await onCreate(plugin, resourceId, resourceType);
      }
    } catch {}
  };

  const onAddResource = async (
    resourceId: string,
    plugin: string,
    model: string,
    metadata: any
  ) => {
    try {
      const resource = await editResource(resourceId, {
        metadata: {
          ...(metadata || {}),
          related_use_case: Array.isArray(metadata?.related_use_case)
            ? [...metadata.related_use_case, objectId]
            : [objectId],
        },
      });
    } catch {}
    setResourceId(resourceId);
    setRepositories([...JSON.parse(value), resourceId]);
    onChange(JSON.stringify([...JSON.parse(value), resourceId]));
    queryClient.invalidateQueries([
      QueryKey.Resource,
      {
        "metadata_related_use_case[]": [objectId],
      },
    ]);

    if (plugin === "hugging_face" && model) {
      setOpenConfirmation(false);
      setConfirmationTitle("Associated Model Exists");
      setConfirmationDescription(
        "The resource is associated with a model. Would you like to connect this model to use case?"
      );
      setAcceptText("Connect");
      setOnAccept(() => () => onAddModel(model));
      setRejectText("Cancel");
      setOnReject(() => () => setOpenConfirmation(false));
      setOpenConfirmation(true);
    }

    if (plugin === "hugging_face" && !model) {
      setOpenShowModelOrApplicationCode(true);
    }
  };

  const onCreate = async (plugin: string, resourceId: string, resourceType: string) => {
    setOpenConfirmation(false);
    setName(resourceId);
    try {
      const resource = await addResource({
        external_id: resourceId,
        system: plugin,
        status: "Uncategorized",
        resource_type: resourceType,
      });
      onAddResource(resource.id, plugin, "", resource.metadata);
    } catch {}
  };

  const onCloseAddResourceModal = async () => {
    try {
      const repositories = await fetchResources({ "object_relates_to_id[]": [objectId] });
      if (repositories.results.length === 0) {
        setConnectRepositories("");
      }
      setShowAddResourceModal(false);
    } catch {}
  };

  const onCreateModel = async () => {
    try {
      const { data: model } = await addAsset({
        name: name,
        description: "",
        type: assetTypes?.find((type) => type.name === "Model")?.id ?? "",
        source: "HUGGING FACE",
      });
      if (model) {
        onAddModel(model.id);
      }
      setOpenCreateYesOrNoModal(false);
    } catch {}
  };

  const onAddModel = async (modelId: string) => {
    try {
      const modelDetails = await fetchAsset(modelId);
      const model = await editAsset(modelId, {
        metadata: {
          ...(modelDetails?.metadata || {}),
          related_use_case: Array.isArray(modelDetails?.metadata?.related_use_case)
            ? [...modelDetails.metadata.related_use_case, objectId]
            : [objectId],
        },
      });
    } catch {}
    setModels([...models, modelId]);
    onChange(JSON.stringify(repositories), JSON.stringify([...models, modelId]));
    try {
      await editResource(resourceId, {
        mapped_resource: modelId,
        mapped_resource_type: "assets.Asset",
      });
    } catch {}
    queryClient.invalidateQueries([
      QueryKey.Resource,
      {
        "metadata_related_use_case[]": [objectId],
      },
    ]);
  };

  const onRemove = async () => {
    try {
      await editResource(selectedRepository?.id ?? "", {
        metadata: {
          ...selectedRepository?.metadata,
          related_use_case: Array.isArray(selectedRepository?.metadata?.related_use_case)
            ? selectedRepository?.metadata.related_use_case.filter(
                (useCase: string) => useCase !== objectId
              )
            : [],
        },
      });
      setRepositories(JSON.parse(value).filter((item: string) => item !== selectedRepository?.id));
      onChange(
        JSON.stringify(JSON.parse(value).filter((item: string) => item !== selectedRepository?.id)),
        JSON.stringify(models)
      );
    } catch {}
    queryClient.invalidateQueries([QueryKey.Resource]);
  };

  useEffect(() => {
    if (connectRepositories === "Yes" && (repositories.length ?? 0) === 0) {
      setShowAddResourceModal(true);
    }
  }, [connectRepositories]);

  useEffect(() => {
    if (modelCode === "Yes") {
      setOpenCreateYesOrNoModal(true);
      setOpenShowModelOrApplicationCode(false);
    }
  }, [modelCode]);

  useEffect(() => {
    if (createModel === "Yes") {
      onCreateModel();
    }
  }, [createModel]);

  useEffect(() => {
    if (
      repositoryTypeResponse &&
      repositoryTypeResponse.results &&
      repositoryTypeResponse.results.length > 0
    ) {
      if (isJSON(repositoryTypeResponse.results[0].answer)) {
        const options = JSON.parse(repositoryTypeResponse.results[0].answer);
        setRepositories(options);
      }
    }
  }, [repositoryTypeResponse]);

  useEffect(() => {
    if (
      repositoryResponses &&
      repositoryResponses.results &&
      repositoryResponses.results.length > 0
    ) {
      let options: string[] = [];
      const valueOptions = JSON.parse(value);
      repositoryResponses.results.map((repository) => options.push(repository.id));
      if (Array.isArray(valueOptions)) {
        if (valueOptions.length === 0) {
          setRepositories(options);
          onChange(JSON.stringify(options), JSON.stringify(models));
        }
      }
    }
  }, [repositoryResponses]);

  return (
    <>
      <ConfirmationModal
        open={openConfirmation}
        title={confirmationTitle}
        description={confirmationDescription}
        onAccept={onAccept}
        onClose={() => {}}
        acceptText={acceptText}
        onReject={onReject}
        rejectText={rejectText}
      />
      <AddResourceModal
        open={showAddResourceModal}
        onClose={onCloseAddResourceModal}
        onCreate={createResourceConfirmation}
        setSelectedPlugin={setSelectedPlugin}
      />
      <ShowModelOrApplicationCodeModal
        open={openShowModelOrApplicationCode}
        onClose={() => setOpenShowModelOrApplicationCode(false)}
        modelCode={modelCode}
        setModelCode={setModelCode}
        onNext={() => setOpenShowModelOrApplicationCode(false)}
      />
      <CreateModelYesOrNoModal
        open={openCreateYesOrNoModal}
        onClose={() => setOpenCreateYesOrNoModal(false)}
        createModel={createModel}
        setCreateModel={setCreateModel}
        onNext={() => setOpenCreateYesOrNoModal(false)}
      />
      {selectedRepository && (
        <RemoveRelatedObjectConfirmation
          open={!!selectedRepository}
          onClose={() => setSelectedRepository(null)}
          mappedObjectId={objectId}
          onRemoved={() => onRemove()}
          objectId={selectedRepository.id}
          showErrorSnackbar={false}
        />
      )}
      {selectedPlugin && (
        <PluginConfigureModal
          allPlugins={allPlugins ?? []}
          open={!!selectedPlugin}
          onClose={() => {
            setSelectedPlugin(null);
          }}
          setPlugin={(plugin: PluginData) => setSelectedPlugin(plugin)}
          plugin={selectedPlugin}
          closeOnOauthRedirect={true}
        />
      )}
      {isLoading || isLoadingRepositoryResponse ? (
        <RectangularLoader width="100%" height="100px" />
      ) : repositories.length > 0 ? (
        <APITable
          useGetData={useResources}
          queryParams={{
            ...params,
            "metadata_related_use_case[]": [objectId],
          }}
          columnsVisibility={columnsVisibility}
          secondComponent={
            <Box
              display="flex"
              flexGrow={1}
              gap="10px"
              alignItems="center"
              justifyContent="flex-end"
            >
              <Button onClick={() => setShowAddResourceModal(true)}>Connect</Button>
            </Box>
          }
          emptyTableComponent={
            <EmptyTable
              label="External Resources"
              variant="use-case-registry"
              description={`No Related Resources added yet.`}
              actionLabel="Add"
              action={() => setShowAddResourceModal(true)}
            />
          }
          hideColumnVisibility
          showPagination={false}
        />
      ) : (
        <Box display="flex" flexDirection="column" gap="10px">
          <Typography variant="body2">Do you have any repositories to link?</Typography>
          <YesOrNoSelectField
            value={connectRepositories}
            onChange={setConnectRepositories}
            required
            yesLabel="Yes"
            noLabel="No"
          />
        </Box>
      )}
    </>
  );
};
