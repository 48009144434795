import { PluginData, PluginType } from "../../../models/types";
import { MLFlowAutologgingForm } from "../../../organisms/MlflowAutologging";
import { PluginAuthForm } from "../../form/PluginAuthForm";

type PluginSetupAuthProps = {
  onSetup: (params: any) => Promise<void>;
  plugin: PluginData;
  onChangePlugin: (plugin: PluginType) => void;
  setOauthRedirect?: () => void;
};

const PluginSetupAuth = (props: PluginSetupAuthProps) => {
  const { onSetup, plugin, onChangePlugin, setOauthRedirect } = props;
  return (
    <>
      {(() => {
        switch (plugin.plugin_type) {
          case "mlflow":
            return (
              <MLFlowAutologgingForm
                onChangePlugin={onChangePlugin}
                active={false}
                onSetup={onSetup}
              />
            );
          default:
            return (
              <PluginAuthForm
                plugin={plugin}
                updatePlugin={onSetup}
                setOauthRedirect={setOauthRedirect}
              />
            );
        }
      })()}
    </>
  );
};

export { PluginSetupAuth };
