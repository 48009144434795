import { LoadingButton } from "@mui/lab";
import { Box, Typography } from "@mui/material";
import { useEffect, useRef, useState } from "react";

type ShowMoreProps = {
  description: string;
  maxChar?: number;
  richText?: boolean;
  maxLines?: number;
};

export const ShowMore = (props: ShowMoreProps) => {
  const { description, richText = false, maxLines = 3 } = props;
  const [isExpanded, setIsExpanded] = useState(false);
  const [isOverflow, setIsOverflow] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (containerRef.current) {
      const el = containerRef.current;
      const isContentOverflowing = el.scrollHeight > el.clientHeight;
      setIsOverflow(isContentOverflowing);
    }
  }, [description]);

  const toggleExpanded = () => {
    setIsExpanded((prev) => !prev);
  };

  return (
    <>
      <Box
        ref={containerRef}
        sx={{
          display: "-webkit-box",
          WebkitLineClamp: isExpanded ? "none" : maxLines,
          WebkitBoxOrient: "vertical",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {!richText ? (
          <Typography variant="body2" sx={{ whiteSpace: "pre-line" }}>
            {description}
          </Typography>
        ) : (
          <div
            dangerouslySetInnerHTML={{
              __html: description,
            }}
          />
        )}
      </Box>
      {isOverflow && (
        <Box width="100%" display="flex" justifyContent="flex-end">
          <LoadingButton
            onClick={toggleExpanded}
            sx={{
              padding: "0px",
            }}
          >
            {isExpanded ? "Show Less" : "Show More"}
          </LoadingButton>
        </Box>
      )}
    </>
  );
};
