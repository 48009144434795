import { Box, Typography } from "@mui/material";
import AddPermissionWithUserImage from "../../../assets/guide/databricks/add-permissions-2.png";
import AddPermissionsImage from "../../../assets/guide/databricks/add-permissions.png";
import AddServicePrincipleImage from "../../../assets/guide/databricks/add-service-principle.png";
import CopySecretImage from "../../../assets/guide/databricks/copy-secret.png";
import GenerateSecretImage from "../../../assets/guide/databricks/generate-secret.png";
import LastStepImage from "../../../assets/guide/databricks/last-step.png";
import NavigateWorkspaceImage from "../../../assets/guide/databricks/navigate-workspace.png";
import SelectServicePrinciple from "../../../assets/guide/databricks/select-service-principle.png";
import ServicePrincipleName from "../../../assets/guide/databricks/service-principle-name.png";
import SetupFairoImage from "../../../assets/guide/databricks/setup-fairo.png";
import SetupServicePrincipalImage from "../../../assets/guide/databricks/setup-service-principal.png";
import UserManagementImage from "../../../assets/guide/databricks/user-management.png";

import { SmallModal } from "../../../components/SmallModal";
import { PluginConfigureModalHeader } from "../../../molecules/organization/integrations/PluginConfigureModalHeader";
import { ScreenSmallerThen } from "../../../utilities/UIHelper";
type DatabricksPluginGuideModalProps = {
  open: boolean;
  onClose: () => void;
};

export const DatabricksPluginGuideModal = (props: DatabricksPluginGuideModalProps) => {
  const { open, onClose } = props;
  const isSmallScreen = ScreenSmallerThen();
  return (
    <>
      <SmallModal
        customTitle={
          <PluginConfigureModalHeader
            title="Databricks Integration Guide"
            icon="databricks"
            active={false}
          />
        }
        title="Integrations"
        open={open}
        onClose={() => onClose()}
        size="medium"
        maxHeight={isSmallScreen ? "80vh" : "75vh"}
        isLoading={false}
      >
        <Box
          display="flex"
          flexGrow={1}
          height="100%"
          overflow="auto"
          flexDirection="column"
          gap="15px"
        >
          <Typography variant="body2">1. Login to your Databricks account.</Typography>
          <Typography variant="body2">2. Create a service principal.</Typography>
          <Typography variant="body2" fontWeight={400} paddingLeft={2}>
            ● From the Account Console, select User Management from the leftnav.
          </Typography>
          <Box display="flex" paddingY="5px" width="100%" justifyContent="center">
            <img
              src={UserManagementImage}
              width={isSmallScreen ? "90%" : "50%"}
              alt="databricks user management"
            />
          </Box>
          <Typography variant="body2" fontWeight={400} paddingLeft={2}>
            ● From the Service Principals tab, click on Add service principal.
          </Typography>
          <Box display="flex" paddingY="5px" width="100%" justifyContent="center">
            <img
              src={AddServicePrincipleImage}
              width={isSmallScreen ? "90%" : "50%"}
              alt="add service principal"
            />
          </Box>
          <Typography variant="body2" fontWeight={400} paddingLeft={2}>
            ● Enter a name for the service principal and click on Add.
          </Typography>
          <Box display="flex" paddingY="5px" width="100%" justifyContent="center">
            <img
              src={ServicePrincipleName}
              width={isSmallScreen ? "90%" : "50%"}
              alt="setup service principal"
            />
          </Box>
          <Typography variant="body2">3. Create a service principal secret.</Typography>
          <Typography variant="body2" fontWeight={400} paddingLeft={2}>
            ● Select the service principal you just created.
          </Typography>
          <Box display="flex" paddingY="5px" width="100%" justifyContent="center">
            <img
              src={SelectServicePrinciple}
              width={isSmallScreen ? "90%" : "50%"}
              alt="select service principal"
            />
          </Box>
          <Typography variant="body2" fontWeight={400} paddingLeft={2}>
            ● Click on Generate secret.
          </Typography>
          <Box display="flex" paddingY="5px" width="100%" justifyContent="center">
            <img
              src={GenerateSecretImage}
              width={isSmallScreen ? "90%" : "50%"}
              alt="generate principal secret"
            />
          </Box>
          <Typography variant="body2" fontWeight={400} paddingLeft={2}>
            ● Copy the Client ID and Secret from the pop-up window. The secret will only be revealed
            once during creation.
          </Typography>
          <Box display="flex" paddingY="5px" width="100%" justifyContent="center">
            <img src={CopySecretImage} width={isSmallScreen ? "90%" : "65%"} alt="copy secret" />
          </Box>
          <Typography variant="body2">4. Navigate to your workspace.</Typography>
          <Box display="flex" paddingY="5px" width="100%" justifyContent="center">
            <img
              src={NavigateWorkspaceImage}
              width={isSmallScreen ? "90%" : "65%"}
              alt="navigate to workspace"
            />
          </Box>
          <Typography variant="body2" fontWeight={400} paddingLeft={2}>
            ● From the permissions tab, click on Add permissions.
          </Typography>
          <Box display="flex" paddingY="5px" width="100%" justifyContent="center">
            <img
              src={AddPermissionsImage}
              width={isSmallScreen ? "90%" : "65%"}
              alt="add permissions to principal"
            />
          </Box>
          <Typography variant="body2" fontWeight={400} paddingLeft={2}>
            ● Select the service principal you just created and add it.
          </Typography>
          <Box display="flex" paddingY="5px" width="100%" justifyContent="center">
            <img
              src={AddPermissionWithUserImage}
              width={isSmallScreen ? "90%" : "65%"}
              alt="select service principal permission"
            />
          </Box>
          <Typography variant="body2">
            5. Open your workspace and navigate to Identity and access on the settings menu.
          </Typography>
          <Box display="flex" paddingY="5px" width="100%" justifyContent="center">
            <img
              src={SetupServicePrincipalImage}
              width={isSmallScreen ? "90%" : "90%"}
              alt="open workspace and navigate"
            />
          </Box>
          <Typography variant="body2" fontWeight={400} paddingLeft={2}>
            ● Click on Add service principal.
          </Typography>
          <Typography variant="body2" fontWeight={400} paddingLeft={2}>
            ● Select the principal you just created and click on Add.
          </Typography>
          <Box display="flex" paddingY="5px" width="100%" justifyContent="center">
            <img src={LastStepImage} width={isSmallScreen ? "90%" : "90%"} alt="click on add" />
          </Box>
          <Typography variant="body2">6. Open Fairo Integrations page.</Typography>
          <Typography variant="body2" fontWeight={400} paddingLeft={2}>
            ● Locate Databricks integration and click on Get Started.
          </Typography>
          <Typography variant="body2" fontWeight={400} paddingLeft={2}>
            ● Complete the form with your workspace, the service principal credentials you just
            created and click on Validate.
          </Typography>
          <Box display="flex" paddingY="5px" width="100%" justifyContent="center">
            <img src={SetupFairoImage} width={isSmallScreen ? "90%" : "90%"} alt="setup fairo" />
          </Box>
        </Box>
      </SmallModal>
    </>
  );
};
