import { LoadingButton } from "@mui/lab";
import { Box, Button, Typography } from "@mui/material";
import useTheme from "@mui/material/styles/useTheme";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { NavbarIcon } from "../../atoms/navbar/Icon";
import { SearchableDropdown } from "../../components/SearchableDropdown";
import { SmallModal } from "../../components/SmallModal";
import { openSnackbar } from "../../context/SnackbarContext";
import { usePlugins } from "../../hooks/usePlugins";
import { PluginData, S3Object } from "../../models/types";
import { SelectedPluginType } from "../plugins/SelectedPluginType";
import { CustomAxiosError } from "../../utilities/ErrorResponseHelper";
import { FairoPolicyTemplate } from "../../components/FairoPolicyTemplate";
import { DocumentationFilesDetailsForm } from "../../components/DocumentationFilesDetailsForm";

type AddPluginDataModalProps = {
  /** Should the modal be open? */
  open: boolean;
  /** Callback to close the modal */
  onClose: () => void;
  onSave: (
    resource: Exclude<S3Object, "id">,
    name?: string,
    description?: string,
    recurrenceStart?: Date | null
  ) => Promise<void>;
  assetType?: "policy" | "documentation";
  recurrence?: number;
  document?: any;
  setSelectedPlugin?: (plugin: PluginData | null) => void;
};

export const AddPluginDataModal = (props: AddPluginDataModalProps) => {
  const {
    open,
    onClose,
    onSave,
    assetType,
    recurrence,
    document,
    setSelectedPlugin: _setSelectedPlugin,
  } = props;

  const [loading, setLoading] = useState<boolean>(false);

  const theme = useTheme();
  const innerTextColor = theme.palette.custom.secondaryTypography;
  const { data } = usePlugins({});
  const [selectedPlugin, setSelectedPlugin] = useState<PluginData | null>(null);
  const [selectedResource, setSelectedResource] = useState<S3Object | null>(null);
  const navigate = useNavigate();

  const [name, setName] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [recurrenceStart, setRecurrenceStart] = useState<Date | null>(null);

  const handleSave = async () => {
    try {
      setLoading(true);
      if (!selectedPlugin || !selectedResource) throw new Error("No plugin selected");
      await onSave(
        { ...selectedResource, type: selectedPlugin.plugin_type },
        name,
        description,
        recurrenceStart
      );
      openSnackbar("External Resources added sucessfully", "success");
      onClose();
    } catch (error) {
      if (error instanceof CustomAxiosError) {
        error.showAuditLogSnackbar("Failed to add External Resources");
      }
    } finally {
      setLoading(false);
    }
  };

  const handleSetPlugin = (plugin: PluginData | null) => {
    if (plugin) {
      if (plugin.status !== "Active") {
        if (_setSelectedPlugin) {
          _setSelectedPlugin(plugin);
          return;
        }
        return navigate("/organization/integrations");
      }
      setSelectedPlugin(plugin);
    } else {
      setSelectedPlugin(null);
    }
  };

  // clear resources on change plugin
  useEffect(() => {
    setSelectedResource(null);
  }, [selectedPlugin]);

  useEffect(() => {
    setSelectedPlugin(null);
  }, [open]);

  useEffect(() => {
    if (document) {
      setSelectedPlugin(
        data?.find((plugin) => plugin.plugin_type === document.plugin_type) || null
      );
      setSelectedResource(document);
      setName(document?.name);
      setDescription(document?.description);
      setRecurrenceStart(document?.recurrence_start ? new Date(document?.recurrence_start) : null);
    } else {
      setSelectedPlugin(null);
      setSelectedResource(null);
      setName("");
      setDescription("");
      setRecurrenceStart(null);
    }
  }, [document]);

  return (
    <SmallModal open={open} onClose={onClose} title={"Add Plugin Data"}>
      <Box display="flex" flexDirection="column" gap="10px">
        {assetType === "policy" && (
          <FairoPolicyTemplate
            title="Download Our Template"
            description="Edit it in your favorite tool."
          />
        )}
        <Typography variant="h3" color={innerTextColor}>
          Plugin
        </Typography>
        <SearchableDropdown<PluginData>
          label="Select Plugin"
          required
          error={false}
          value={selectedPlugin || null}
          onChange={handleSetPlugin}
          getOptions={(search: string) => {
            return (
              data?.filter((option) => {
                return (
                  // @todo remove this while adding new plugins with proper content
                  option.plugin_type === "aws" &&
                  (option.plugin_display_name.toLowerCase().includes(search.toLowerCase()) ||
                    option.plugin_description.toLowerCase().includes(search.toLowerCase()))
                );
              }) || []
            );
          }}
          isOptionEqualToValue={(a, b) => a === b}
          renderOption={(props, option) => {
            return (
              <Box
                component="li"
                {...props}
                key={option.id}
                sx={{ cursor: "pointer" }}
                alignItems="center"
                padding="5px"
                display="flex"
                flexDirection="row"
                gap="5px"
                width="100%"
              >
                <Box
                  display="flex"
                  flex={1}
                  flexDirection="row"
                  gap="5px"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Box display="flex" flexDirection="row" gap="5px" alignItems="center" flex={1}>
                    <NavbarIcon
                      variant={option.plugin_logo_url}
                      sx={{ width: "20px", height: "20px", minHeight: "20px", minWidth: "20px" }}
                    />
                    <Typography variant="h4">
                      {option.plugin_display_name}
                      {option.status !== "Active" ? ` (${option.status})` : ""}
                    </Typography>
                  </Box>
                  {option.status !== "Active" && (
                    <Button
                      sx={{ height: "30px" }}
                      onClick={() => navigate("/organization/integrations")}
                    >
                      Set Up Now
                    </Button>
                  )}
                </Box>
              </Box>
            );
          }}
          getOptionLabel={(option) =>
            `${option.plugin_display_name}${
              option.status !== "Active" ? ` (${option.status})` : ""
            }`
          }
        />
        {selectedPlugin && (
          <SelectedPluginType
            type={selectedPlugin.plugin_type}
            setSelectedResource={setSelectedResource}
            selectedResource={selectedResource}
          />
        )}
        {selectedPlugin && selectedResource && assetType === "documentation" && (
          <DocumentationFilesDetailsForm
            name={name}
            setName={setName}
            description={description}
            setDescription={setDescription}
            recurrence={recurrence || 0}
            recurrenceStart={recurrenceStart}
            setRecurrenceStart={setRecurrenceStart}
          />
        )}
        <LoadingButton
          onClick={() => handleSave()}
          variant="contained"
          loading={loading}
          fullWidth={true}
        >
          Add Data
        </LoadingButton>
      </Box>
    </SmallModal>
  );
};
