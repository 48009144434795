import { LoadingButton } from "@mui/lab";
import { Box, Step, StepLabel, Stepper, Typography, useTheme } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { IconButton } from "../../atoms/IconButton";
import { InputOnFocus } from "../../atoms/InputOnFocus";
import { navigateToUseCase, useUseCasePathState } from "../../components/UseCaseNavigator";
import { useQuestionnaires } from "../../hooks/useQuestionnaires";
import { useUseCase } from "../../hooks/useUseCases";
import { CustomAnswer } from "../../models/types";
import ErrorBoundaryWrapper from "../../molecules/ErrorHandling";
import { QuestionnaireProgressBar } from "../../molecules/impact-assessment/QuestionnaireProgressBar";
import { WizardQuestionDisplay } from "../../molecules/impact-assessment/WizardQuestionDisplay";
import { ImpactAssessmentSectionMenuOption } from "../../organisms/impact-assessment/Sidebar/ImpactAssessmentSectionMenuOption";
import { editUseCase } from "../../services/UseCaseService";
import { getDefaultCustomFieldStartValue, ScreenSmallerThen } from "../../utilities/UIHelper";

type QuestionIndexBySection = {
  [key: string]: {
    value: number;
  };
};

export default function QuestionnaireWizard() {
  const location = useLocation();
  const navigate = useNavigate();
  const { data: pathData } = useUseCasePathState(location.state, location.pathname);
  const { useCaseId } = pathData!;
  const { data } = useUseCase(useCaseId);

  const [questionIndex, setQuestionIndex] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const { data: questionnaires, isLoading } = useQuestionnaires({
    "metadata_internal_wizard[]": "USE_CASE",
  });
  const [answerLoading, setAnswersLoading] = useState<boolean>(true);

  const selectedQuestionnaire =
    questionnaires && questionnaires.results && questionnaires.results.length > 0
      ? questionnaires.results[0]
      : null;
  const [sections, setSections] = useState<string[]>([]);
  const questionFieldRef = useRef();
  const [answers, setAnswers] = useState<CustomAnswer[]>([]);
  const theme = useTheme();

  const currentStepsBySection = selectedQuestionnaire?.questions
    .filter(
      (q) => q.navigation_tag === selectedQuestionnaire.questions[questionIndex].navigation_tag
    )
    .map((q) => q.sub_section);
  const [firstQuestionIndexBySection, setFirstQuestionIndexBySection] =
    useState<QuestionIndexBySection>({});
  const [completed, setCompleted] = useState<boolean>(false);

  useEffect(() => {
    if (selectedQuestionnaire) {
      const uniqueSections = Array.from(
        new Set(selectedQuestionnaire.questions.map((q) => q.navigation_tag ?? q.title))
      );
      const sectionIndexes: Record<string, { value: number }> = {};
      selectedQuestionnaire.questions.forEach((item, index) => {
        if (item.navigation_tag) {
          if (!(item.navigation_tag in sectionIndexes)) {
            sectionIndexes[item.navigation_tag] = { value: index };
          }
        }
      });
      setFirstQuestionIndexBySection(sectionIndexes);
      setSections(uniqueSections);
      setAnswers(
        selectedQuestionnaire.questions?.map((question: any) => ({
          questionnaire: selectedQuestionnaire.id,
          question: question?.ref ?? question.title,
          field_type: question.type,
          answer: getDefaultCustomFieldStartValue(question.type),
          status: "DRAFT",
          related_object_id: useCaseId,
          related_object_type: "Use Case",
          metadata: question.metadata,
        }))
      );
      setAnswersLoading(false);
    }
    setCompleted(false);
  }, [selectedQuestionnaire]);

  const handleNext = async () => {
    try {
      setLoading(true);
      setQuestionIndex(questionIndex + 1);
    } catch (e) {
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (value: string | null, models?: string) => {
    // setValueError(false);
    const newAnswers = answers.map((answer, index) => {
      if (index === questionIndex) {
        return {
          ...answer,
          answer: value,
        };
      }
      if (models && answer.question === "use_case_models") {
        return {
          ...answer,
          answer: models,
        };
      }
      return answer;
    });
    setAnswers(newAnswers);
  };

  const onFinish = async () => {
    try {
      if (data) {
        await editUseCase(data.id, { status: "OK" });
        navigateToUseCase({
          navigate,
          useCaseId: data?.id,
          useCaseNumber: data?.use_case_num,
        });
      }
    } catch {}
  };

  const isSmallScreen = ScreenSmallerThen();
  return selectedQuestionnaire && data && !answerLoading ? (
    <ErrorBoundaryWrapper>
      <Box display="flex" flexDirection="column" minHeight="100vh">
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          bgcolor={theme.palette.custom.white}
          alignItems="center"
          padding="20px"
        >
          <Box
            display="flex"
            flexDirection="row"
            alignItems="flex-start"
            flexGrow={1}
            justifyContent="flex-start"
          >
            <IconButton
              variant="left-arrow"
              height="20px"
              width="20px"
              color={theme.palette.custom.secondaryTypography}
              onClick={() =>
                navigateToUseCase({
                  navigate,
                  useCaseId: data?.id,
                  useCaseNumber: data?.use_case_num,
                })
              }
            />
            <Box display="flex" flexDirection="column" flexGrow={1} gap="5px">
              <InputOnFocus
                value={data.name}
                onChange={async (newName: string) => {
                  await editUseCase(data.id, { name: newName });
                }}
              />
              <Typography variant="body2">Use Case-{data?.use_case_num}</Typography>
            </Box>
          </Box>
          <Box display="flex" flexDirection="row" gap="20px" alignItems="center">
            <Typography
              variant="body2"
              sx={{
                textDecoration: "underline",
              }}
            >
              {loading ? "Saving..." : "Saved"}
            </Typography>
          </Box>
        </Box>
        <QuestionnaireProgressBar
          rounded={false}
          questionIndex={questionIndex}
          questionnaireLength={selectedQuestionnaire?.questions.length}
        />
        <Box display="flex" flexDirection="row" flexGrow={1}>
          {!isSmallScreen && (
            <Box
              display="flex"
              maxWidth="235px"
              minWidth="235px"
              minHeight="100%"
              flexGrow={1}
              padding="20px"
              gap="5px"
              borderRight={`1px solid ${theme.palette.custom.secondaryBorder}`}
              flexDirection="column"
              bgcolor={theme.palette.custom.white}
            >
              {sections?.map((question, index) => (
                <ImpactAssessmentSectionMenuOption
                  // variant="potential-risks"
                  key={index}
                  name={question}
                  onClick={() => setQuestionIndex(firstQuestionIndexBySection[question].value)}
                  iconColor={
                    question === selectedQuestionnaire.questions[questionIndex].navigation_tag
                      ? theme.palette.custom.navbarIcon
                      : undefined
                  }
                  strokeWidth="1.5"
                  selected={
                    question === selectedQuestionnaire.questions[questionIndex].navigation_tag
                  }
                />
              ))}
            </Box>
          )}
          <Box
            display="flex"
            flexDirection="column"
            gap="16px"
            flexGrow={1}
            justifyContent="center"
            alignItems="center"
            padding="20px"
            width="100vw"
          >
            {completed ? (
              <Box
                height="100%"
                maxWidth="800px"
                display="flex"
                flexDirection="column"
                gap="24px"
                justifyContent="start"
                paddingTop="80px"
              >
                <Box display="flex" flexDirection="column" gap="4px">
                  <Typography variant="h1">Finish Use Case Setup</Typography>
                  <Typography variant="body2">
                    You're all set! Based on your approvals, we'll proceed with analyzing the
                    selected resources to enhance your use case. You can review or update these
                    approvals anytime.
                  </Typography>
                </Box>
                <Box display="flex">
                  <LoadingButton variant="contained" onClick={onFinish}>
                    Finish
                  </LoadingButton>
                </Box>
              </Box>
            ) : (
              <Box
                display="flex"
                flexDirection={"column"}
                gap="16px"
                flexGrow={1}
                width="100%"
                alignItems="center"
              >
                <Box display="flex" flexDirection="row" justifyContent="center">
                  <Stepper
                    activeStep={currentStepsBySection?.indexOf(
                      selectedQuestionnaire.questions[questionIndex].sub_section
                    )}
                  >
                    {currentStepsBySection?.map((label, index) => {
                      return (
                        <Step
                          active={
                            label === selectedQuestionnaire.questions[questionIndex].sub_section
                          }
                          key={index}
                        >
                          <StepLabel
                            sx={{
                              textAlign: "center",
                            }}
                            color={theme.palette.custom.blueTypography}
                          >
                            {label}
                          </StepLabel>
                        </Step>
                      );
                    })}
                  </Stepper>
                </Box>
                <Box
                  display="flex"
                  flexDirection={"column"}
                  gap="16px"
                  flexGrow={1}
                  width="100%"
                  maxWidth="800px"
                >
                  <WizardQuestionDisplay
                    questionnaire={selectedQuestionnaire}
                    question={selectedQuestionnaire?.questions[questionIndex]}
                    questionIndex={questionIndex}
                    answer={answers[questionIndex]}
                    onChange={handleChange}
                    valueError={false}
                    relatedObjectId={useCaseId}
                    handleNext={() => {
                      if (questionIndex === selectedQuestionnaire?.questions.length - 1) {
                        setCompleted(true);
                      } else {
                        // @TODO remove this hardcode
                        if (
                          selectedQuestionnaire?.questions[questionIndex].ref ===
                            "use_case_documentation_options" &&
                          answers[questionIndex].answer === JSON.stringify([])
                        ) {
                          return setQuestionIndex(questionIndex + 2);
                        }
                        setQuestionIndex(questionIndex + 1);
                      }
                    }}
                    loading={loading}
                    handlePrevious={() => setQuestionIndex(questionIndex - 1)}
                    relatedObjectName={data.name}
                  />
                </Box>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </ErrorBoundaryWrapper>
  ) : null;
}
