import { Box, Theme, Typography, useTheme } from "@mui/material";
import React from "react";
import { INavbarIconVariant, NavbarIcon } from "./navbar/Icon";

export type StatusOption =
  | "PENDING"
  | "DUE SOON"
  | "OK"
  | "OVERDUE"
  | "KICKOFF"
  | "DEACTIVATED"
  | "PASSED"
  | "FAILED"
  | "NOT ADDED"
  | "NEEDS REMEDIATION"
  | "NO POLICY"
  | "DRAFT"
  | "NEEDS APPROVAL"
  | "ACTION REQUIRED"
  | "APPROVED"
  | "EXCLUDED"
  | "NO DOCUMENT"
  | "KICKOFF"
  | "IN PROGRESS"
  | "UNDER REVIEW"
  | "FINALIZED"
  | "DISABLED"
  | "ARCHIVED"
  | "N/A"
  | "COMPLETED"
  | "ACTIVE"
  | "INACTIVE"
  | "ERROR"
  | "SUCCESS"
  | "NOT PROCESSED"
  | "SYNCING"
  | "UNCATEGORIZED"
  | "ACTIVE"
  | "INVITED"
  | "NEW VERSION REQUIRED"
  // test status options
  | "UNTESTED"
  | "BLOCKED"
  | "RETEST"
  | "CANCELED"
  | "READY"
  | "ACCEPTED"
  | "FLAGGED"
  | "EXCLUDED"
  | "NOT READY"
  | "ALLOW"
  | "DENY"
  | "PROVISIONAL RISK"
  | "JSON"
  | "LIST";

type StatusBadgeProps = {
  status: StatusOption;
  justifyContent?: string;
  onClick?: () => void;
};

export const StatusColor = ({
  status,
}: StatusBadgeProps): {
  color: string;
  bgcolor: string;
} => {
  const theme = useTheme();
  switch (status) {
    case "PENDING":
    case "DEACTIVATED":
    case "NOT ADDED":
    case "NO POLICY":
    case "EXCLUDED":
    case "NO DOCUMENT":
    case "KICKOFF":
    case "DISABLED":
    case "DRAFT":
    case "ARCHIVED":
    case "N/A":
    case "NOT PROCESSED":
    case "UNCATEGORIZED":
    case "INVITED":
    case "UNTESTED":
    case "NOT READY":
      return {
        bgcolor: theme.palette.custom.secondaryStatusBackgroundColor,
        color: theme.palette.custom.secondaryStatusColor,
      };
    case "DUE SOON":
    case "NEEDS APPROVAL":
    case "ACTION REQUIRED":
    case "UNDER REVIEW":
    case "CANCELED":
    case "RETEST":
    case "FLAGGED":
    case "PROVISIONAL RISK":
      return {
        color: theme.palette.custom.warningStatusColor,
        bgcolor: theme.palette.custom.warningStatusBackgroundColor,
      };
    case "OVERDUE":
    case "FAILED":
    case "NEEDS REMEDIATION":
    case "INACTIVE":
    case "ERROR":
    case "NEW VERSION REQUIRED":
    case "DENY":
      return {
        color: theme.palette.custom.errorStatusColor,
        bgcolor: theme.palette.custom.errorStatusBackgroundColor,
      };
    case "OK":
    case "PASSED":
    case "APPROVED":
    case "COMPLETED":
    case "FINALIZED":
    case "SUCCESS":
    case "ACTIVE":
    case "ACCEPTED":
    case "ALLOW":
    case "JSON":
    case "LIST":
      return {
        color: theme.palette.custom.successStatusColor,
        bgcolor: theme.palette.custom.successStatusBackgroundColor,
      };
    case "IN PROGRESS":
    case "SYNCING":
    case "BLOCKED":
    case "READY":
      return {
        color: theme.palette.custom.primaryStatusColor,
        bgcolor: theme.palette.custom.primaryStatusBackgroundColor,
      };
    default:
      const _: never = status;
      return {
        bgcolor: theme.palette.custom.secondaryStatusBackgroundColor,
        color: theme.palette.custom.secondaryStatusColor,
      };
  }
};

export const StatusBadge = ({ status, justifyContent, onClick }: StatusBadgeProps) => {
  const { color, bgcolor } = StatusColor({ status });
  return (
    <Box
      display="flex"
      width="100%"
      justifyContent={justifyContent}
      onClick={(event) => {
        if (onClick) {
          event.stopPropagation();
          onClick();
        }
      }}
      sx={{
        cursor: onClick ? "pointer" : "default",
      }}
    >
      <Box display="inline-block" borderRadius="3px" padding="2.5px 4px" bgcolor={`${bgcolor}80`}>
        <Typography variant="h5" color={color}>
          {status}
        </Typography>
      </Box>
    </Box>
  );
};

export const DefaultBadge = ({
  icon,
  value,
  onClick,
  renderIcon,
  textTransform = "uppercase",
  expanded,
}: {
  onClick?: () => void;
  renderIcon?: () => React.ReactNode;
  icon?: INavbarIconVariant;
  value: string;
  textTransform?: "uppercase" | "capitalize" | "lowercase" | "none";
  expanded?: boolean;
}) => {
  const theme = useTheme();
  const hasIcon = icon || renderIcon;
  return value !== "" ? (
    <Box display="flex" maxWidth="100%">
      <Box
        display={hasIcon ? "inline-flex" : "inline-block"}
        alignItems={hasIcon ? "center" : "flex-start"}
        gap="5px"
        borderRadius="3px"
        padding="2.5px 4px"
        whiteSpace="nowrap"
        overflow="hidden"
        onClick={() => onClick && onClick()}
        sx={{
          ...(onClick && { cursor: "pointer" }),
        }}
        bgcolor={theme.palette.custom.defaultStatusBackgroundColor}
      >
        {renderIcon && <>{renderIcon()}</>}
        {icon && (
          <NavbarIcon
            variant={icon}
            color="none"
            sx={{
              width: "12px",
              height: "12px",
              minHeight: "12px",
              minWidth: "12px",
            }}
          />
        )}
        <Typography
          textTransform={textTransform}
          variant="h5"
          overflow="hidden"
          textOverflow={expanded ? undefined : "ellipsis"}
          whiteSpace={expanded ? "wrap" : undefined}
          color={theme.palette.custom.defaultStatusColor}
        >
          {value}
        </Typography>
      </Box>
    </Box>
  ) : (
    <></>
  );
};

export const getThreeLevelRiskScoreColor = (score: number, theme: Theme) => {
  if (score === 1) {
    return theme.palette.custom.greenTypography;
  } else if (score === 2) {
    return theme.palette.custom.yellowTypography;
  } else {
    return theme.palette.custom.redTypography;
  }
};

export const getFourLevelRiskScoreColor = (score: number, theme: Theme) => {
  if (score === 1) {
    return theme.palette.custom.greenTypography;
  } else if (score === 2) {
    return theme.palette.custom.yellowTypography;
  } else if (score === 3) {
    return theme.palette.custom.errorStatusBackgroundColor;
  } else {
    return theme.palette.custom.redTypography;
  }
};

export const getRiskScoreColor = (score: number, theme: Theme, singleScore?: boolean) => {
  if (singleScore) {
    if (score < 3) {
      return theme.palette.custom.greenTypography;
    } else if (score < 4) {
      return theme.palette.custom.yellowTypography;
    } else {
      return theme.palette.custom.redTypography;
    }
  } else {
    if (score < 5) {
      return theme.palette.custom.greenTypography;
    } else if (score < 10) {
      return theme.palette.custom.yellowTypography;
    } else {
      return theme.palette.custom.redTypography;
    }
  }
};

export const getPotentialValueScoreColor = (score: number, theme: Theme) => {
  if (score <= 3) {
    return theme.palette.custom.redTypography;
  } else if (score <= 6) {
    return theme.palette.custom.yellowTypography;
  } else {
    return theme.palette.custom.greenTypography;
  }
};

export const getRiskScoreSeverity = (score: number) => {
  if (score < 3) {
    return "Low";
  } else if (score < 4) {
    return "Medium";
  } else {
    return "High";
  }
};

type RiskScoreBadgeProps = {
  score: number | null;
  size?: "small" | "medium";
  singleScore?: boolean;
  bgcolor?: string;
};

export const RiskScoreBadge = ({
  score,
  size = "medium",
  singleScore = false,
  bgcolor,
}: RiskScoreBadgeProps) => {
  const theme = useTheme();
  const isSmallSize = size === "small";
  return score ? (
    <Box
      display="flex"
      alignItems="center"
      borderRadius="3px"
      width={isSmallSize ? "16px" : "25px"}
      minWidth={isSmallSize ? "16px" : "25px"}
      minHeight={isSmallSize ? "16px" : "25px"}
      height={isSmallSize ? "16px" : "25px"}
      padding={isSmallSize ? "0px" : "0px"}
      bgcolor={bgcolor ?? getRiskScoreColor(score, theme, singleScore)}
    >
      <Typography
        width="100%"
        textAlign="center"
        textTransform="uppercase"
        color={theme.palette.custom.whiteTypography}
        variant="h5"
        fontSize={isSmallSize ? "10px" : "12px"}
      >
        {score}
      </Typography>
    </Box>
  ) : null;
};

export const RiskScoreBadgeWithLabel = (props: RiskScoreBadgeProps & { label: string }) => {
  return props.score ? (
    <Box width="67px" display="flex" flexDirection="column" gap="5px" alignItems="center">
      <RiskScoreBadge {...props} />
      <Typography variant="body2">{props.label}</Typography>
    </Box>
  ) : null;
};

export const WorkflowNodeSlugBadge = ({
  slug,
  type,
}: {
  slug: string;
  type: "primary" | "secondary" | "success";
}) => {
  const theme = useTheme();
  const getBackgroundColor = (): string => {
    switch (type) {
      case "primary":
        return theme.palette.custom.primaryStatusBackgroundColor;
      case "secondary":
        return theme.palette.custom.secondaryStatusBackgroundColor;
      case "success":
        return theme.palette.custom.successStatusBackgroundColor;
      default:
        return theme.palette.custom.secondaryTypography;
    }
  };

  const getFontColor = (): string => {
    switch (type) {
      case "primary":
        return theme.palette.custom.primaryStatusColor;
      case "secondary":
        return theme.palette.custom.secondaryStatusColor;
      case "success":
        return theme.palette.custom.successStatusColor;
      default:
        return theme.palette.custom.secondaryTypography;
    }
  };

  return (
    <Box borderRadius="3px" padding="2.18px 3.49px" bgcolor={getBackgroundColor()}>
      <Typography variant="h5" color={getFontColor()}>
        {slug}
      </Typography>
    </Box>
  );
};
