import { Box, Typography, TypographyProps } from "@mui/material";
import { NavbarIcon } from "../atoms/navbar/Icon";
import { TableTextWithEllipsis } from "../molecules/TableTextWithEllipsis";

type ExpandableTitleProps = {
  expanded: boolean;
  setExpanded: (value: boolean) => void;
  title?: string;
  variant?: TypographyProps["variant"];
  secondComponent?: React.ReactNode;
  leftComponent?: React.ReactNode;
  rightComponent?: React.ReactNode;
  onClick?: () => void;
  gap?: string;
};

export const ExpandableTitle = (props: ExpandableTitleProps) => {
  const {
    expanded,
    setExpanded,
    title,
    variant = "h3",
    secondComponent,
    leftComponent,
    rightComponent,
    onClick,
    gap = "10px",
  } = props;

  return (
    <Box
      display="flex"
      gap="10px"
      alignItems="center"
      justifyContent="space-between"
      onClick={() => setExpanded(true)}
      sx={{
        cursor: !expanded ? "pointer" : "default",
      }}
      width="100%"
    >
      <Box display="flex" gap={gap} alignItems="center">
        <Box
          sx={{ cursor: "pointer" }}
          onClick={(event) => {
            event.stopPropagation();
            setExpanded(!expanded);
          }}
          alignItems="center"
          display="flex"
        >
          <NavbarIcon variant={expanded ? "chevron-up-sm" : "chevron-down-sm"} />
        </Box>
        {leftComponent && leftComponent}
        {title && (
          <TableTextWithEllipsis
            variant={variant}
            onClick={onClick}
            fullWidth={false}
            value={title}
          />
        )}
        {secondComponent && secondComponent}
      </Box>
      {rightComponent && rightComponent}
    </Box>
  );
};
