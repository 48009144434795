import {
  ElementMapping,
  ElementMappingFetchOptions,
  ElementMappingRelationshipName,
  ElementMappingType,
  SupportedElementMappingModels,
} from "../models/types";
import queryClient, { QueryKey } from "../state/QueryStore";
import { ListResponse } from "../utilities/ApiResponseHelper";
import {
  httpDeleteAuthenticated,
  httpGetAuthenticated,
  httpPatchAuthenticated,
  httpPostAuthenticated,
} from "./ApiService";

const elementMappingEndpoints = {
  elementMappings: () => "/element_mappings",
  elementMapping: (elementMappingId: string) => `/element_mappings/${elementMappingId}`,
};

export const getElementMappings = (params: ElementMappingFetchOptions) => {
  return httpGetAuthenticated<ListResponse<ElementMapping>>(
    elementMappingEndpoints.elementMappings(),
    {
      params,
    }
  );
};

export const getElementMapping = async (
  elementMappingId: string,
  params: ElementMappingFetchOptions
) => {
  return httpGetAuthenticated<ElementMapping>(
    elementMappingEndpoints.elementMapping(elementMappingId),
    {
      params,
    }
  );
};

export const addElementMapping = async (payload: {
  mapping_type: ElementMappingType;
  relationship_name: ElementMappingRelationshipName;
  from_object: string;
  from_object_type: SupportedElementMappingModels;
  to_object: string;
  to_object_type: SupportedElementMappingModels;
  additional_details?: string;
}) => {
  const { data } = await httpPostAuthenticated<ElementMapping>(
    elementMappingEndpoints.elementMappings(),
    payload
  );
  queryClient.invalidateQueries({ queryKey: [QueryKey.ElementMapping] });
  return data;
};

export const editElementMapping = async (
  elementMappingId: string,
  payload: {
    mapping_type?: ElementMappingType;
    relationship_name?: ElementMappingRelationshipName;
    from_object?: string;
    from_object_type?: SupportedElementMappingModels;
    to_object?: string;
    to_object_type?: SupportedElementMappingModels;
    additional_details?: string;
  }
) => {
  const { data } = await httpPatchAuthenticated(
    elementMappingEndpoints.elementMapping(elementMappingId),
    payload
  );
  queryClient.invalidateQueries({ queryKey: [QueryKey.ElementMapping] });
  return data;
};

export const deleteElementMapping = async (
  elementMappingId: string,
  params: ElementMappingFetchOptions
) => {
  const resp = await httpDeleteAuthenticated(
    elementMappingEndpoints.elementMapping(elementMappingId)
  );
  queryClient.invalidateQueries({ queryKey: [QueryKey.ElementMapping, params] });
  queryClient.invalidateQueries({ queryKey: [QueryKey.ElementMapping] });
  return resp;
};
