import { PluginData, PluginType } from "../../../models/types";
import { MLFlowAutologgingForm } from "../../../organisms/MlflowAutologging";
import { EditAWSPlugin } from "./EditAWSPlugin";
import { EditDatabricksPlugin } from "./EditDatabricksPlugin";
import { EditGithubPlugin } from "./EditGithubPlugin";
import { EditHuggingFacePlugin } from "./EditHuggingFacePlugin";
import { EditJiraPlugin } from "./EditJiraPlugin";
import { EditOAuthPlugin } from "./EditOAuthPlugin";
import { EditOpenAIPlugin } from "./EditOpenAIPlugin";
import { EditTokenAuthPlugin } from "./EditTokenAuthPlugin";

type PluginEditProps = {
  allPlugins: PluginData[];
  plugin: PluginData;
  setPlugin: (plugin: PluginData) => void;
  pluginData: any; // @todo grant that plugin data must have auth_mode prop when it supports more then one auth type
  onDisconnect: () => void;
  onEdit: () => void;
  active?: boolean;
  onSetup?: (params: any) => void;
};

const PluginEdit = (props: PluginEditProps) => {
  const { plugin, onDisconnect, onEdit, pluginData, active, onSetup, setPlugin, allPlugins } =
    props;
  const pluginHasOAuth = plugin.auth.some((auth: any) => auth.auth_type === "oauth");
  const pluginHasTokenAuth = plugin.auth.some((auth: any) => auth.auth_type === "token_auth");

  const handleChangePlugin = (pluginType: PluginType) => {
    const selectedPlugin = allPlugins.find((p) => p.plugin_type === pluginType);
    if (selectedPlugin) {
      setPlugin(selectedPlugin);
    }
  };

  return (
    <>
      {(() => {
        switch (plugin.plugin_type) {
          case "github":
            return <EditGithubPlugin pluginData={pluginData} onDisconnect={onDisconnect} />;
          case "jira":
            return (
              <EditJiraPlugin
                onEdit={onEdit}
                onDisconnect={onDisconnect}
                plugin={plugin}
                pluginData={pluginData}
              />
            );
          case "aws":
            return (
              <EditAWSPlugin onEdit={onEdit} onDisconnect={onDisconnect} pluginData={pluginData} />
            );
          case "mlflow":
            return (
              <MLFlowAutologgingForm
                onDisconnect={onDisconnect}
                onEdit={onEdit}
                onChangePlugin={handleChangePlugin}
                pluginData={pluginData}
                onSetup={onSetup ?? onEdit}
                active={active ?? false}
              />
            );
          case "openai":
            return (
              <EditOpenAIPlugin
                onEdit={onEdit}
                onDisconnect={onDisconnect}
                pluginData={pluginData}
              />
            );
          case "databricks":
            return (
              <EditDatabricksPlugin
                onEdit={onEdit}
                onDisconnect={onDisconnect}
                pluginData={pluginData}
                plugin={plugin}
              />
            );
          case "hugging_face":
            return (
              <EditHuggingFacePlugin
                pluginData={pluginData}
                plugin={plugin}
                onEdit={onEdit}
                onDisconnect={onDisconnect}
              />
            );
          default:
            if (pluginHasOAuth && pluginData?.auth_mode === "oauth") {
              <EditOAuthPlugin
                pluginData={pluginData}
                plugin={plugin}
                onDisconnect={onDisconnect}
              />;
            }
            if (pluginHasTokenAuth && pluginData?.auth_mode === "token_auth") {
              <EditTokenAuthPlugin
                pluginData={pluginData}
                onEdit={onEdit}
                onDisconnect={onDisconnect}
              />;
            }
            return <></>;
        }
      })()}
    </>
  );
};

export { PluginEdit };
