import { useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { useCustomFieldValues } from "../hooks/useCustomFields";
import { getBadgeColor } from "../utilities/BadgeColors";
import { evaluateColorLogic } from "../utilities/ColorLogicHelper";
import { isJSON } from "../utilities/UIHelper";
import { ColoredBadge } from "./ColoredBadge";
import { RectangularLoader } from "./skeleton-loader/RectangularLoader";

type BenchmarkBadgeProps = {
  metricId: string;
  metricName?: string;
  metricResultValue: string;
  onClick: () => void;
};

export const BenchmarkBadge = (props: BenchmarkBadgeProps) => {
  const { metricId, metricName, metricResultValue, onClick } = props;
  const theme = useTheme();

  const { data: colorScheme, isLoading: isLoadingColorScheme } = useCustomFieldValues(metricId, {
    "custom_field_field_key[]": ["color_scheme"],
  });

  const [color, setColor] = useState<string>(theme.palette.custom.secondaryStatusColor);
  const benchmarkColor = getBadgeColor(color);

  useEffect(() => {
    if (colorScheme && colorScheme.length > 0) {
      const value = colorScheme[0].value;
      const rules = typeof value === "string" && isJSON(value) ? JSON.parse(value) : value;
      const evaluatedColor = evaluateColorLogic(rules, metricResultValue, "Number");
      setColor(evaluatedColor ?? theme.palette.custom.secondaryStatusColor);
    }
  }, [colorScheme]);

  return isLoadingColorScheme ? (
    <RectangularLoader width="165px" height="28px" />
  ) : (
    <ColoredBadge
      name={metricName}
      value={metricResultValue}
      color={benchmarkColor}
      onClick={onClick}
    />
  );
};
