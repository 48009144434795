import { Box, Typography, useTheme } from "@mui/material";
import { SearchableDropdown } from "../../components/SearchableDropdown";
import { S3Bucket, S3Object } from "../../models/types";
import { searchBuckets, searchS3Objects } from "../../hooks/usePlugins";
import { useState } from "react";

type AddS3PluginDataFormProps = {
  setSelectedResource: (newField: any) => void;
  selectedResource: S3Object | null;
};

const AddS3PluginDataForm = (props: AddS3PluginDataFormProps) => {
  const { setSelectedResource, selectedResource } = props;
  const theme = useTheme();
  const innerTextColor = theme.palette.text.secondary;
  const [selectedBucket, setSelectedBucket] = useState<S3Bucket | null>(null);
  return (
    <Box display="flex" flexDirection="column" gap="10px">
      <Typography variant="h3" color={innerTextColor}>
        Bucket
      </Typography>
      <SearchableDropdown<S3Bucket>
        multiple={false}
        label="Select or search"
        required
        error={false}
        value={selectedBucket || null}
        onChange={(newField) => newField != null && setSelectedBucket(newField)}
        getOptions={(search: string) => searchBuckets(search)}
        isOptionEqualToValue={(a, b) => a === b}
        getOptionLabel={(option) => option.Name}
      />
      {selectedBucket && (
        <>
          <Typography variant="h3" color={innerTextColor}>
            File
          </Typography>
          <SearchableDropdown<S3Object>
            label="Select or search"
            required
            error={false}
            value={selectedResource || null}
            onChange={(newField) => newField != null && setSelectedResource(newField)}
            getOptions={(search: string) => searchS3Objects(selectedBucket?.Name, search ?? "")}
            isOptionEqualToValue={(a, b) => a === b}
            getOptionLabel={(option) => option.name}
          />
        </>
      )}
    </Box>
  );
};

export { AddS3PluginDataForm };
