import { Box } from "@mui/material";
import { RegistryFieldStyleRule } from "../models/types";
import { DisplayRule } from "./DisplayRule";

export const StyleRules = (props: {
  rules: RegistryFieldStyleRule[];
  onEdit: (rule: RegistryFieldStyleRule) => void;
  onRemove: (idx: number) => void;
  disabled?: boolean;
}) => {
  const { rules, onEdit, onRemove, disabled = false } = props;
  return (
    <Box display="flex" flexDirection="column" gap="5px" maxWidth="100%" overflow="hidden">
      {!!rules.length &&
        rules.map((rule, index) => (
          <DisplayRule
            key={index}
            rule={rule}
            onEdit={() => onEdit(rule)}
            onRemove={() => onRemove(index)}
            disabled={disabled}
          />
        ))}
    </Box>
  );
};
