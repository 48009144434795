import { Box, Typography } from "@mui/material";
import { NavbarIcon } from "../../../atoms/navbar/Icon";
import { PluginData } from "../../../models/types";
import { EditButton, DisconnectButton } from "./Buttons";

type HuggingFaceIntegrationProps = {
  hugging_face_token?: string;
};

type EditHuggingFacePluginProps = {
  pluginData: HuggingFaceIntegrationProps;
  plugin: PluginData;
  onEdit: () => void;
  onDisconnect: () => void;
};

export const EditHuggingFacePlugin = (props: EditHuggingFacePluginProps) => {
  const { pluginData, plugin, onDisconnect, onEdit } = props;
  return (
    <Box sx={{ display: "flex", justifyContent: "space-between" }}>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <NavbarIcon
          variant="lock"
          sx={{
            height: "30px",
          }}
        />
        <Typography variant="h2">{pluginData.hugging_face_token}</Typography>
      </Box>
      <Box>
        <EditButton onEdit={onEdit} />
        <DisconnectButton onDisconnect={onDisconnect} />
      </Box>
    </Box>
  );
};
