import {
  Box,
  Divider,
  Table as MuiTable,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from "@mui/material";
import { IColumnVisibility } from "../molecules/ColumnVisibility";
import { EmptyTable } from "../molecules/EmptyTable";
import LoaderLabeled from "./LoaderLabeled";
import { useState } from "react";
import { NavbarIcon } from "../atoms/navbar/Icon";
import { hasResourceTypeName } from "../utilities/UIHelper";

type TableProps<T extends { id: string }> = {
  /** Column display information */
  columnsVisibility: IColumnVisibility<T>[];
  /** Is the data loading? */
  isLoading?: boolean;
  /** Data for the rows to display*/
  rows: T[] | null;
  /** `onClick` handler for the row*/
  rowOnClick?: (row: T) => void;
  /** Empty table component */
  emptyTableComponent?: React.ReactNode;
  /** Callback to show all columns */
  onShowAllColumns?: () => void;
  /** Small empty version */
  smallEmptyHeight?: boolean;
  /** Height used for hidding/showing the table with effect */
  visible?: boolean;
  borderTopRadius?: string;
  expandable?: boolean;
  tableExpandableComponent?: (row: T) => React.ReactNode;
  showFirstExpanded?: boolean;
  minEmptyHeight?: boolean;
};

export const Table = <T extends { id: string }>(props: TableProps<T>) => {
  const {
    smallEmptyHeight,
    columnsVisibility,
    isLoading,
    rows,
    rowOnClick,
    emptyTableComponent,
    onShowAllColumns,
    visible = true,
    borderTopRadius = "0px",
    expandable = false,
    tableExpandableComponent,
    showFirstExpanded = false,
    minEmptyHeight = false,
  } = props;
  const containerHeight = visible ? "100%" : "0px";
  const theme = useTheme();
  const visibleColumns = columnsVisibility.filter((col) => col.visible);
  const noColumnsVisible = !visibleColumns.length;
  const isTableEmpty = (rows && rows.length === 0) || noColumnsVisible;
  const [expanded, setExpanded] = useState<Record<string, boolean>>(
    rows?.reduce(
      (acc, row) => ({ ...acc, [row.id]: showFirstExpanded && rows.length === 1 ? true : false }),
      {}
    ) || {}
  );

  const toggleExpanded = (id: string) => {
    setExpanded((prevStates: any) => ({
      ...prevStates,
      [id]: !prevStates[id],
    }));
  };

  return (
    <>
      <TableContainer
        sx={{
          borderRadius: 0,
          boxShadow: "none",
          border: "none",
          display: "flex",
          flex: isTableEmpty ? 1 : 0,
          height: containerHeight,
          "& .MuiTableRow-root": {
            borderTop: "1px solid",
            borderTopLeftRadius: borderTopRadius,
            borderTopRightRadius: borderTopRadius,
            borderTopColor: theme.palette.custom.secondaryBorder,
          },
          "& .MuiTableCell-root": {
            borderBottom: "unset",
          },
        }}
      >
        <MuiTable sx={{ borderRadius: 0 }}>
          <TableHead
            sx={{
              borderRadius: 0,
              borderBottom: "1px solid",
              borderBottomColor: theme.palette.custom.secondaryBorder,
            }}
          >
            <TableRow>
              {visibleColumns.map((col, idx) => (
                <TableCell
                  key={col.field}
                  sx={{
                    height: "35px",
                    padding: 0,
                    maxWidth: col.columnMaxWidth,
                    width: col.columnMaxWidth,
                  }}
                >
                  <Box
                    sx={{
                      width: "100%",
                      height: "100%",
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                    }}
                  >
                    {idx > 0 && (
                      <Divider sx={{ height: "14px", width: "2px" }} orientation="vertical" />
                    )}
                    <Typography
                      variant="body2"
                      sx={{
                        padding: "0 10px 0 10px",
                        color: theme.palette.custom.columnHeader,
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {col.headerName}
                    </Typography>
                  </Box>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          {!!isLoading || rows == null ? (
            <caption
              style={{
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <LoaderLabeled />
            </caption>
          ) : (
            <TableBody>
              {isTableEmpty ? (
                <TableCell colSpan={visibleColumns.length}>
                  <Box
                    sx={{
                      width: "100%",
                      height: minEmptyHeight ? "unset" : smallEmptyHeight ? "174px" : "440px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {noColumnsVisible ? (
                      <EmptyTable
                        variant="no-visible"
                        label="No Columns Selected"
                        description="Select columns to see content here"
                        actionLabel="Show All Columns"
                        action={onShowAllColumns}
                      />
                    ) : (
                      emptyTableComponent ?? <EmptyTable label="No Data" />
                    )}
                  </Box>
                </TableCell>
              ) : (
                rows.map((row) => (
                  <>
                    <TableRow
                      key={row.id}
                      sx={{
                        height: "48px",
                        transition: "background-color 0.3s ease",
                        ...(rowOnClick && {
                          "&:hover": {
                            backgroundColor: theme.palette.custom.tableHover,
                          },
                          cursor: "pointer",
                        }),
                      }}
                      onClick={(e) => {
                        if (rowOnClick != null) {
                          rowOnClick(row);
                          e.stopPropagation();
                        }
                      }}
                    >
                      {visibleColumns.map(
                        ({ field, columnOnClick, renderer, fullRowWidth }, index) => {
                          const tableCellMinWidth = (minWidth: number = 80) => {
                            try {
                              if (visibleColumns[index]?.columnMinWidth) {
                                return `${visibleColumns[index]?.columnMinWidth}px`;
                              }
                              const maxSize = visibleColumns[index]?.columnMaxWidth ?? minWidth;
                              return `${maxSize < minWidth ? maxSize : minWidth}px`;
                            } catch (ex) {
                              return `${minWidth}px`;
                            }
                          };
                          const isFullRow = fullRowWidth && fullRowWidth(row);
                          if (isFullRow && index > 0) {
                            return null;
                          } else
                            return (
                              <TableCell
                                colSpan={isFullRow ? visibleColumns.length : 1}
                                key={field}
                                id={(row as any).id}
                                sx={{
                                  minWidth: tableCellMinWidth(),
                                  maxWidth: "300px",
                                  ...(columnOnClick && {
                                    cursor: "pointer",
                                    "&:hover": {
                                      textDecoration: "underline",
                                    },
                                  }),
                                }}
                                onClick={(e) => {
                                  if (columnOnClick != null) {
                                    columnOnClick(row);
                                    e.stopPropagation();
                                  }
                                }}
                              >
                                <Box display="flex" gap="6px" alignItems="center">
                                  {expandable && index === 0 && (
                                    <Box
                                      onClick={(e) => {
                                        toggleExpanded(row.id);
                                        e.stopPropagation();
                                      }}
                                      sx={{ cursor: "pointer" }}
                                    >
                                      {hasResourceTypeName(row) ? (
                                        row.resource_type_name !== "Test" ? (
                                          <NavbarIcon
                                            variant={
                                              expanded[row.id] ? "chevron-up-sm" : "chevron-down-sm"
                                            }
                                          />
                                        ) : (
                                          <></>
                                        )
                                      ) : (
                                        <NavbarIcon
                                          variant={
                                            expanded[row.id] ? "chevron-up-sm" : "chevron-down-sm"
                                          }
                                        />
                                      )}
                                    </Box>
                                  )}
                                  {renderer == null ? (row as any)[field] : renderer(row)}
                                </Box>
                              </TableCell>
                            );
                        }
                      )}
                    </TableRow>
                    {expandable && expanded[row.id] && tableExpandableComponent && (
                      <TableRow>
                        <TableCell colSpan={visibleColumns.length + 1}>
                          {tableExpandableComponent(row)}
                        </TableCell>
                      </TableRow>
                    )}
                  </>
                ))
              )}
            </TableBody>
          )}
        </MuiTable>
      </TableContainer>
    </>
  );
};
